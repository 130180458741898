//#region component import
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

const GstaModalConfirmation = ({
  modalOpen,
  handleCloseModal,
  handleValidate,
  title,
  messages,
  cancelText,
  validText,
  overrideStyle,
  className,
}) => {
  return (
    <GstaModal
      modalOpen={modalOpen}
      handleCloseModal={handleCloseModal}
      handleValidate={handleValidate}
      title={title}
      cancelText={cancelText}
      validText={validText}
      overrideStyle={overrideStyle}
      className={className}
    >
      {messages.map((message, index) => (
        <p key={index}>
          <b>{message}</b>
          <br />
        </p>
      ))}
    </GstaModal>
  );
};

export default GstaModalConfirmation;
