//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import ExportPdfParameters from "../ExportPdfParameters/ExportPdfParameters.component";
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
import { ICON_PDF, ICON_CLOSE } from "../../Constants/IconConstants";
//#endregion

//#region styles import
import "./ExportPdfParametersModal.style.scss";
//#endregion

/**
 * Modal dedicated to configure the pdf export of a student
 */

const ExportPdfParametersModal = ({
  modalOpen,
  setModalOpen,
  generateCustomPdfDocument,
  pdfTemplates,
  setPdfTemplates,
  selectedPeriod,
  setSelectedPeriod,
  selectedPdfTemplate,
  setSelectedPdfTemplate,
  personalizedStartDate,
  setPersonalizedStartDate,
  personalizedEndDate,
  setPersonalizedEndDate,
}) => {
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  return (
    <GstaModal
      cancelText={getTranslations(TRANSLATIONS.COMMON_CANCEL, translations)}
      validText={getTranslations(TRANSLATIONS.EXPORT_PDF, translations)}
      modalOpen={modalOpen}
      handleCloseModal={() => setModalOpen(false)}
      handleValidate={generateCustomPdfDocument}
      title={`${getTranslations(TRANSLATIONS.RESULTS_EXPORT_PARAMETERS, translations)}`}
      validIcon={ICON_PDF}
      deleteIcon={ICON_CLOSE}
      className={"export-pdf-parameters"}
    >
      <ExportPdfParameters
        pdfTemplates={pdfTemplates}
        setPdfTemplates={setPdfTemplates}
        selectedTemplatePdf={selectedPdfTemplate}
        setSelectedTemplatePdf={setSelectedPdfTemplate}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        personalizedStartDate={personalizedStartDate}
        setPersonalizedStartDate={setPersonalizedStartDate}
        personalizedEndDate={personalizedEndDate}
        setPersonalizedEndDate={setPersonalizedEndDate}
      />
    </GstaModal>
  );
};
export default ExportPdfParametersModal;
