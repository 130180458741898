//#region react import
import { useRef } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import Skills from "../../Pages/Student/Skills/Skills.component";
import GstaToolTip from "../GstaToolTip/GstaToolTip.component";
import SimTimeToolTipContent from "../SimTimeToolTipContent/SimTimeToolTipContent.component";
//#endregion

//#region functions import
import useIsVisible from "../../Helper/CustomHook/IsVisible";
import { ConvertNote } from "../../Helper/NoteConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
import { EXAMINE_MODULE_NOT_ACTIVATED } from "../../Pages/Home/GstaCalendar/GstaCalendar.constants";
import { ICON_GAUGE, ICON_CIRCLE_FILL_ON, ICON_SEARCH, ICON_HOURGLASS, ICON_QUICK_REFERENCE } from "../../Constants/IconConstants";
//#endregion

//#region styles import
import "./MachineResultSummary.style.scss";
//#endregion

/**
 * Component with the timing, image, and skills return
 */
const MachineResultSummary = ({ machineResult, translations }) => {
  //#region others use...
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);
  const examineModule = useSelector((state) => state.settingSlice.settingDictionary.EXAMINE_MODULES_EXTENDED);
  //#endregion

  //#region functions
  const getAverageSpan = () => {
    const spanClass =
      machineResult.averagePercent === null && machineResult.totalSecondElapsed === null
        ? "gsta-span-disabled"
        : machineResult.averagePercent >= 50
        ? "gsta-span-valid"
        : "gsta-span-invalid";

    const spanContent =
      machineResult.averagePercent === null && machineResult.totalSecondElapsed === null ? (
        getTranslations(TRANSLATIONS.RESULT_DETAIL_NOT_STARTED, translations)
      ) : (
        <>
          <i className={ICON_GAUGE} />
          {ConvertNote(machineResult.averagePercent, notationType)}
        </>
      );
    return <span className={spanClass}>{spanContent}</span>;
  };
  //#endregion

  //#region constants
  const freeTime = formatSecondToStringTime(
    machineResult.freeTotalSecondElapsed,
    translations,
    FORMAT_TIMESTRING.HHMM_IGNOR_S);
  //#endregion
  return (
    <div
      className="result-per-machine"
      ref={ref}
    >
      {isVisible ? (
        <>
          <div className="result-per-machine-title">
            <h1 className="text-icon">
              <i className={ICON_CIRCLE_FILL_ON} />
              <i className={`icon-${machineResult.machine.code} machine-icon`} />
              {`- ${getTranslations(machineResult.machine.languageKey, translations)}`}
            </h1>
            <div className="result-per-machine-title-spans">
              <div className="title-part">
                <span className="span-title">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED, translations)}
                </span>
                <GstaToolTip
                  overrideClass={"machine-summary-tooltip"}
                  toolTipContent={
                    <SimTimeToolTipContent
                      totalTime={formatSecondToStringTime(
                        machineResult.totalSecondElapsed + machineResult.freeTotalSecondElapsed,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )}
                      cursusTime={formatSecondToStringTime(
                        machineResult.totalSecondElapsed,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )}
                      freeTime={freeTime}
                    />
                  }
                  disabled={examineModule === EXAMINE_MODULE_NOT_ACTIVATED || freeTime === getTranslations(TRANSLATIONS.COMMON_DEFAULT_TIME, translations)}
                  overridePositionX
                  overridePositionY
                >
                  <span className="gsta-span">
                    {examineModule !== EXAMINE_MODULE_NOT_ACTIVATED && freeTime !== getTranslations(TRANSLATIONS.COMMON_DEFAULT_TIME, translations) && <i className={ICON_SEARCH} />}
                    <i className={ICON_HOURGLASS} />
                    {formatSecondToStringTime(
                      machineResult.totalSecondElapsed + machineResult.freeTotalSecondElapsed,
                      translations,
                      FORMAT_TIMESTRING.HHMM_IGNOR_S
                    )}
                  </span>
                </GstaToolTip>
              </div>
              <div className="title-part">
                <span className="span-title">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_AVERAGE, translations)}
                </span>
                {getAverageSpan()}
              </div>
              <div className="title-part">
                <span className="span-title">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_SUBSCRIPTIONS, translations)}
                </span>
                <span className="gsta-span-disabled">
                  <i className={ICON_QUICK_REFERENCE} />
                  {machineResult.trainingResults.length}
                </span>
              </div>
            </div>
          </div>
          <hr className="student-training--separator full-width" />
          <div className="result-per-machine-skills">
            <Skills
              title={getTranslations(TRANSLATIONS.PDF_RESULT_PER_MACHINE_JOB_SKILLS, translations)}
              noSkillPlaceHolder={getTranslations(TRANSLATIONS.PDF_RESULT_PER_MACHINE_NO_SKILLS, translations)}
              skills={machineResult.machineSkills}
            />
          </div>
        </>
      ) : (
        <div className="machine-summary-placholder">
          <div className="placholder-animation" />
        </div>
      )}
    </div>
  );
};

export default MachineResultSummary;
