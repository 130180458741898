//#region react import
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//#endregion

//#region functions import
import { getTranslations } from "../../../Helper/TranslationController";
import { getCurrentActivity } from "../../../Services/CenterService";
//#endregion

//#region components import
import MachineAddedPatchNotes from "../../../Components/MachineAddedPatchNotes/MachineAddedPatchNotes.component";
import PatchNotes from "../../../Components/PatchNotes/PatchNotes.component";
import PatchNotesModal from "../../../Components/PatchNotesModal/PatchNotesModal.component";
import SimulatorsActivity from "../../../Components/SimulatorsActivity/SimulatorsActivity.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../../Constants/Translations";
import { CURRENT_ACTIVITY } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./Dashboard.style.scss";
//#endregion

/**
 * Component designed to be rendered in Welcome page
 */

const DashBoard = () => {
  //#region hook
  const trainerCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region functions
  const checkForUpdate = () => {
    return location.state?.updatedVersions
      ? Object.values(location.state?.updatedVersions).some((arr) => Array.isArray(arr) && arr.length > 0)
      : false;
  };
  const getSimulatorsCenter = async (centerSimulators) => {
    let activeSimulatorsNumber = 0;
    let inactiveSimulatorsNumber = 0;
    let tmpCenterSimulators = centerSimulators;
    tmpCenterSimulators.forEach((simulator) =>
      simulator.isActive ? activeSimulatorsNumber++ : inactiveSimulatorsNumber++
    );
    tmpCenterSimulators.sort((elt1, elt2) => {
      if (elt1.isActive && !elt2.isActive) {
        return 1;
      } else if (!elt1.isActive && elt2.isActive) {
        return -1;
      } else {
        return 0;
      }
    });
    tmpCenterSimulators.reverse();
    setCenterSimulator(tmpCenterSimulators);
    setActiveSimulators(activeSimulatorsNumber);
    setInactiveSimulators(inactiveSimulatorsNumber);
    setActiveSimulatorsPercentage(Math.round((100 / tmpCenterSimulators.length) * activeSimulatorsNumber));
  };

  const getMaxPageNumber = () => {
    let updatedVersions = location.state?.updatedVersions;
    let pageNumber = 0;

    if (updatedVersions?.addedMachines && updatedVersions.addedMachines.length > 0) {
      pageNumber += updatedVersions.addedMachines.length;
    }

    if (updatedVersions?.updatedGstaVersions && updatedVersions.updatedGstaVersions.length > 0) {
      pageNumber += updatedVersions.updatedGstaVersions.length;
    }

    if (updatedVersions?.updatedSoftwares && updatedVersions.updatedSoftwares.length > 0) {
      pageNumber += updatedVersions.updatedSoftwares.length;
    }
    return pageNumber;
  };

  const getPatchNoteContent = () => {
    let elementToReturn = <></>;

    if (currentPage <= patchNotes.length) {
      elementToReturn = patchNotes[currentPage - 1];
    }

    return elementToReturn;
  };

  const handleValidate = () => {
    if (currentPage < getMaxPageNumber()) {
      setCurrentPage(currentPage + 1);
    } else {
      setModalOpen(false);
    }
  };

  const goToPreviousModalPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const buildPatchNotesContent = (updatedVersions) => {
    let patchNotesToCreate = [];

    if (updatedVersions?.addedMachines && updatedVersions.addedMachines.length > 0) {
      for (const addedMachines of updatedVersions.addedMachines) {
        patchNotesToCreate.push(<MachineAddedPatchNotes machineInfo={addedMachines} />);
      }
    }
    if (updatedVersions?.updatedSoftwares && updatedVersions.updatedSoftwares.length > 0) {
      for (const updatedSoftwares of updatedVersions.updatedSoftwares) {
        patchNotesToCreate.push(<PatchNotes patchNotesContent={updatedSoftwares.json} />);
      }
    }
    if (updatedVersions?.updatedGstaVersions && updatedVersions.updatedGstaVersions.length > 0) {
      for (const updatedGstaVersions of updatedVersions.updatedGstaVersions) {
        patchNotesToCreate.push(<PatchNotes patchNotesContent={updatedGstaVersions.json} />);
      }
    }

    return patchNotesToCreate;
  };
  //#endregion

  //#region constants
  const patchNotes = buildPatchNotesContent(location.state?.updatedVersions);
  //#endregion

  //#region state
  const [centerSimulator, setCenterSimulator] = useState([]);
  const [activeSimulatorsPercentage, setActiveSimulatorsPercentage] = useState(0);
  const [activeSimulators, setActiveSimulators] = useState(0);
  const [inactiveSimulators, setInactiveSimulators] = useState(0);
  const [modalOpen, setModalOpen] = useState(checkForUpdate());
  const [currentPage, setCurrentPage] = useState(1);
  //#endregion

  //#region useQuery
  const {
    data: centerSimulatorsData,
    isSuccess: isSuccessCenterSimulators,
    isError: isErrorCenterSimulators,
  } = useQuery({
    queryKey: ["centerSimulators"],
    queryFn: () => getCurrentActivity(trainerCenterId),
    refetchOnWindowFocus: false,
  });
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (isSuccessCenterSimulators) {
      getSimulatorsCenter(centerSimulatorsData);
    }
    if (isErrorCenterSimulators) {
      dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.COMMON_ERROR, translations) }));
    }
  }, [centerSimulatorsData, isSuccessCenterSimulators, isErrorCenterSimulators, translations, dispatch]);
  //#endregion

  return (
    <div>
      <div className="home-title">
        <h1>{getTranslations(CURRENT_ACTIVITY.pageTraductionKey, translations)}</h1>
      </div>
      <hr className="activity-separator" />
      <SimulatorsActivity
        centerSimulators={centerSimulator}
        activeSimulatorsPercentage={activeSimulatorsPercentage}
        activeSimulators={activeSimulators}
        inactiveSimulators={inactiveSimulators}
      />
      {modalOpen && (
        <PatchNotesModal
          modalOpen={modalOpen}
          patchNotes={getPatchNoteContent()}
          setModalOpen={setModalOpen}
          currentPage={currentPage}
          maxNumberPage={getMaxPageNumber()}
          handleValidate={handleValidate}
          goToPreviousModalPage={goToPreviousModalPage}
        />
      )}
    </div>
  );
};

export default DashBoard;
