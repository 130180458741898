//#region react import
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region component import
import BackButton from "../../Components/BackButton/BackButton.component";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import TrainingDetailGlobalInfo from "../../Pages/Training/TrainingDetailGlobalInfo/TrainingDetailGlobalInfo.component";
import TrainingDetailStepTimeLine from "../../Pages/Training/TrainingDetailStepTimeline/TrainingDetailStepTimeLine.component";
//#endregion

//#region functions import
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
//#endregion

//#region service import
import { getCenterTrainingSkills, getCenterTrainingSteps, setArchivedtrainings } from "../../Services/TrainingService";
//#endregion

//#region store import
import { setSelectedArchivedTraining, setSelectedTrainingId, setTrainings } from "../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
import { ICON_ARCHIVE, ICON_UNARCHIVE } from "../../Constants/IconConstants";
//#endregion

//#region style import
import { TRAININGS } from "../../Routing/PageNames";
import "./TrainingDetailLayout.style.scss";
//#endregion

const TrainingDetailLayout = () => {
  //#region useSelector
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  const selectedTrainingId = useSelector((state) => state.trainingSlice.selectedTrainingId);
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const selectedArchivedTraining = useSelector((state) => state.trainingSlice.selectedArchivedTraining);
  const { state } = useLocation();
  //#endregion
  //#region functions
  const getSelectedTraining = () => {
    if (state?.training && !selectedArchivedTraining) {
      return state?.training;
    } else if (selectedArchivedTraining) {
      return selectedArchivedTraining;
    } else {
      return trainings.find((element) => {
        return element.id === selectedTrainingId;
      });
    }
  };
  //#endregion

  //#region other use
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region useState
  const [skills, setSkills] = useState([]);
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(getSelectedTraining());
  //#endregion
  //#region constants
  //#endregion
  //#region function
  const backToTraining = () => {
    dispatch(setSelectedArchivedTraining(null));
    dispatch(setSelectedTrainingId(null));
    if (window.history.length === 1) {
      navigate(TRAININGS.url);
    } else {
      navigate(-1);
    }
  };

  const buildArchiveButtonContent = () => {
    if (selectedTraining?.isArchivedByCusForCenter) {
      return (
        <>
          <i className={ICON_UNARCHIVE} />
          <span>{getTranslations(TRANSLATIONS.UNARCHIVE_TRAINING, translations)}</span>
        </>
      );
    } else {
      return (
        <>
          <i className={ICON_ARCHIVE} />
          <span>{getTranslations(TRANSLATIONS.ARCHIVE_TRAINING, translations)}</span>
        </>
      );
    }
  };

  const archiveUnarchiveTraining = async () => {
    setLoading(true);
    let trainingsCopied = copyObject(trainings);
    const selectedTrainingCopied = copyObject(selectedTraining);
    try {
      if (selectedTraining.isArchivedByCusForCenter) {
        await setArchivedtrainings(activeCenterId, selectedTrainingId, false);
        trainingsCopied.push(selectedTrainingCopied);
        selectedTrainingCopied.isArchivedByCusForCenter = false;
        setSelectedTraining(selectedTrainingCopied);
        dispatch(setSelectedArchivedTraining(selectedTrainingCopied));
      } else {
        await setArchivedtrainings(activeCenterId, selectedTrainingId, true);
        trainingsCopied = trainingsCopied.filter((training) => training.id !== selectedTrainingId);
        selectedTrainingCopied.isArchivedByCusForCenter = true;
        setSelectedTraining(selectedTrainingCopied);
        dispatch(setSelectedArchivedTraining(selectedTrainingCopied));
      }
    } catch (e) {
      dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.ARCHIVE_UNARCHIVE_TRAINING_ERROR, translations) }));
    }
    dispatch(setTrainings(trainingsCopied));
    setSelectedTraining(selectedTrainingCopied);
    setLoading(false);
  };

  const handleQueryResult = useCallback((isSuccess, data, isError, error, setData) => {
    if (isSuccess && data) {
      setData(data);
    } else if (isError) {
      dispatch(setErrorNotification({ message: getTranslations(error, translations) }));
    }
  }, [dispatch, translations]);
  //#endregion

  //#region useQuery
  const { isSuccess: isSuccessTrainingSkills, data: trainingSkillsData, isError: isErrorTrainingSkills } = useQuery({
    queryKey: ["trainingSkills"],
    queryFn: () => getCenterTrainingSkills(activeCenterId, selectedTrainingId),
    refetchOnWindowFocus: false,
  });

  const { isSuccess: isSuccessTrainingSteps, data: trainingStepData, isError: isErrorTrainingSteps } = useQuery({
    queryKey: ["trainingSteps"],
    queryFn: () => getCenterTrainingSteps(activeCenterId, selectedTrainingId),
    refetchOnWindowFocus: false,
  });
  //#endregion

  //#region useEffect
  useEffect(() => {
    handleQueryResult(isSuccessTrainingSkills, trainingSkillsData, isErrorTrainingSkills, TRANSLATIONS.GET_TRAINING_SKILL_ERROR, setSkills);
  }, [isSuccessTrainingSkills, trainingSkillsData, isErrorTrainingSkills, setSkills, handleQueryResult]);
  
  useEffect(() => {
    handleQueryResult(isSuccessTrainingSteps, trainingStepData, isErrorTrainingSteps, TRANSLATIONS.GET_TRAINING_STEP_ERROR, setSteps);
  }, [isSuccessTrainingSteps, trainingStepData, isErrorTrainingSteps, setSteps, handleQueryResult]);
  //#endregion

  return (
    <div>
      {loading && <GstaLoaderPage />}
      <div className="header-student-detail-back-button">
        <BackButton
          text={getTranslations(TRANSLATIONS.TRAINING_LAYOUT_DETAIL_BACK_TO_CATALOG, translations)}
          backFunction={backToTraining}
        />
      </div>
      <div className="training-detail-info">
        <div className="training-detail-title">
          <div>
            <h1 className="title1">{getTranslations(selectedTraining.trainingName, translations)}</h1>
            {selectedTraining.isArchivedByCusForCenter && (
              <span>{getTranslations(TRANSLATIONS.TRAINING_ARCHIVED, translations)}</span>
            )}
          </div>
          <button
            onClick={archiveUnarchiveTraining}
            className="gsta-button-outlined"
          >
            {buildArchiveButtonContent()}
          </button>
        </div>
        <TrainingDetailGlobalInfo
          training={selectedTraining}
          skills={skills}
        />
        <TrainingDetailStepTimeLine
          steps={steps}
          trainingInfo={{
            id: getSelectedTraining()?.id,
            isAcreos: getSelectedTraining()?.acreos,
            isArchive: getSelectedTraining()?.isArchivedByCusForCenter,
          }}
        />
      </div>
    </div>
  );
};

export default TrainingDetailLayout;
