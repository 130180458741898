//#region react import
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region functions import
import { copyObject } from "../../../Helper/CopyObject";
import { DisableOverflow, EnableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModalConfirmation from "../../../Components/GstaModalConfirmation/GstaModalConfirmation.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import {
  deleteTraining,
  setSelectedArchivedTraining,
  setSelectedTrainingId,
  setTrainings,
} from "../../../ReduxStore/trainingSlice";
//#endregion

//#region services import
import { deleteCustomTraining, setArchivedtrainings } from "../../../Services/TrainingService";
//#endregion

//#region constants import
import { NO, YES } from "../../../Components/GstaModal/GstaModal.constants";
import { TRAININGS_CHOOSE_MACHINE, TRAININGS_DETAILS } from "../../../Routing/PageNames";
import { TRANSLATIONS } from "../../../Constants/Translations";
import { ICON_ARCHIVE, ICON_BACKSPACE, ICON_FILE_COPY, ICON_INFO, ICON_UNARCHIVE } from "../../../Constants/IconConstants";
//#endregion

//#region style import
import "./TrainingAction.style.scss";
//#endregion

/**
 * All the user actions of a training table
 */

const TrainingAction = ({ value, ActionsProps }) => {
  //#region useState import
  const [modalOpen, setModalOpen] = useState(false);
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false);
  //#endregion

  //#region useSelector import
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  //#endregion
  //#region others use import
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const showTrainingDetails = () => {
    navigate(TRAININGS_DETAILS.url, { state: { training: value } });
    dispatch(setSelectedTrainingId(value.id));
  };

  const redirectChooseMachine = () => {
    navigate(TRAININGS_CHOOSE_MACHINE.url, { state: { trainingId: value?.id } });
  };

  const openModal = () => {
    setModalOpen(true);
    DisableOverflow();
  };

  const openArchiveModal = () => {
    setModalArchiveOpen(true);
    DisableOverflow();
  };

  const closeModal = () => {
    setModalOpen(false);
    EnableOverflow();
  };

  const closeArchiveModal = () => {
    setModalArchiveOpen(false);
    EnableOverflow();
  };

  const modalValidate = async () => {
    if (value.studentCount > 0) {
      dispatch(
        setErrorNotification({
          message: getTranslations(TRANSLATIONS.NOTIFICATION_DELETE_TRAINING_FAILED, translations),
        })
      );
    } else {
      try {
        await deleteCustomTraining(centerId, value.id);
        dispatch(deleteTraining(value.id));
      } catch (e) {
        dispatch(setErrorNotification(e));
      }
    }
    closeModal();
    ActionsProps.isArchivedTrainingPage && ActionsProps.refetchArchivedTrainings();
  };

  const addModifiedTrainingToSlice = () => {
    let trainingsCopied = copyObject(trainings);
    let valueCopied = copyObject(value);
    valueCopied.isArchivedByCusForCenter = false;
    trainingsCopied.push(valueCopied);
    return trainingsCopied;
  };

  const modalArchiveUnarchiveValidate = async () => {
    closeArchiveModal();
    ActionsProps.setLoading(true);
    try {
      if (ActionsProps.isArchivedTrainingPage) {
        await setArchivedtrainings(centerId, value.id, false);
        dispatch(setTrainings(addModifiedTrainingToSlice()));
        dispatch(setSelectedArchivedTraining(null));
        ActionsProps.isArchivedTrainingPage && ActionsProps.refetchArchivedTrainings();
      } else {
        await setArchivedtrainings(centerId, value.id, true);
        let valueCopied = copyObject(value);
        valueCopied.isArchivedByCusForCenter = true;
        ActionsProps.setTrainingState(ActionsProps.trainingsState.filter((training) => training.id !== value.id));
        dispatch(setSelectedArchivedTraining(valueCopied.id));
        //deleting the training in store
        dispatch(deleteTraining(value.id));
      }
    } catch (e) {
      dispatch(
        setErrorNotification({ ...e, message: getTranslations(TRANSLATIONS.ARCHIVE_UNARCHIVE_TRAINING_ERROR, translations) })
      );
    }
    ActionsProps.setLoading(false);
  };
  //#endregion
  return (
    <div className="training-table-actions">
      <GstaToolTip toolTipContent={getTranslations(TRANSLATIONS.TRAINING_LAYOUT_TRAINING_DETAIL, translations)}>
        <button
          className="gsta-table-action-button"
          data-testid={"training-step-layout-action-" + value.id}
          onClick={showTrainingDetails}
        >
          <i className={ICON_INFO} />
        </button>
      </GstaToolTip>
      {!ActionsProps.isArchivedTrainingPage && (
        <GstaToolTip toolTipContent={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DUPLICATE, translations)}>
          <button
            className="gsta-table-action-button"
            data-testid={"training-step-layout-action-choose-machine-" + value.id}
            onClick={redirectChooseMachine}
          >
            <i className={ICON_FILE_COPY} />
          </button>
        </GstaToolTip>
      )}
      <GstaToolTip
        toolTipContent={getTranslations(
          ActionsProps.isArchivedTrainingPage ? TRANSLATIONS.UNARCHIVE_TRAINING : TRANSLATIONS.ARCHIVE_TRAINING,
          translations
        )}
      >
        <button
          className="gsta-table-action-button"
          data-testid={"training-step-layout-action-archive-training-" + value.id}
          onClick={openArchiveModal}
        >
          <i className={ActionsProps.isArchivedTrainingPage ? ICON_UNARCHIVE : ICON_ARCHIVE} />
        </button>
      </GstaToolTip>
      {value.isDeletable && (
        <GstaToolTip toolTipContent={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DELETE_TITLE_POPUP, translations)}>
          <button
            className="gsta-table-action-button"
            data-testid={"training-step-layout-action-delete-" + value.id}
            onClick={openModal}
          >
            <i className={ICON_BACKSPACE} />
          </button>
        </GstaToolTip>
      )}
      <GstaModalConfirmation
        modalOpen={modalOpen}
        handleCloseModal={closeModal}
        handleValidate={modalValidate}
        className={"delete-training-modal"}
        title={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DELETE_TITLE_POPUP, translations)}
        messages={[
          `${getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DELETE_TEXT_START_POPUP, translations)} ${value.trainingName}`,
          getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DELETE_TEXT_END_POPUP, translations),
          getTranslations(TRANSLATIONS.PERSONNALIZED_FIELD_DELETE_CONFIRM, translations),
        ]}
        cancelText={getTranslations(NO, translations)}
        validText={getTranslations(YES, translations)}
      />
      <GstaModalConfirmation
        modalOpen={modalArchiveOpen}
        handleCloseModal={closeArchiveModal}
        handleValidate={modalArchiveUnarchiveValidate}
        className={"archive-training-modal"}
        title={getTranslations(
          ActionsProps.isArchivedTrainingPage ? TRANSLATIONS.UNARCHIVE_TRAINING_TITLE_POPUP : TRANSLATIONS.ARCHIVE_TRAINING_TITLE_POPUP,
          translations
        )}
        messages={[
          `${getTranslations(
            ActionsProps.isArchivedTrainingPage ? TRANSLATIONS.ABOUT_TO_UNARCHIVE_TRAINING : TRANSLATIONS.ABOUT_TO_ARCHIVE_TRAINING,
            translations
          )} ${getTranslations(value.trainingName, translations)}`,
          getTranslations(
            ActionsProps.isArchivedTrainingPage ? TRANSLATIONS.TRAINING_UNARCHIVE_CONFIRM : TRANSLATIONS.TRAINING_ARCHIVE_CONFIRM,
            translations
          ),
        ]}
        cancelText={getTranslations(NO, translations)}
        validText={getTranslations(YES, translations)}
      />
    </div>
  );
};

export default TrainingAction;
