//#region react import
import { StyleSheet, Text, View } from "@react-pdf/renderer";
//#endregion

//#region functions import
import { getNotePdf } from "../../../Helper/NoteConverter";
import { DateTimeToIsoString, FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
import { getNoteClass } from "./CustomPdf.functions";
//#endregion

//#region constants import
import { COLOR, FONT_SIZE } from "./CustomPdf.constants";
import TrainingStepDetails from "./TrainingStepDetail";
import { TRANSLATIONS } from "../../../Constants/Translations";
//#endregion

const styles = StyleSheet.create({
  machineHistory: {
    width: "100%",
    flexDirection: "column",
  },
  mainTitle: {
    width: "100%",
    backgroundColor: COLOR.orange,
    textAlign: "center",
    fontSize: FONT_SIZE.mainTitle,
    paddingVertical: "5px",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: COLOR.grey,
    color: COLOR.textColorInversed,
    paddingVertical: "5px",
  },
  tableLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingVertical: "5px",
  },
  tableCell: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  tableCellText: {
    fontSize: "11px",
    textAlign: "center",
    width: "100%",
  },
  stepNote: {
    fontSize: "11px",
    borderRadius: "5px",
    padding: "5px",
    color: COLOR.textColorInversed,
  },
});

const MachineHistory = ({ translations, trainingHistories, notationType, isShowTrainingStepsDetail, showTime }) => {
  //#region functions
  const getAverage = (trainingHistory) => {
    return trainingHistory.trainingName
      ? trainingHistory.averagePercent || trainingHistory.averagePercent !== null
        ? getNotePdf(trainingHistory.averagePercent, notationType)
        : getTranslations(TRANSLATIONS.RESULT_DETAIL_IN_PROGRESS, translations)
      : "---";
  };

  const getTrainingHistoryBackgroundColor = (index) => {
    if (isShowTrainingStepsDetail) {
      return { backgroundColor: COLOR.darkGrey, color: COLOR.white };
    } else {
      return { backgroundColor: index % 2 === 0 ? COLOR.orangeLight : COLOR.white };
    }
  };
  //#endregion

  return (
    <View style={styles.machineHistory}>
      <View style={styles.mainTitle}>
        <Text>{getTranslations(TRANSLATIONS.PEDAGOGY_THEME_HISTORY, translations)}</Text>
      </View>
      <View style={styles.tableHeader}>
        <Text style={styles.tableCellText}>{getTranslations(TRANSLATIONS.PERSONNALIZED_FIELD_DATE, translations)}</Text>
        <Text style={styles.tableCellText}>{getTranslations(TRANSLATIONS.PDF_RESULT_PER_MACHINE_TRAINING, translations)}</Text>
        <Text style={styles.tableCellText}>
          {getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_TABLE_NAME_STEP, translations)}
        </Text>
        {showTime && (
          <Text style={styles.tableCellText}>{getTranslations(TRANSLATIONS.COMMON_DURATION, translations)}</Text>
        )}
        <Text style={styles.tableCellText}>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_AVERAGE, translations)}</Text>
      </View>
      {trainingHistories.map((trainingHistory, index) => {
        return (
          <View key={`training-history-${index}`}>
            <View
              style={[styles.tableLine, getTrainingHistoryBackgroundColor(index)]}
              key={trainingHistory.trainingStepId}
            >
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>{DateTimeToIsoString(new Date(trainingHistory.trainingDate))}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>
                  {getTranslations(
                    !trainingHistory.trainingName ? TRANSLATIONS.FREE_MODE : trainingHistory.trainingName,
                    translations
                  )}
                </Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.tableCellText}>
                  {getTranslations(trainingHistory.trainingStepName, translations)}
                </Text>
              </View>
              {showTime && (
                <View style={styles.tableCell}>
                  <Text style={styles.tableCellText}>
                    {formatSecondToStringTime(trainingHistory.duration, translations, FORMAT_TIMESTRING.HHMM_IGNOR_S)}
                  </Text>
                </View>
              )}
              <View style={styles.tableCell}>
                <Text
                  style={
                    trainingHistory.trainingName ? getNoteClass(styles.stepNote, trainingHistory.averagePercent) : ""
                  }
                >
                  {getAverage(trainingHistory)}
                </Text>
              </View>
            </View>
            {isShowTrainingStepsDetail && trainingHistory?.results?.length > 0 && (
              <TrainingStepDetails
                translations={translations}
                resultCriterionInfo={trainingHistory.results}
              />
            )}
          </View>
        );
      })}
    </View>
  );
};

export default MachineHistory;
