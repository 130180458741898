//#region react import
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import useNotationType from "../../../Helper/CustomHook/useNotationType";
import { ConvertNote } from "../../../Helper/NoteConverter";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import Loader from "../../../Components/Loader/Loader.component";
import SimTimeToolTipContent from "../../../Components/SimTimeToolTipContent/SimTimeToolTipContent.component";
import StepProgressTimeline from "../../../Components/StepProgressTimeline/StepProgressTimeline.component";
//#endregion

//#region services import
import { getStudentPreview } from "../../../Services/StudentService";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../../Constants/Translations";
import { STUDENTS_TRAINING } from "../../../Routing/PageNames";
import { EXAMINE_MODULE_NOT_ACTIVATED } from "../../Home/GstaCalendar/GstaCalendar.constants";
import { ICON_SEARCH, ICON_HOURGLASS, ICON_GAUGE } from "../../../Constants/IconConstants";
//#endregion

//#region style import
import "./StudentDetail.style.scss";
//#endregion

/**
 * Component created to render the student detail progress
 */

const StudentDetail = ({ value }) => {
  //#region state
  const [machineResults, setMachineResults] = useState([]);
  //#endregion

  //#region useQuery

  const getStudentResults = async () => {
    return await getStudentPreview(centerId, value.id);
  };

  //#region getStudentResults
  const { isFetching, isSuccess, data : studentResults } = useQuery({
    queryKey: ["studentResults"],
    queryFn: async () => {
      const studentPreview = await getStudentResults();
      return studentPreview;
    },
  });

  useEffect(() => {
    if (isSuccess && studentResults) {
      studentResults.forEach((machineResult) =>
        setCurrentStepState(machineResult.subscriptions)
      );
      setMachineResults(studentResults);
    }
  }, [isSuccess, studentResults]);
  //#endregion
  //#endregion

  //#region constants
  //#endregion

  //#region hook
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const examineModule = useSelector((state) => state.settingSlice.settingDictionary.EXAMINE_MODULES_EXTENDED);

  const dispatch = useDispatch();

  useNotationType(dispatch, centerId, notationType);
  //#endregion

  //#region functions
  const setCurrentStepState = (trainingResults) => {
    for (let i = 0; i < trainingResults?.length - 1; i++) {
      for (let j = 0; j < trainingResults[i]?.stepAverages.length - 1; j++) {
        if (
          trainingResults[i]?.stepAverages[j]?.trainingStepOrder === trainingResults[i]?.currentStepOrder &&
          !trainingResults[i]?.currentStepFinished
        ) {
          trainingResults[i].stepAverages[j].stepState = 1;
          return;
        }
      }
    }
  };
  const renderContent = () => {
    if (isFetching) {
      return <Loader />;
    } else if (isSuccess && machineResults?.length === 0) {
      return (
        <div className="student-detail-container-no-content">
          <span>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_NO_TRAINING_INSCRIPTION, translations)}</span>
        </div>
      );
    } else if (isSuccess && machineResults) {
      return (
        <>
          {isFetching && <Loader />}
          {machineResults?.length === 0 && (
            <div className="student-detail-container-no-content">
              <span>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_NO_TRAINING_INSCRIPTION, translations)}</span>
            </div>
          )}
          {machineResults?.map((machineResult, index) => {
            return (
              <div
                key={`${machineResult.machineCode}${index}`}
                className="student-detail-container"
              >
                <div className="student-detail-machine-icon-container">
                  <i className={`icon-${machineResult.code}`} />
                  <span className="engine-name">{getTranslations(machineResult.languageKey, translations)}</span>
                  <GstaToolTip
                    overrideClass={"machine-summary-tooltip"}
                    toolTipContent={
                      <SimTimeToolTipContent
                        totalTime={formatSecondToStringTime(
                          machineResult.totalSecondElapsed + machineResult.totalFreeSecondElapsed,
                          translations,
                          FORMAT_TIMESTRING.HHMM_IGNOR_S
                        )}
                        cursusTime={formatSecondToStringTime(
                          machineResult.totalSecondElapsed,
                          translations,
                          FORMAT_TIMESTRING.HHMM_IGNOR_S
                        )}
                        freeTime={formatSecondToStringTime(
                          machineResult.totalFreeSecondElapsed,
                          translations,
                          FORMAT_TIMESTRING.HHMM_IGNOR_S
                        )}
                      />
                    }
                    disabled={examineModule === EXAMINE_MODULE_NOT_ACTIVATED || machineResult?.totalFreeSecondElapsed === 0}
                    overridePositionX
                    overridePositionY
                  >
                    <span className="gsta-span">
                      {examineModule !== EXAMINE_MODULE_NOT_ACTIVATED && machineResult?.totalFreeSecondElapsed !== 0 && <i className={ICON_SEARCH} />}
                      <i className={ICON_HOURGLASS} />
                      {formatSecondToStringTime(
                        machineResult.totalSecondElapsed + machineResult.totalFreeSecondElapsed,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )}
                    </span>
                  </GstaToolTip>
                </div>
                <div className="student-detail-training-container">
                  <div className="student-detail-training-container-header">
                    <p />
                    <p>{getTranslations(STUDENTS_TRAINING.pageTraductionKey, translations)}</p>
                    <p>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_TIME, translations)}</p>
                    <p>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_AVERAGE, translations)}</p>
                    <p>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_PROGRESSION, translations)}</p>
                  </div>
                  {examineModule !== EXAMINE_MODULE_NOT_ACTIVATED && machineResult?.totalFreeSecondElapsed !== 0 && (
                    <div
                      key={index}
                      className="student-detail-training-container-row"
                    >
                      <div className="cell-container">
                        <span>---</span>
                      </div>
                      <div className="cell-container">
                        <p>{getTranslations(TRANSLATIONS.FREE_MODE, translations)}</p>
                      </div>
                      <div className="student-detail-cell">
                        <div className="gsta-span">
                          <i className={ICON_HOURGLASS} />
                          <span>
                            {formatSecondToStringTime(
                              machineResult.totalFreeSecondElapsed,
                              translations,
                              FORMAT_TIMESTRING.HHMM_IGNOR_S
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="student-detail-cell">---</div>
                      <div className="student-detail-cell">---</div>
                    </div>
                  )}

                  {machineResult.subscriptions.map((trainingResult, index) => (
                    <div
                      key={index}
                      className="student-detail-training-container-row"
                    >
                      <div className="cell-container">
                        <span className={getSpanStyle(trainingResult)}>{getSpanContent(trainingResult)}</span>
                      </div>
                      <div className="cell-container">
                        <p>{getTranslations(trainingResult.customName ?? trainingResult.languageKey, translations)}</p>
                      </div>
                      <div className="student-detail-cell">
                        <div className="gsta-span">
                          <i className={ICON_HOURGLASS} />
                          <span>
                            {formatSecondToStringTime(
                              trainingResult.totalSecondElapsed,
                              translations,
                              FORMAT_TIMESTRING.pedagogy_theme_history
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="student-detail-cell">
                        {trainingResult.totalSecondElapsed === null || !trainingResult.averagePercent ? (
                          <span className={getSpanStyle(trainingResult)}>
                            {getTranslations(TRANSLATIONS.RESULT_DETAIL_NOT_STARTED, translations)}
                          </span>
                        ) : (
                          <div className={trainingResult.averagePercent > 50 ? "gsta-span-valid" : "gsta-span-invalid"}>
                            <i className={ICON_GAUGE} />
                            <span>{ConvertNote(trainingResult.averagePercent, notationType)}</span>
                          </div>
                        )}
                      </div>
                      <div className="student-detail-cell">
                        <StepProgressTimeline
                          key={index}
                          data={{
                            steps: trainingResult.stepAverages,
                            subscriptionId: trainingResult.subscriptionId,
                            student: value,
                          }}
                          nbDisplay={4}
                          toolTips
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  const getSpanStyle = (trainingResult) => {
    if (trainingResult.currentStepOrder === trainingResult.stepCount && trainingResult.currentStepFinished) {
      return "gsta-span-valid";
    } else if (trainingResult.totalSecondElapsed !== null) {
      return "gsta-span-in-progress";
    } else {
      return "gsta-span-disabled";
    }
  };

  const getSpanContent = (trainingResult) => {
    if (trainingResult.currentStepOrder === trainingResult.stepCount && trainingResult.currentStepFinished) {
      return getTranslations(TRANSLATIONS.STUDENT_DETAIL_FINISHED, translations);
    } else if (trainingResult.totalSecondElapsed !== null) {
      return getTranslations(TRANSLATIONS.STUDENT_DETAIL_IN_PROGRESS, translations);
    } else {
      return getTranslations(TRANSLATIONS.RESULT_DETAIL_NOT_STARTED_TAG, translations);
    }
  };
  //#endregion
  return renderContent();
};
StudentDetail.propTypes = {
  value: PropTypes.shape({
    accessEnd: PropTypes.any,
    accessStart: PropTypes.any,
    customValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        value: PropTypes.string,
        fieldType: PropTypes.number,
      })
    ),
    firstName: PropTypes.string,
    guid: PropTypes.string,
    id: PropTypes.number,
    isActive: PropTypes.bool,
    lastActivityDate: PropTypes.any,
    machineSubscribes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        softwareCode: PropTypes.string,
        versionId: PropTypes.number,
        version: PropTypes.string,
        languageKey: PropTypes.string,
      })
    ),
    name: PropTypes.string,
    pinCode: PropTypes.string,
  }).isRequired,
};
export default StudentDetail;
