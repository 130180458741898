//#region react import
import moment from "moment";
import React, { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatePicker from "../../../Components/AcreosDatePicker/AcreosDatePicker.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaToolTip from "../../../Components/GstaToolTip/GstaToolTip.component";
import ActivityCard from "../ActivityCard/ActivityCard.component";
import GstaCalendar from "../GstaCalendar/GstaCalendar.component";
//#endregion

//#region service import
import { getSimulatorsMonthActivity } from "../../../Services/CenterService";
//#endregion

//#region functions import
import ScrollToTopController from "../../../Helper/CustomHook/ScrollToTopController";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../../Helper/TimeConverter";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region constants import
import { DATEPICKER_TYPE } from "../../../Components/AcreosDatePicker/AcreosDatePicker.constants";
import { SIMULATOR } from "../../../Constants/DataConstants";
import { DAYS, FORMAT_DAY_HOURS, FORMAT_DAY_PDF_EXPORT, MONTHS } from "../../../Constants/DateConstants";
import { ICON_EXPAND_MORE, ICON_HELP } from "../../../Constants/IconConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
import { MONTH_ACTIVITY } from "../../../Routing/PageNames";
//#endregion

//#region style import
import "./MonthActivity.style.scss";
//#endregion

const MonthActivity = () => {
  //#region state
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [monthlyActivity, setMonthlyActivity] = useState();
  const [loading, setLoading] = useState(false);
  const [datepickerOpen, setDatePickerOpen] = useState(false);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const refButton = useRef();
  //#endregion

  //#region functions
  const handleSetSelectedDateTime = async (newDateTime) => {
    setLoading(true);
    setSelectedDateTime(newDateTime);
    const monthlyActivity = await getSimulatorsMonthActivity(
      activeCenterId,
      moment(newDateTime).format(FORMAT_DAY_HOURS)
    );
    setMonthlyActivity(monthlyActivity);
    setLoading(false);
  };
  //#endregion
  //#region useQuery
  useQuery({
    queryKey: ["simulatorsMonthActivity"],
    queryFn: async () => {
      setLoading(true);
      const monthActivity = await getSimulatorsMonthActivity(activeCenterId, moment().format(FORMAT_DAY_PDF_EXPORT));
      setMonthlyActivity(monthActivity);
      setLoading(false);
      return monthActivity;
    },
    meta: {
      errorMessage: TRANSLATIONS.ERROR_GET_MONTHLY_ACTIVITY,
    },
    refetchOnWindowFocus: false,
  });
  //#endregion
  return (
    <div className="month-activity">
      <ScrollToTopController />
      {loading ? (
        <GstaLoaderPage LoadingText={getTranslations(TRANSLATIONS.HOME_LOADING, translations)} />
      ) : (
        <>
          <div className="home-title">
            <h1>{getTranslations(MONTH_ACTIVITY.pageTraductionKey, translations)}</h1>
            <AcreosDatePicker
              type={DATEPICKER_TYPE.SELECT_MONTH}
              months={MONTHS.map((month) => ({
                name: getTranslations(month.traductionKey, translations),
                number: month.number,
              }))}
              days={DAYS.map((day) => ({
                name: getTranslations(day.traductionKey, translations),
                number: day.number,
              }))}
              selectedDateTime={selectedDateTime}
              setSelectedDateTime={handleSetSelectedDateTime}
              disabledFutureDate
              datePickerOpen={datepickerOpen}
              setDatePickerOpen={setDatePickerOpen}
              refOpenDatePicker={refButton}
            >
              <button
                className="gsta-date-picker"
                onClick={() => setDatePickerOpen(!datepickerOpen)}
                ref={refButton}
              >
                <span>
                  {`${getTranslations(
                    MONTHS.find((month) => month.number === selectedDateTime?.getMonth() + 1).traductionKey,
                    translations
                  )} ${selectedDateTime?.getFullYear()}`}
                </span>
                <i className={`${ICON_EXPAND_MORE} ${datepickerOpen ? "gsta-date-picker-icon-down" : ""}`} />
              </button>
            </AcreosDatePicker>
          </div>

          <hr className="activity-separator" />
          <h3>
            <b>{getTranslations(TRANSLATIONS.HOME_MONTH_ACTIVITY_HISTORY, translations)}</b>
            <GstaToolTip
              toolTipContent={getTranslations(TRANSLATIONS.HOME_MONTH_ACTIVITY_HISTORY_INFORMATIONS, translations)}
            >
              <i className={ICON_HELP} />
            </GstaToolTip>
          </h3>
          <div className="activity-container">
            <div className="calendar-container">
              <GstaCalendar
                month={selectedDateTime.getMonth() + 1}
                year={selectedDateTime.getFullYear()}
                activities={monthlyActivity}
                simulatorOrStudent={SIMULATOR}
              />
            </div>
            <div className="activity-card-grid">
              <ActivityCard
                value={
                  monthlyActivity
                    ? formatSecondToStringTime(
                        monthlyActivity?.totalSimulatorsMonthActivity.duration +
                          monthlyActivity?.totalSimulatorsMonthActivity.freeDuration,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )
                    : getTranslations(TRANSLATIONS.COMMON_DEFAULT_TIME, translations)
                }
                legend={getTranslations(TRANSLATIONS.STUDENT_DETAIL_TRAINING_MACHINE_TOTAL_TIME_ELAPSED, translations)}
                loading={loading}
                cursusTime={monthlyActivity?.totalSimulatorsMonthActivity.duration}
                freeTime={monthlyActivity?.totalSimulatorsMonthActivity.freeDuration}
              />
              <ActivityCard
                value={monthlyActivity ? monthlyActivity.studentConnected.toString() : "--"}
                legend={getTranslations(TRANSLATIONS.HOME_STUDENT_CONNECTED, translations)}
              />

              <ActivityCard
                value={
                  monthlyActivity
                    ? formatSecondToStringTime(
                        monthlyActivity.totalSecondElapsedPerStudent,
                        translations,
                        FORMAT_TIMESTRING.HHMM_IGNOR_S
                      )
                    : getTranslations(TRANSLATIONS.COMMON_DEFAULT_TIME, translations)
                }
                legend={getTranslations(TRANSLATIONS.HOME_STUDENT_SIMULATOR_TIME_AVERAGE, translations)}
              />
              {monthlyActivity && monthlyActivity.totalFreeDurationHomePage >= 60 &&(
              <ActivityCard
              value = { formatSecondToStringTime(
                      monthlyActivity.totalFreeDurationHomePage, 
                      translations, 
                      FORMAT_TIMESTRING.HHMM_IGNOR_S
                    )}
                legend={getTranslations(TRANSLATIONS.HOME_STUDENT_SIMULATOR_TIME_AVERAGE_NO_STUDENT, translations)}
              /> 
              )}
            </div>
          </div>

          <h3>
            <b>{getTranslations(TRANSLATIONS.HOME_SIMULATOR_ACTIVITY, translations)}</b>
          </h3>
          <div className="activity-card-grid-simulator">
            {monthlyActivity &&
              monthlyActivity.simulatorsMonthActivity.map((simulatorMonthActivity) => (
                <ActivityCard
                  key={simulatorMonthActivity.simSerial}
                  simulatorName={simulatorMonthActivity.simSerial}
                  value={formatSecondToStringTime(
                    simulatorMonthActivity.duration + simulatorMonthActivity.freeDuration,
                    translations,
                    FORMAT_TIMESTRING.HHMM_IGNOR_S
                  )}
                  cursusTime={simulatorMonthActivity.duration}
                  freeTime={simulatorMonthActivity.freeDuration}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MonthActivity;
