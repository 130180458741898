//#region react import
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import GstaModal from "../GstaModal/GstaModal.component";
import SkillsCell from "../SkillsCell/SkillsCell.component";
import ModuleNameItem from "../ModuleNameItem/ModuleNameItem.component";
import SelectButtonAction from "../SelectButtonAction/SelectButtonAction.component";
import ModalFooter from "../ModalFooter/ModalFooter.component";
import GstaTable from "../GstaTable/GstaTable.component";
import TrainingItem from "../../Pages/Training/TrainingItem/TrainingItem.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region service import
import { getMachineModules } from "../../Services/MachinesService";
//#endregion

//#region constants import
import { ORDERED } from "../../Constants/HeaderType";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
//#endregion

const getValueToSelect = (value) => {
  return value.moduleNameKey ?? value.name;
};

/**
 * Component that allow the user to add step to a training
 */

const AddTrainingStepModal = ({
  modalOpen,
  customTraining,
  addStepToCustomTraining,
  setModalOpen,
  machineModules,
  setMachinesModules,
}) => {
  //#region useState

  const [selectedModuleIds, setSelectedModuleIds] = useState([]);

  //#endregion
  //#region others use...
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const getModuleName = (value) => {
    return getTranslations(value.moduleNameKey, translations);
  };

  const getExercicesCount = (value) => {
    let exerciceCount = 0;
    value.series.forEach((serie) => {
      exerciceCount += serie.exercises.length;
    });
    return exerciceCount;
  };

  const getStepDuration = (value) => {
    let durationInSeconds = 0;
    value.series.forEach((serie) => {
      serie.exercises.forEach((exercise) => {
        durationInSeconds += exercise.duration;
      });
    });
    return durationInSeconds;
  };

  const selectModule = (value) => {
    const newSelectedModuleIds = [...selectedModuleIds];
    newSelectedModuleIds.push(value);
    setSelectedModuleIds(newSelectedModuleIds);
  };

  const unselectModule = (value) => {
    const newSelectedModuleIds = [...selectedModuleIds];
    const indexToRemove = newSelectedModuleIds.findIndex((moduleOrder) => moduleOrder === value);
    newSelectedModuleIds.splice(indexToRemove, 1);
    setSelectedModuleIds(newSelectedModuleIds);
  };

  const handleValidate = () => {
    const stepsToAdd = machineModules.filter((module) => selectedModuleIds.includes(getValueToSelect(module)));
    addStepToCustomTraining(stepsToAdd);
    setSelectedModuleIds([]);
    setModalOpen(false);
  };
  //#endregion

  //#region useQuery
  const { isSuccess, data : machinesModulesData }= useQuery({
    queryKey: ["machinesModules"],
    queryFn: () =>  getMachineModules(activeCenterId, customTraining.machineId),
    refetchOnWindowFocus: false,
    enabled: machineModules?.length === 0,
  });

  useEffect(() => {
    if (isSuccess && machinesModulesData) {
      setMachinesModules(machinesModulesData);
    }
  }, [isSuccess, machinesModulesData, setMachinesModules]);
  //#endregion

  //#region useEffect
  //#endregion

  const headerDefinition = [
    {
      columnName: getTranslations("personnalized_training_table_name_step", translations),
      type: ORDERED,
      getValue: getModuleName,
      overrideColumn: ModuleNameItem,
      overrideProps: {
        getValue: getModuleName,
        getImage: (value) => process.env.PUBLIC_URL + value.picturePath,
      },
      searchColumn: true,
      width: "25%",
    },
    {
      columnName: getTranslations("personnalized_training_table_duration", translations),
      type: ORDERED,
      getValue: getStepDuration,
      overrideColumn: TrainingItem,
      overrideProps: {
        image: "timer",
        getValue: (value) => formatSecondToStringTime(getStepDuration(value), translations, FORMAT_TIMESTRING.HHMM),
      },
      searchColumn: true,
    },
    {
      columnName: getTranslations("personnalized_training_table_exercices_number", translations),
      type: ORDERED,
      getValue: getExercicesCount,
      overrideColumn: TrainingItem,
      overrideProps: {
        image: "traficlight",
        getValue: (value) =>
          `${getExercicesCount(value)} ${getTranslations("training_layout_detail_exercice", translations)}`,
      },
      searchColumn: true,
    },
    {
      columnName: getTranslations("personnalized_training_table_skills_worked", translations),
      getValue: (value) => <SkillsCell value={value} />,
      searchColumn: false,
    },
  ];

  return (
    <GstaModal
      title={getTranslations("personnalized_training_pop_up_steps_title", translations)}
      modalOpen={modalOpen}
      cancelText={getTranslations("common_cancel", translations)}
      deleteIcon={"icon-undo"}
      validIcon={"icon-check"}
      validText={getTranslations("common_validate", translations)}
      handleCloseModal={() => {
        setModalOpen(false);
        setSelectedModuleIds([]);
      }}
      FooterAddedComponent={ModalFooter}
      FooterAddedComponentProps={{
        texts:
          selectedModuleIds?.length > 0 ? [selectedModuleIds?.length, "personnalized_training_add_step_footer"] : [],
      }}
      hasTableInside
      validateDisabled={selectedModuleIds?.length === 0}
      handleValidate={handleValidate}
    >
      <GstaTable
        globalSearch
        pagination
        values={machineModules}
        headerDefinitions={headerDefinition}
        Actions={SelectButtonAction}
        ActionsProps={{
          defaultTranslation: getTranslations("choose", translations),
          onSelectTranslation: getTranslations("choosed", translations),
          selectIds: selectedModuleIds,
          getValueToSelect: getValueToSelect,
          selectValue: selectModule,
          unselectValue: unselectModule,
        }}
      />
    </GstaModal>
  );
};

export default AddTrainingStepModal;
