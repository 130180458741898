import { loadTranslations, setTranslationsDictionaries } from "../ReduxStore/translationSlice";
import { getDictionnariesToLoad } from "../Services/TranslationService";
import { getMachineTranslation } from "./GetMachineSource";
import { toCamel } from "./ToCamelCase";

export const loadTranslationFile = async (fileName) => {
  const json = await fetch(`${fileName}?timeStamp=${Date.now()}`)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      console.error(err);
    });

  if (!json) {
    console.error(`error : translation file not found -> ${fileName}`);
    return [];
  }
  const translations = getPropertyByNameOrByLowerCaseName(toCamel(json), "translationKeys");
  return translations;
};

export const loadConnexionPageDictionnary = async (language) => {
  const languageWithoutCountry = language.split("-")[0];
  if (process.env.NODE_ENV === "test") {
    return [];
  }
  return await loadTranslationFile(process.env.PUBLIC_URL + "/GSTA/gstaConnexionPage_" + languageWithoutCountry + ".json");
};

export const loadGstaDictionnary = async (language) => {
  const languageWithoutCountry = language.split("-")[0];
  if (process.env.NODE_ENV === "test") {
    return [];
  }
  return await loadTranslationFile(process.env.PUBLIC_URL + "/GSTA/translation_" + languageWithoutCountry + ".json");
};

export const getTranslations = (translationKey, translationDictionnary) => {
  let translation = translationKey;
  if (translationDictionnary && translationDictionnary.length > 0) {
    translation = translationDictionnary.find(
      (translationFromDictionary) => translationFromDictionary.code === translationKey
    );
    if (!translation) {
      translation = translationDictionnary.find(
        (translationDictionnary) => translationDictionnary.code === translationKey?.toString().substring(4)
      );
    }
  }
  return translation && translation.translation ? translation.translation.value : translationKey;
};

export const getPropertyByNameOrByLowerCaseName = (objectToFetch, propertyName) => {
  if (!objectToFetch) {
    console.error(`error : objectToFetch cannot be null when getting property : ${propertyName}`);
    return null;
  }
  if (!objectToFetch[propertyName]) {
    propertyName = propertyName.toLowerCase();
  }

  return objectToFetch[propertyName];
};

export const reloadEnginesTranslations = async (dispatch, centerId, language) => {
  const dictionnariesToLoad = await getDictionnariesToLoad(centerId);
  dispatch(setTranslationsDictionaries(dictionnariesToLoad));
  dictionnariesToLoad.forEach(async (dictionary) => {
    loadTranslationFile(getMachineTranslation(dictionary["acronym"], language, dictionnariesToLoad)).then(
      (dictionaryValues) => {
        dispatch(
          loadTranslations({
            translationsToAdd: dictionaryValues,
            language: language,
          })
        );
      }
    );
  });
};

export const reloadGSTATranslations = async (dispatch, language) => {
   await loadGstaDictionnary(language).then((dictionaryValues) => {
    dispatch(
      loadTranslations({
        translationsToAdd: dictionaryValues,
        language: language,
      })
    );
  });
};
