//#region react import
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region component import
import AcreosNavigation from "../../Components/AcreosNavigation/AcreosNavigation.component";
import BackButton from "../../Components/BackButton/BackButton.component";
import ExportPdfOptions from "../../Components/ExportPdfOptions/ExportPdfOptions.component";
import ExportPdfParametersModal from "../../Components/ExportPdfParametersModal/ExportPdfParametersModal.component";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import SwitchWithText from "../../Components/SwitchWithText/SwitchWithText.component";
import GlobalLayout from "../GlobalLayout/GlobalLayout.component";
import AddTrainingModal from "../../Pages/Training/AddTrainingModal/AddTrainingModal.component";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { setStudent } from "../../ReduxStore/studentsSlice";
//#endregion

//#region function import
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import useNotationType from "../../Helper/CustomHook/useNotationType";
import { PrepareCustomPdf, getNotificationMessage } from "../../Helper/ExportPdf";
import { DisableOverflow, EnableOverflow } from "../../Helper/Overflow";
import { getEndPeriod, getStartPeriod } from "../../Pages/Student/Student/Student.constants";
import { getStudentResultsByTemplate } from "../../Services/StudentService";
//#endregion

//#region service import
import { getTranslations } from "../../Helper/TranslationController";
import { setSimulatorAccess } from "../../Services/StudentService";
import {
  closeStudentSubscription,
  createStudentSubscription,
  getStudentSubscriptions,
  studentResubscription,
} from "../../Services/SubscriptionsService";
//#endregion

//#region constants import
import { NO, YES } from "../../Components/GstaModal/GstaModal.constants";
import { STUDENT_INSCRIPTION } from "../../Constants/AddtrainingModalType";
import { GROUPS, ICON_NOTE_ADD, ICON_PDF } from "../../Constants/IconConstants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { STUDENTS, STUDENTS_ARCHIVED, STUDENTS_PERSONNAL_INFORMATIONS, STUDENTS_TRAINING } from "../../Routing/PageNames";
//#endregion

//#region style import
import "./StudentDetailsGlobalLayout.style.scss";
//#endregion

const StudentDetailsGlobalLayout = ({ children }) => {
  //#region useSelector
  const dispatch = useDispatch();
  const notationType = useSelector((state) => state.settingSlice.notationType);
  const student = useSelector((state) => state.studentSlice.student);
  const customFields = useSelector((state) => state.studentSlice.customFields);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const centerName = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.name);
  const translations = useSelector((state) => state.translationSlice.translations);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  const exportPdfData = useSelector((state) => state.studentSlice.exportPdfData);
  //#endregion

  //#region usestate
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrainingToSubscribe, setSelectedTrainingToSubscribe] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [trainingsToUnsubscribe, setTrainingsToUnsubscribe] = useState([]);
  const [trainingsToResubscribe, setTrainingsToResubscribe] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showExportPdfOptions, setShowExportPdfOptions] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [pdfTemplates, setPdfTemplates] = useState(exportPdfData?.pdfTemplates);
  const [selectedPdfTemplate, setSelectedPdfTemplate] = useState(exportPdfData?.selectedPdfTemplate);
  const [selectedPeriod, setSelectedPeriod] = useState(exportPdfData?.selectedPeriod);
  const [personalizedStartDate, setPersonalizedStartDate] = useState();
  const [personalizedEndDate, setPersonalizedEndDate] = useState();
  //#endregion

  //#region other use
  const navigate = useNavigate();
  //#endregion

  //#region constant
  const dataStudentDetailMenu = [
    {
      id: 1,
      url: STUDENTS_TRAINING.url,
      title: getTranslations(STUDENTS_TRAINING.pageTraductionKey, translations),
    },
    {
      id: 2,
      url: STUDENTS_PERSONNAL_INFORMATIONS.url,
      title: getTranslations(STUDENTS_PERSONNAL_INFORMATIONS.pageTraductionKey, translations),
    },
  ];
  //#endregion

  //#region functions import
  const handleOpenModal = async () => {
    try {
      setModalOpen(!modalOpen);
      DisableOverflow();
      if (!modalOpen) {
        setSelectedTrainingToSubscribe([]);
        setTrainingsToUnsubscribe([]);
        setTrainingsToResubscribe([]);
        const studentSubscriptions = await getStudentSubscriptions(centerId, student.id);
        setSubscriptions(studentSubscriptions);
      }
    } catch (e) {
      EnableOverflow();
      dispatch(setErrorNotification(e));
    }
  };

  const handleCloseTrainingModal = () => {
    setModalOpen(false);
    EnableOverflow();
  };

  const handleValidate = async () => {
    try {
      await createStudentSubscription(
        centerId,
        selectedTrainingToSubscribe.map((trainingId) => ({
          studentId: student.id,
          trainingId: trainingId,
        }))
      );

      if (trainingsToUnsubscribe.length > 0) {
        await closeStudentSubscription(centerId, trainingsToUnsubscribe);
      }

      if (trainingsToResubscribe.length > 0) {
        await studentResubscription(centerId, trainingsToResubscribe);
      }
      dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_STUDENT_INSCRIPTION, translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleChangeStudentSimulatorAccess = async () => {
    try {
      const newValue = (await setSimulatorAccess(centerId, student.id, !student.isActive)).dataModified;
      const studentModified = { ...student };
      studentModified.isActive = newValue;
      dispatch(setStudent({ newStudent: studentModified }));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const generateCustomPdfDocument = async () => {
    setLoading(true);
    let personalizedStartDateCloned;
    let personalizedEndDateCloned;
    if (!modalExportOpen) {
      personalizedStartDateCloned = null;
      personalizedEndDateCloned = null;
    } else {
      personalizedStartDateCloned = personalizedStartDate;
      personalizedEndDateCloned = personalizedEndDate;
    }
    try {
      const studentResult = await getStudentResultsByTemplate(centerId, student.id, {
        templateId: selectedPdfTemplate.id,
        startPeriod: getStartPeriod(student, sessionDates, selectedPeriod, personalizedStartDateCloned),
        endPeriod: getEndPeriod(student, sessionDates, selectedPeriod, personalizedEndDateCloned),
        showTime: selectedPdfTemplate?.showTime,
      });
      await PrepareCustomPdf(
        notationType,
        translations,
        getStartPeriod(student, sessionDates, selectedPeriod, personalizedStartDateCloned),
        getEndPeriod(student, sessionDates, selectedPeriod, personalizedEndDateCloned),
        sessionDates,
        customFields,
        selectedPdfTemplate,
        {
          ...studentResult,
          customValues: student.customValues,
          firstName: student.firstName,
          lastName: student.name,
          center: centerName,
        },
        centerId,
        setLoading,
        () => dispatch(setSuccessNotification(getNotificationMessage(student, translations))),
        () => dispatch(setErrorNotification(getTranslations(TRANSLATIONS.ERROR_GENERATE_PDF, translations)))
      );
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
    setShowExportPdfOptions(false);
    setModalExportOpen(false);
  };

  const getBackButtonInfo = (student) => {
    let backButtonInfo = {
      text: TRANSLATIONS.STUDENT_DETAIL_BACK_STUDENT_LIST,
      url: STUDENTS.url,
    };
    if (student?.archived) {
      backButtonInfo = {
        text: TRANSLATIONS.STUDENT_DETAIL_BACK_STUDENT_LIST_ARCHIVED,
        url: STUDENTS_ARCHIVED.url,
      };
    }
    return backButtonInfo;
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region hook
  useNotationType(dispatch, centerId, notationType);
  const refExportPdfOptions = useRef();
  useClickOutside([refExportPdfOptions], true, setShowExportPdfOptions);
  //#endregion

  return (
    <GlobalLayout
      icon={GROUPS}
      title={STUDENTS.pageTraductionKey}
    >
      <main className="student-details-global-layout">
        {loading && <GstaLoaderPage />}
        <ScrollToTopController />
        <div>
          <div className="header-student-detail-back-button">
            <BackButton text={getTranslations(getBackButtonInfo(student)?.text, translations)} backFunction={() => navigate(getBackButtonInfo(student)?.url)} />
          </div>
          <div className="header-student-detail-and-button">
            <div className="header-student-detail">
              <h1 className="header-student-detail-name">
                {student.name} {student.firstName}
              </h1>
              {!(sessionDates === "true" || student?.archived) && (
                <div className="header-student-detail-simulator-access">
                  <span className="header-student-detail-simulator-access-span">
                    {getTranslations(TRANSLATIONS.STUDENT_DETAIL_SIMULATOR_ACCESS, translations)}
                  </span>
                  <SwitchWithText
                    dataTestId={"switch-simulator-access"}
                    onText={getTranslations(YES, translations)}
                    offText={getTranslations(NO, translations)}
                    checked={student.isActive}
                    handleChange={handleChangeStudentSimulatorAccess}
                  />
                </div>
              )}
            </div>
            <div className="header-student-detail-button">
              <button
                className="gsta-button-outlined"
                data-testid="student-detail-button-export-test-id"
                onClick={() => setShowExportPdfOptions(!showExportPdfOptions)}
                disabled={loading}
              >
                <i className={ICON_PDF} />
                <span className="header-student-detail-button-export-label">
                  {getTranslations(TRANSLATIONS.STUDENT_DETAIL_RESULT_EXPORT, translations)}
                </span>
              </button>
              {!student?.archived && (
                <button
                  className="gsta-button-outlined"
                  onClick={handleOpenModal}
                  data-testid="student-detail-button-gestion-test-id"
                >
                  <i className={ICON_NOTE_ADD} />
                  <span>{getTranslations(TRANSLATIONS.STUDENT_DETAIL_INSCRIPTION_MANAGE, translations)}</span>
                </button>
              )}
              {showExportPdfOptions && (
                <ExportPdfOptions
                  refExportPdfOptions={refExportPdfOptions}
                  setModalOpen={setModalExportOpen}
                  generateCustomPdfDocument={generateCustomPdfDocument}
                />
              )}
              <ExportPdfParametersModal
                modalOpen={modalExportOpen}
                setModalOpen={setModalExportOpen}
                generateCustomPdfDocument={generateCustomPdfDocument}
                pdfTemplates={pdfTemplates}
                setPdfTemplates={setPdfTemplates}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                selectedPdfTemplate={selectedPdfTemplate}
                setSelectedPdfTemplate={setSelectedPdfTemplate}
                personalizedStartDate={personalizedStartDate}
                setPersonalizedStartDate={setPersonalizedStartDate}
                personalizedEndDate={personalizedEndDate}
                setPersonalizedEndDate={setPersonalizedEndDate}
              />
            </div>
          </div>
          <div className="margin-left-right">
            <AcreosNavigation
              maxNavElement={6}
              navData={dataStudentDetailMenu}
              structureClass={"gsta-sub-navigation"}
              overrideCustomLinkClass={"gsta-sub-navigation-link"}
            />
          </div>
        </div>
        {modalOpen && (
          <AddTrainingModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleModalClose={handleCloseTrainingModal}
            selectedTrainingToSubscribe={selectedTrainingToSubscribe}
            setSelectedTrainingToSubscribe={setSelectedTrainingToSubscribe}
            selectedTrainingToReSubscribe={trainingsToResubscribe}
            setSelectedTrainingToReSubscribe={setTrainingsToResubscribe}
            selectedTrainingToUnsubscribe={trainingsToUnsubscribe}
            setSelectedTrainingToUnsubscribe={setTrainingsToUnsubscribe}
            subscriptions={subscriptions}
            setTrainingSubscribed={setSubscriptions}
            setValues={handleValidate}
            type={STUDENT_INSCRIPTION}
            students={[student]}
            title={`${getTranslations(TRANSLATIONS.ADD_TRAINING_MODAL_STUDENT_TRAINING_INSCRIPTION, translations)} ${
              student.firstName
            } ${student.name}`}
          />
        )}
        <div className="margin-left-right">{children}</div>
      </main>
    </GlobalLayout>
  );
};

export default StudentDetailsGlobalLayout;
