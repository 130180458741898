//#region react import
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region component import
import ActionsGrouped from "../../../Components/ActionsGrouped/ActionsGrouped.component";
import ExportPdfModal from "../../../Components/ExportPdfModal/ExportPdfModal.component";
import GstaLoaderPage from "../../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import AddTrainingModal from "../../Training/AddTrainingModal/AddTrainingModal.component";
import ProfileModal from "../ProfileModal/ProfileModal.component";
//#endregion

//#region function import
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region service import
import { getCenterSettings } from "../../../Services/SettingsService";
import { setArchivedStudents, setSimulatorAccess } from "../../../Services/StudentService";
import {
  closeStudentSubscription,
  createStudentSubscription,
  getStudentSubscriptions,
  studentResubscription,
} from "../../../Services/SubscriptionsService";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../../ReduxStore/notificationSlice";
//#endregion

//#region constants import
import { EXPORT_TYPE } from "../../../Components/ExportPdfModal/ExportPdfModal.constants";
import { STUDENT_INSCRIPTION } from "../../../Constants/AddtrainingModalType";
import { ICON_NOTE_ADD, ICON_UNARCHIVE, ICON_ARCHIVE, ICON_EXPAND_MORE_DROPDOWN, ICON_PENCIL, ICON_PDF, ICON_SWITCH_OFF, ICON_SWITCH_ON } from "../../../Constants/IconConstants";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../../Constants/SettingsConstants";
import { TRANSLATIONS } from "../../../Constants/Translations";
//#endregion

//#region import style
import "./StudentGroupedActions.style.scss";
//#endregion

const StudentGroupedActions = ({ values, selectedObject, setValue, GroupedActionProps }) => {
  //#region state
  const [trainingModalOpen, setTrainingModalOpen] = useState(false);
  const [profilModalOpen, setprofileModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [trainingsToUnsubscribe, setTrainingsToUnsubscribe] = useState([]);
  const [trainingsToResubscribe, setTrainingsToResubscribe] = useState([]);
  const [managesWithDates, setManageWithDates] = useState(false);
  const [archivedStudentsModalOpen, setArchivedStudentsModalOpen] = useState(false);
  const [selectedTrainingToSubscribe, setSelectedTrainingToSubscribe] = useState([]);
  const [exportPdfModalOpen, setExportPdfModalOpen] = useState(false);
  //#endregion

  //#region redux store
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  //#endregion

  const getSettings = async () => {
    try {
      const manageWithDatesValue = await getCenterSettings(centerId, MANAGE_STUDENTS_ACCESS_WITH_DATES);
      setManageWithDates(manageWithDatesValue.value === "true");
      return manageWithDatesValue;
    } catch (e) {
      dispatch(setErrorNotification({message: getTranslations(TRANSLATIONS.ERROR_GET_STUDENTS_ACCESS_WITH_DATES, translations)}));
      throw e;
    }
  };

  useQuery({
    queryKey: ["studentGroupedActionsSettings"],
    queryFn: getSettings,
  });

  const selectedStudents = values.filter((value) => selectedObject.find((obj) => obj === value.id));
  const selectedStudentsCount = selectedStudents.length;

  const handleChangeActivateStudent = async (newValue) => {
    let selectedStudent = null;
    let newStudent;
    let itsOk = false;
    const newStudents = [];
    try {
      for (let selectedObjectValue of selectedObject) {
        selectedStudent = values.find((student) => student.id === selectedObjectValue);
        if (selectedStudent.isActive !== newValue) {
          newStudent = { ...selectedStudent };
          newStudent.isActive = (await setSimulatorAccess(centerId, selectedStudent.id, newValue)).dataModified;
          newStudents.push(newStudent);
          itsOk = true;
        }
      }
      GroupedActionProps.setValues(newStudents);
    } catch (e) {
      dispatch(setErrorNotification({message: getTranslations(TRANSLATIONS.STUDENT_UNARCHIVED_ERROR_ONE_STUDENT, translations)}));
    }
    return itsOk;
  };

  const handleActivateSelectedStudent = async () => {
    if (await handleChangeActivateStudent(true)) {
      dispatch(
        setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_STUDENT_GROUPED_ACTIONS_SIMULATOR_ACCESS, translations))
      );
    }
  };

  const handleDeactivateSelectedStudent = async () => {
    if (await handleChangeActivateStudent(false)) {
      dispatch(
        setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_STUDENT_GROUPED_ACTIONS_SIMULATOR_DENY, translations))
      );
    }
  };

  const handleOpenTrainingModal = async () => {
    if (selectedObject.length === 1) {
      setTrainingsToUnsubscribe([]);
      setTrainingsToResubscribe([]);
      const studentSubscriptions = await getStudentSubscriptions(centerId, selectedObject[0]);
      setSubscriptions(studentSubscriptions);
    }
    setSelectedTrainingToSubscribe([]);
    setTrainingModalOpen(true);
  };

  const handleCloseTrainingModal = () => {
    setTrainingModalOpen(false);
  };

  const handleSubscribeAllStudentToTraining = async () => {    
    for (let student of selectedStudents) {
      const actualStudentId = student.id;

      const subscriptionsToCreate = [];
      selectedTrainingToSubscribe.forEach((trainingId) => {
        subscriptionsToCreate.push({ studentId: actualStudentId, trainingId: trainingId });
      });
      try {
        await createStudentSubscription(centerId, subscriptionsToCreate);
        if (selectedObject.length === 1) {
          await closeStudentSubscription(centerId, trainingsToUnsubscribe);
          await studentResubscription(centerId, trainingsToResubscribe);
        }
      } catch (e) {
        dispatch(setErrorNotification({message : getTranslations(TRANSLATIONS.ERROR_NOTIFICATION_STUDENT_TRAINING_INSCRIPTION, translations)}));
      }
    }
    dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_STUDENT_TRAINING_INSCRIPTION, translations)));
    setLoading(false);
  };

  const handleClickModifyProfileInformation = () => {
    setprofileModalOpen(true);
  };

  const handleValidateChangeArchivedValue = async () => {
    try {
      await setArchivedStudents(centerId, selectedObject, GroupedActionProps?.archived ? false : true);
      GroupedActionProps.removeStudents(selectedObject);
      setArchivedStudentsModalOpen(false);
      dispatch(
        setSuccessNotification(
          GroupedActionProps?.archived ? TRANSLATIONS.STUDENT_UNARCHIVED_SUCCESSFULLY : TRANSLATIONS.STUDENT_ARCHIVED_SUCCESSFULLY,
          translations
        )
      );
    } catch (e) {
      dispatch(setErrorNotification({message: getTranslations(TRANSLATIONS.STUDENT_ARCHIVED_ERROR_ONE_STUDENT, translations)}));
    }
  };

  const actionList = [];

  if (!GroupedActionProps?.archived) {
    actionList.push({
      action: () => setExportPdfModalOpen(true),
      iconClass: ICON_PDF,
      text: getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_PDF_EXPORT, translations),
    });
    actionList.push({
      action: handleClickModifyProfileInformation,
      iconClass: ICON_PENCIL,
      text: getTranslations(TRANSLATIONS.PERSONNALIZED_FIELD_GROUPED_ACTION, translations),
    });
    actionList.push({
      action: handleOpenTrainingModal,
      iconClass: ICON_NOTE_ADD,
      text: getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_INSCRIPTION, translations),
    });
    if (!managesWithDates) {
      actionList.unshift({
        action: handleDeactivateSelectedStudent,
        iconClass: ICON_SWITCH_OFF,
        text: getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_SIMULATOR_DENY, translations),
      });
      actionList.unshift({
        action: handleActivateSelectedStudent,
        iconClass: ICON_SWITCH_ON,
        text: getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_SIMULATOR_ACCESS, translations),
      });
    }
  } else {
    actionList.push({
      action: handleOpenTrainingModal,
      iconClass: ICON_NOTE_ADD,
      text: getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_INSCRIPTION, translations),
    });
  }
  actionList.push({
    action: () => setArchivedStudentsModalOpen(true),
    iconClass: GroupedActionProps?.archived ? ICON_UNARCHIVE : ICON_ARCHIVE,
    text: getTranslations(
      GroupedActionProps?.archived ? TRANSLATIONS.UNARCHIVE_STUDENTS_MODAL_TITLE : TRANSLATIONS.ARCHIVE_STUDENTS_MODAL_TITLE,
      translations
    ),
  });
  //#endregion
  return (
    <div className={"student-grouped-actions-container"}>
      {exportPdfModalOpen && (
        <ExportPdfModal
          modalOpen={exportPdfModalOpen}
          setModalOpen={setExportPdfModalOpen}
          studentsSelected={selectedObject}
          defaultTabSelected={selectedObject.length === 1 ? EXPORT_TYPE.INDIVIDUAL : EXPORT_TYPE.GROUP}
          pdfTemplates={GroupedActionProps.pdfTemplates}
          setPdfTemplates={GroupedActionProps.setPdfTemplates}
          selectedPeriod={GroupedActionProps.selectedPeriod}
          setSelectedPeriod={GroupedActionProps.setSelectedPeriod}
          selectedPdfTemplate={GroupedActionProps.selectedPdfTemplate}
          setSelectedPdfTemplate={GroupedActionProps.setSelectedPdfTemplate}
          personalizedStartDate={GroupedActionProps.personalizedStartDate}
          setPersonalizedStartDate={GroupedActionProps.setPersonalizedStartDate}
          personalizedEndDate={GroupedActionProps.personalizedEndDate}
          setPersonalizedEndDate={GroupedActionProps.setPersonalizedEndDate}
        />
      )}
      {loading && (
        <GstaLoaderPage LoadingText={getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_PDF_GENERATION, translations)} />
      )}
      <p className="grouped-action-text">
        {`${selectedStudentsCount} ${getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_SELECTED_ELEMENTS, translations)}`}
      </p>
      <ActionsGrouped
        appearanceClass={"gsta-button-fill"}
        content={
          <>
            <span>{getTranslations(TRANSLATIONS.STUDENT_GROUPED_ACTIONS_LABEL, translations)}</span>
            <i className={ICON_EXPAND_MORE_DROPDOWN} />
          </>
        }
        overrideClick={() => {
          document.querySelector(".icon-expand_more").classList.toggle("dropup");
          document.querySelector(".icon-expand_more").classList.toggle("dropdown");
        }}
        actionsList={actionList}
      />

      {trainingModalOpen && (
        <AddTrainingModal
          type={selectedObject.length === 1 ? STUDENT_INSCRIPTION : undefined}
          subscriptions={selectedObject.length === 1 ? subscriptions : undefined}
          selectedTrainingToSubscribe={selectedTrainingToSubscribe}
          setSelectedTrainingToSubscribe={setSelectedTrainingToSubscribe}
          selectedTrainingToReSubscribe={trainingsToResubscribe}
          setSelectedTrainingToReSubscribe={setTrainingsToResubscribe}
          selectedTrainingToUnsubscribe={trainingsToUnsubscribe}
          setSelectedTrainingToUnsubscribe={setTrainingsToUnsubscribe}
          modalOpen={trainingModalOpen}
          setModalOpen={setTrainingModalOpen}
          handleModalClose={handleCloseTrainingModal}
          validateAction={handleSubscribeAllStudentToTraining}
          students={selectedStudents}
          title={getTranslations(TRANSLATIONS.ADD_TRAINING_MODAL_MULTIPLE_STUDENT_TRAINING_INSCRIPTION, translations)}
          setValues={GroupedActionProps.setValues}
        />
      )}
      {archivedStudentsModalOpen && (
        <GstaModal
          modalOpen={archivedStudentsModalOpen}
          setModalOpen={setArchivedStudentsModalOpen}
          cancelText={getTranslations(TRANSLATIONS.COMMON_CANCEL, translations)}
          validText={getTranslations(TRANSLATIONS.COMMON_VALIDATE, translations)}
          handleCloseModal={() => setArchivedStudentsModalOpen(false)}
          handleValidate={handleValidateChangeArchivedValue}
          title={getTranslations(
            GroupedActionProps?.archived ? TRANSLATIONS.UNARCHIVE_STUDENTS_MODAL_TITLE : TRANSLATIONS.ARCHIVE_STUDENTS_MODAL_TITLE,
            translations
          )}
        >
          <div className="archived-unarchived-students_modal-content">
            <span>
              {getTranslations(
                GroupedActionProps?.archived ? TRANSLATIONS.UNARCHIVE_STUDENTS_MODAL_TEXT : TRANSLATIONS.ARCHIVE_STUDENTS_MODAL_TEXT,
                translations
              )}
            </span>
            <span>{`${selectedStudentsCount} ${getTranslations(
              TRANSLATIONS.PERSONNALIZED_FIELD_STUDENTS_SELECTED,
              translations
            )}`}</span>
          </div>
        </GstaModal>
      )}
      {profilModalOpen && (
        <ProfileModal
          profilModalOpen={profilModalOpen}
          closeModal={() => setprofileModalOpen(false)}
          selectedStudents={selectedObject}
          setValues={GroupedActionProps.setValues}
        />
      )}
    </div>
  );
};

export default StudentGroupedActions;
