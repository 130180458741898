//#region react import
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region constants import
import { ICON_WEST } from "../../Constants/IconConstants";
//#endregion

//#region style import
import "./ImportStudentsActions.style.scss";
//#endregion

/**
 *  actions buttons component created to be rendered in importStudentTable
 */

const ImportStudentsActions = ({ value, ActionsProps }) => {
  //#region useState
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredTableBeforeModification, setFilteredTableBeforeModification] = useState();
  //#endregion

  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const handleClickEditButton = (value) => {
    if (value) {
      const currentTable = ActionsProps.filteredTable.map((row) => ({ ...row, values: [...row.values] }));
      setFilteredTableBeforeModification(currentTable);
      ActionsProps.setIsRowEdit(value);
      ActionsProps.handleSelectRowToEddit(value);
    }
  };

  const handleBackClickButton = () => {
    ActionsProps.setFilteredTable(filteredTableBeforeModification);
    setFilteredTableBeforeModification();
    ActionsProps.setIsRowEdit(false);
    ActionsProps.handleSelectRowToEddit();
  };

  const handleDeleteButton = () => {
    ActionsProps.handleDeleteClick(value.id);
    setModalOpen(false);
  };
  //#endregion

  return (
    <div className="import-students-modal-table_actions">
      <GstaModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={getTranslations("excel_import_delete_ligne_title", translations)}
        cancelText={getTranslations("common_cancel", translations)}
        validText={getTranslations("common_validate", translations)}
        handleValidate={handleDeleteButton}
        handleCloseModal={() => setModalOpen(false)}
      >
        <span>{getTranslations("excel_import_delete_ligne_message", translations)}</span>
      </GstaModal>
      {ActionsProps.selectedStudent?.id !== value.id && (
        <>
          <button
            disabled={ActionsProps.isRowEdit}
            className="gsta-button-outlined"
            onClick={() => handleClickEditButton(value)}
          >
            <i className="icon-pencil" />
          </button>
          <button
            disabled={ActionsProps.isRowEdit}
            className="gsta-button-outlined"
            onClick={() => setModalOpen(true)}
          >
            <i className="icon-backspace" />
          </button>
        </>
      )}
      {ActionsProps.selectedStudent?.id === value.id && (
        <>
          <button
            className="gsta-button-outlined"
            onClick={ActionsProps.handleValidateClick}
          >
            <i className="icon-check" />
          </button>
          <button
            className="gsta-button-outlined"
            onClick={handleBackClickButton}
          >
            <i className={ICON_WEST} />
          </button>
        </>
      )}
    </div>
  );
};

export default ImportStudentsActions;
