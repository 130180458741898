import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  machines: []
};

export const machineSlice = createSlice({
 name:'machineSlice',
 initialState: initialState,
 reducers:{
  setMachines:(state, action)=>{
    state.machines = action.payload;
  },
  resetMachineSlice:(state, action) => {
    state.machines = initialState.machines;
  }
 }
})

export const createCustomMachineSlice = (initialState) => {
  return createSlice({
    name: "customMachineSlice",
    initialState: initialState,
    reducers: {
    },
  });
};

export const { setMachines, resetMachineSlice } = machineSlice.actions;

export default machineSlice.reducer;