//#region react import
import { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import GstaModal from "../GstaModal/GstaModal.component.jsx";
//#endregion

//#region function import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { ICON_UNKNOWN_DOCUMENT } from "../../Constants/IconConstants.js";
import { TRANSLATIONS } from "../../Constants/Translations.js";
//#endregion

//#region import style
import "./DocumentationModal.style.scss";
//#endregion

const DocumentationModal = () => {
  //#region redux store
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region state
  const [modalOpen, setModalOpen] = useState(false);
  //#endregion

  return (
    <div className="gsta-documentation">
      <i
        className={`${ICON_UNKNOWN_DOCUMENT}`}
        onClick={() => setModalOpen(true)}
      />
      {modalOpen && (
        <GstaModal
          modalOpen={modalOpen}
          title={getTranslations(TRANSLATIONS.DOCUMENTATION_MODAL_TITLE, translations)}
          cancelText={getTranslations(TRANSLATIONS.COMMON_CANCEL, translations)}
          handleCloseModal={() => setModalOpen(false)}
          className={"documentation-modal"}
        ></GstaModal>
      )}
    </div>
  );
};
export default DocumentationModal;
