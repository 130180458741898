//#region react import
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
//#endregion

//#region custom import
import MachineResult from "../../Student/MachineResult/MachineResult.component";
import Skills from "../Skills/Skills.component";
//#endregion

//#region store import
//#endregion

//#region constants import
import { getTranslations } from "../../../Helper/TranslationController";
import { setCurrentStepId, setSubscriptionId } from "../../../ReduxStore/subscriptionSlice";
import { getSubscriptionsResult } from "../../../Services/SubscriptionsService";
//#endregion

//#region style import
import "./StudentTraining.style.scss";
//#endregion

const StudentTraining = () => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const student = useSelector((state) => state.studentSlice.student);
  const dispatch = useDispatch();

  const { data } = useQuery({
    queryKey: ["machinesModules"],
    queryFn: () => {
      const subcriptionResult = getSubscriptionsResult(centerId, student.id)
      return subcriptionResult;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => {
      dispatch(setCurrentStepId(null));
      dispatch(setSubscriptionId(null));
    };
  }, [dispatch]);

  return (
    <div className="flex-wrap">
      <h1>{getTranslations("pdf_driver_profile_no_title", translations)}</h1>
      <div className="student-traininig-card">
        <Skills
          noSkillPlaceHolder={getTranslations("pdf_driver_profile_no_skill", translations)}
          skills={data?.genericSkills}
        />
      </div>
      <h1>{`${getTranslations("common_machine", translations)} ${getTranslations("common_of", translations)} ${
        student.firstName
      } ${student.name}`}</h1>
      {data &&
        data?.machineResults?.map((machineResult, index) => (
          <MachineResult
            key={index}
            machineResult={machineResult}
          />
        ))}
    </div>
  );
};

export default StudentTraining;
