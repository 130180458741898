//#region react import
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GroupedButton from "../../Components/GroupedButton/GroupedButton.component";
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import PdfSettingSection from "../../Components/PdfSettingSection/PdfSettingSection.component";
import UndisplayFeature from "../../Components/UndisplayFeature/UndisplayFeature.component";
import CenterParameters from "../../Pages/Center/CenterParameters/CenterParameters.component";
//#endregion

//#region services import
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { setNotationType, setSetting } from "../../ReduxStore/settingSlice";
import { getCenterSettings, setCenterSettings } from "../../Services/SettingsService";
//#endregion

//#region constants import
import {
  CHRONOMETER_VALUES,
  EDIT_STUDENT_CONFIG_COMMAND,
  EXAMINE_MODULES_EXTENDED,
  MEASURING_SYSTEM_CODE,
  MODIFY_COMMAND,
  MODIFY_EXAMINE_MODULES,
  SHOW_NOTATION_CODE,
  STUDENT_CHRONO,
  TOKEN_VALIDITY_TIME,
  TOKEN_VALIDITY_TIME_VALUES,
  TYPE_NOTATION_CODE,
} from "../../Constants/SettingsConstants";
//#endregion

//#region style import
import GstaToolTip from "../../Components/GstaToolTip/GstaToolTip.component";
import "./CenterParametersLayout.style.scss";
//#endregion

/**
 * Layout of the center parameters page
 */

const CenterParametersLayout = ({ title }) => {
  //#region useState
  const [simulatorNotation, setSimulatorNotation] = useState(false);
  const [simulatorNotationType, setSimulatorNotationType] = useState("");
  const [simulatorMeasuringSystem, setSimulatorMeasuringSystem] = useState("");
  const [configCommand, setConfigCommand] = useState("");
  const [examineModules, setExamineModules] = useState("");
  const [tokenValidityTime, setTokenValidityTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [chronometerValue, setChronometerValue] = useState("");
  //#endregion

  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translationDictionariesToLoad = useSelector((state) => state.translationSlice.translationsDictionariesToLoad);
  //#endregion

  //#region others use...
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  const handleChangeShowSimulatorNotationValue = (newValue) => {
    setSimulatorNotation(newValue === "true");
  };

  const handleChangeShowSimulatorNotation = () => {
    handleChangeSetting(SHOW_NOTATION_CODE, !simulatorNotation, handleChangeShowSimulatorNotationValue, () =>
      setSetting({ settingName: SHOW_NOTATION_CODE, settingValue: !simulatorNotation })
    );
  };

  const handleChangeSimulatorNotationType = (notationType) => {
    handleChangeSetting(TYPE_NOTATION_CODE, notationType, setSimulatorNotationType, setNotationType);
  };

  const handleChangeSimulatorMeasuringSystem = (measuringSystem) => {
    handleChangeSetting(MEASURING_SYSTEM_CODE, measuringSystem, setSimulatorMeasuringSystem, () =>
      setSetting({ settingName: MEASURING_SYSTEM_CODE, settingValue: measuringSystem })
    );
  };

  const handleChangeConfigCommand = (configCommand) => {
    handleChangeSetting(EDIT_STUDENT_CONFIG_COMMAND, configCommand, setConfigCommand, () =>
      setSetting({ settingName: EDIT_STUDENT_CONFIG_COMMAND, settingValue: configCommand })
    );
  };

  const handleChangeTokenValidityTime = (tokenValidityTimeNewValue) => {
    handleChangeSetting(TOKEN_VALIDITY_TIME, tokenValidityTimeNewValue, setTokenValidityTime, () =>
      setSetting({ settingName: TOKEN_VALIDITY_TIME, settingValue: tokenValidityTimeNewValue })
    );
  };

  const handleChangeChronometerValue = (newChronometerValue) => {
    handleChangeSetting(STUDENT_CHRONO, newChronometerValue, setChronometerValue, () =>
      setSetting({ settingName: STUDENT_CHRONO, settingValue: newChronometerValue })
    );
  };

  const handleChangeSetting = async (settingCode, settingValue, setSettingValue, dispatchSetting) => {
    try {
      const newValue = (await setCenterSettings(centerId, settingValue, settingCode)).dataModified;
      setSettingValue(newValue);
      dispatchSetting && dispatch(dispatchSetting(newValue));
      dispatch(setSuccessNotification(getTranslations("notification_parameter_updated", translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleChangeExamineModulesAuthorization = (newValue) => {
    handleChangeSetting(EXAMINE_MODULES_EXTENDED, newValue, setExamineModules, () =>
      setSetting({ settingName: EXAMINE_MODULES_EXTENDED, settingValue: newValue })
    );
  };

  const checkInstalledMachines = () => {
    return !translationDictionariesToLoad.some((machine) => machine.acronym === "SYL" || machine.acronym === "LOG");
  };

  //#endregion

  //#region useQuery
  const { isFetched: isSettingShowNoteFetched, isError: isSettingShowNoteError } = useQuery({
    queryKey: ["settingShowNote"],
    queryFn: () => getCenterSettings(centerId, SHOW_NOTATION_CODE),
    meta: {
      onSuccess: (settingShowNote) => setSimulatorNotation(settingShowNote.value === "true"),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });

  const { isFetched: isSettingNotationTypeFetched, isError: isSettingNotationTypeError } = useQuery({
    queryKey: ["settingNotationType"],
    queryFn: () => getCenterSettings(centerId, TYPE_NOTATION_CODE),
    refetchOnWindowFocus: false,
    meta: {
      onSuccess: (settingNotationType) => {
        setSimulatorNotationType(settingNotationType.value);
        dispatch(setNotationType(settingNotationType.value));
      },
      errorMessage: "center_parameters_error",
    },
  });

  const { isFetched: isSettingMeasuringSystemCodeFetched, isError: isSettingMeasuringSystemCodeError } = useQuery({
    queryKey: ["settingMeasuringSystemCode"],
    queryFn: () => getCenterSettings(centerId, MEASURING_SYSTEM_CODE),
    meta: {
      onSuccess: (settingMeasuringSystemCode) => setSimulatorMeasuringSystem(settingMeasuringSystemCode.value),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });

  const { isFetched: isSettingExaminesModulesFetched, isError: isSettingExaminesModulesError } = useQuery({
    queryKey: ["settingExaminesModules"],
    queryFn: () => getCenterSettings(centerId, EXAMINE_MODULES_EXTENDED),
    meta: {
      onSuccess: (settingExaminesModules) => setExamineModules(settingExaminesModules.value),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });

  const { isFetched: isSettingConfigCommandFetched, isError: isSettingConfigCommandError } = useQuery({
    queryKey: ["settingConfigCommand"],
    queryFn: () => getCenterSettings(centerId, EDIT_STUDENT_CONFIG_COMMAND),
    meta: {
      onSuccess: (settingConfigCommand) => setConfigCommand(settingConfigCommand.value),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });

  const { isFetched: isSettingTokenValidityFetched, isError: isSettingTokenValidityError } = useQuery({
    queryKey: ["settingTokenValidity"],
    queryFn: () => getCenterSettings(centerId, TOKEN_VALIDITY_TIME),
    meta: {
      onSuccess: (settingTokenValidity) => setTokenValidityTime(settingTokenValidity.value),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });

  const { isFetched: isSettingChronometerFetched, isError: isSettingChronometerError } = useQuery({
    queryKey: ["settingChronometer"],
    queryFn: () => getCenterSettings(centerId, STUDENT_CHRONO),
    meta: {
      onSuccess: (settingChronometer) => setChronometerValue(settingChronometer.value),
      errorMessage: "center_parameters_error",
    },
    refetchOnWindowFocus: false,
  });
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (
      isSettingShowNoteFetched &&
      isSettingNotationTypeFetched &&
      isSettingMeasuringSystemCodeFetched &&
      isSettingExaminesModulesFetched &&
      isSettingConfigCommandFetched &&
      isSettingTokenValidityFetched &&
      isSettingChronometerFetched
    ) {
      setLoading(false);
    }
  }, [
    isSettingShowNoteFetched,
    isSettingNotationTypeFetched,
    isSettingMeasuringSystemCodeFetched,
    isSettingExaminesModulesFetched,
    isSettingConfigCommandFetched,
    isSettingTokenValidityFetched,
    isSettingChronometerFetched,
  ]);
  //#endregion

  return (
    <div>
      <ScrollToTopController />
      {loading && <GstaLoaderPage />}
      <h2 className="center-parameters-title page-title">{title}</h2>
      <div className="center-parameters-content">
        <h1>{getTranslations("center_notations", translations)}</h1>
        <hr className="hr-without-margin-top" />
        <div className="notation-grid">
          <CenterParameters
            handleChangeShowSimulatorNotation={handleChangeShowSimulatorNotation}
            handleChangeSimulatorMeasuringSystem={handleChangeSimulatorMeasuringSystem}
            handleChangeSimulatorNotationType={handleChangeSimulatorNotationType}
            simulatorNotation={simulatorNotation}
            simulatorNotationType={simulatorNotationType}
            simulatorMeasuringSystem={simulatorMeasuringSystem}
            isSettingNotationTypeError={isSettingNotationTypeError}
            isSettingMeasuringSystemCodeError={isSettingMeasuringSystemCodeError}
            isSettingShowNoteError={isSettingShowNoteError}
          />
        </div>
      </div>
      <PdfSettingSection />
      <div className="center-parameters-content">
        <h1>{getTranslations("center_settings_modules_authorization", translations)}</h1>
        <hr className="hr-without-margin-top" />
        <div className="modules-authorization_content">
          <div>
            <GstaToolTip
              disabled={!isSettingExaminesModulesError}
              toolTipContent={getTranslations("center_parameters_error", translations)}
            >
              <GroupedButton
                label={getTranslations("free_mode", translations)}
                arrayToMap={MODIFY_EXAMINE_MODULES}
                onClick={handleChangeExamineModulesAuthorization}
                selected={examineModules}
                layoutClass={"radio-buttons"}
                disabled={isSettingExaminesModulesError}
              />
            </GstaToolTip>
          </div>
          <UndisplayFeature isLocal={checkInstalledMachines()}>
            <GstaToolTip
              disabled={!isSettingConfigCommandError}
              toolTipContent={getTranslations("center_parameters_error", translations)}
            >
              <GroupedButton
                label={getTranslations("student_command_config_modification", translations)}
                arrayToMap={MODIFY_COMMAND}
                onClick={handleChangeConfigCommand}
                selected={configCommand}
                layoutClass={"radio-buttons"}
                disabled={isSettingConfigCommandError}
              />
            </GstaToolTip>
          </UndisplayFeature>
          <GstaToolTip
            disabled={!isSettingTokenValidityError}
            toolTipContent={getTranslations("center_parameters_error", translations)}
          >
            <GroupedButton
              label={getTranslations("token_validity_time", translations)}
              arrayToMap={TOKEN_VALIDITY_TIME_VALUES}
              onClick={handleChangeTokenValidityTime}
              selected={tokenValidityTime}
              layoutClass={"radio-buttons"}
              disabled={isSettingTokenValidityError}
            />
          </GstaToolTip>
          <GstaToolTip
            disabled={!isSettingChronometerError}
            toolTipContent={getTranslations("center_parameters_error", translations)}
          >
            <GroupedButton
              label={getTranslations("active_chronometer_for_student", translations)}
              arrayToMap={CHRONOMETER_VALUES}
              onClick={handleChangeChronometerValue}
              selected={chronometerValue}
              layoutClass={"radio-buttons"}
              disabled={isSettingChronometerError}
            />
          </GstaToolTip>
        </div>
      </div>
    </div>
  );
};

CenterParametersLayout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CenterParametersLayout;
