import { getMachineTrainings } from "../../Services/TrainingService";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import { getTranslations } from "../../Helper/TranslationController";
import { TRANSLATIONS } from "../../Constants/Translations";

const machineTrainingMock = [
  {
    id: 1,
    trainingCode: "PAC001",
    trainingName: "training_PAC001_name",
    machineName: "PAC_name",
    description: "training_PAC001_description",
    exerciceCount: 67,
    machineId: 1,
    machineCode: "PAC",
    softwareCode: "BTP",
    profileName: "profile_PAC_name ",
    profileId: 1,
    duration: 19710,
    stepCount: 33,
    studentCount: 0,
    acreos: true,
    pictureFileName: "PAC001.jpg",
    isDeletable: false,
    lastTraininigDate: null,
  },
  {
    id: 2,
    trainingCode: "PAC002",
    trainingName: "training_PAC002_name",
    machineName: "PAC_name",
    description: "training_PAC002_description",
    exerciceCount: 67,
    machineId: 1,
    machineCode: "PAC",
    softwareCode: "BTP",
    profileName: "profile_PAC_name ",
    profileId: 1,
    duration: 19710,
    stepCount: 33,
    studentCount: 0,
    acreos: true,
    pictureFileName: "PAC002.jpg",
    isDeletable: false,
    lastTraininigDate: null,
  },
];

export const getMachineTrainingsLoop = async (centerId, machineId, dispatch, translations, setMachineTrainings) => {
  if (process.env.NODE_ENV === "test") {
    setMachineTrainings(machineTrainingMock);
  }
  try {
    const firstPageRequest = await getMachineTrainings(centerId, machineId, 0, 10);
    let machineTrainings = firstPageRequest.datas;
    setMachineTrainings(machineTrainings);
    for (let i = 1; i < Math.ceil(firstPageRequest.totalNumberOfElements / 10); i++) {
      machineTrainings = [...machineTrainings].concat((await getMachineTrainings(centerId, machineId, i, 10)).datas);
      setMachineTrainings(machineTrainings);
    }
  } catch (e) {
    dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.GET_TRAINING_ERROR, translations) }));
  }
};
