//#region react import
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
//#endregion

//#region component import
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import ScrollToTopController from "../../Helper/CustomHook/ScrollToTopController";
import CenterCard from "../../Pages/Center/CenterCard/CenterCard.component";
//#endregion

//#region store import
import { setActiveCenter } from "../../ReduxStore/connexionSlice";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { reset as resetSettings } from "../../ReduxStore/settingSlice";
import { reset as studentReset } from "../../ReduxStore/studentsSlice";
import { reset as trainingReset } from "../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { ELEMENT_PER_PAGE } from "../../Constants/ApiConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { LOADING, SELECT_ACTIVE_CENTER } from "../../Routing/PageNames";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region services import
import { getCenter } from "../../Services/CenterService";
import { setTrainerActiveCenterService } from "../../Services/TrainerService";
//#endregion

//#region style import
import "./ChangeActiveCenterLayout.style.scss";
//#endregion

const ChangeActiveCenterLayout = () => {
  //#region state
  const [centerToChange, setCenterToChange] = useState(null);
  const [centers, setCenters] = useState([]);
  //#endregion

  //#region other use
  const trainer = useSelector((state) => state.connexionSlice.trainer);
  const customerId = trainer?.customerId;
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region useQuery
  const {
    isSuccess,
    data: centersData,
    isError,
  } = useQuery({
    queryKey: ["centers"],
    queryFn: () => getCenter(customerId, 0, ELEMENT_PER_PAGE),
  });
  //#endregion

  //#region functions
  const fetchCenters = useCallback(
    (firstPaginationResponse) => {
      const centersFromApi = firstPaginationResponse.datas;
      for (
        let index = 1;
        index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
        index++
      ) {
        getCenter(customerId, index, ELEMENT_PER_PAGE).then((paginationResponse) =>
          centersFromApi.concat(paginationResponse.datas)
        );
      }
      return centersFromApi;
    },
    [customerId]
  );

  const setTrainerActiveCenter = async (newActiveCenter) => {
    try {
      await setTrainerActiveCenterService(customerId, trainer?.id, newActiveCenter.id);
      if (newActiveCenter.id !== trainer?.activeCenter.id) {
        dispatch(setActiveCenter({ newActiveCenter }));
        dispatch(trainingReset());
        dispatch(studentReset());
        dispatch(resetSettings());
        navigate(LOADING.url, { state: location.pathname });
      }
      dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_CENTER_UPDATED, translations)));
    } catch (e) {
      dispatch(
        setErrorNotification({
          notificationMessage: e.response.data.errorMessage,
          statusCode: e.response.status,
        })
      );
    }
  };

  const renderCards = () => {
    const cards = [];
    const trainerCenters = centers.filter((center) =>
      trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)
    );
    const disabledCenter = centers.filter(
      (center) => !trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)
    );
    trainerCenters.concat(disabledCenter).forEach((center) =>
      cards.push(
        <CenterCard
          center={center}
          key={center.id}
          current={trainer?.activeCenter.id === center.id}
          centerToChange={centerToChange === center.id}
          setCenterToChange={setCenterToChange}
          setTrainerActiveCenter={setTrainerActiveCenter}
          disabled={!trainer?.centers.some((trainerCenter) => trainerCenter.id === center.id)}
        />
      )
    );
    return cards;
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (isSuccess && centersData) {
      setCenters(fetchCenters(centersData));
    } else if (isError) {
      dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.ERROR_GET_CENTERS, translations) }));
    }
  }, [isSuccess, centersData, isError, dispatch, fetchCenters, translations]);
  //#endregion

  return (
    <PageWithTitle title={getTranslations(SELECT_ACTIVE_CENTER.pageTraductionKey, translations)}>
      <ScrollToTopController />
      <div className="center-card-container">{renderCards()}</div>
    </PageWithTitle>
  );
};

export default ChangeActiveCenterLayout;
