//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../GstaModal/GstaModal.component";
//#endregion

//#region constants import
import { ICON_CLOSE, ICON_EAST, ICON_NEW, ICON_WEST } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./PatchNotesModal.style.scss";
//#endregion

/**
 * Component created to be render on user connexion, and rendering the latest patch notes
 */

const PatchNotesModal = ({ patchNotes, modalOpen, setModalOpen, currentPage, maxNumberPage, handleValidate, goToPreviousModalPage }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const buildValidText = () => {
    let validText = getTranslations(TRANSLATIONS.NEXT_BUTTON, translations);
    if ((maxNumberPage > 1 && currentPage === maxNumberPage) || maxNumberPage === 1)
      validText = getTranslations(TRANSLATIONS.CLOSE, translations);
    return validText;
  };

  const buildCancelText = () => {
    let cancelText = "";
    if (maxNumberPage > 1 && currentPage > 1) cancelText = getTranslations(TRANSLATIONS.GO_BACK, translations);
    return cancelText;
  };

  const buildValidIcon = () => {
    let validIcon = ICON_EAST;
    if ((maxNumberPage > 1 && currentPage === maxNumberPage) || maxNumberPage === 1) validIcon = ICON_CLOSE;
    return validIcon;
  };
  //#endregion

  return (
    <GstaModal
      modalOpen={modalOpen}
      handleValidate={handleValidate}
      title={getTranslations(TRANSLATIONS.UPDATE_NOTES, translations)}
      iconTitle={ICON_NEW}
      validText={buildValidText()}
      cancelText={buildCancelText()}
      validIcon={buildValidIcon()}
      handleCloseModal={() => setModalOpen(false)}
      goToPreviousModalPage={goToPreviousModalPage}
      className={"patch-note-modal"}
      cancelIcon={ICON_WEST}
      currentPage={currentPage}
      maxNumberPage={maxNumberPage}
    >
      {patchNotes}
    </GstaModal>
  );
};

export default PatchNotesModal;
