//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController.js";
//#endregion

//#region components import
//#endregion

//#region services import
import { isPdfFileAvailable, downloadPdfFile } from "../../Services/PatchNotesService.js";
//#endregion

//#region store import
//#endregion

//#region constants import
import { ICON_PDF } from "../../Constants/IconConstants.js";
import { TRANSLATIONS } from "../../Constants/Translations.js";
import { DOCUMENTATION } from "../../Constants/Documentation.js";
//#endregion

//#region style import
import "./PatchNotesTitle.style.scss";
//#endregion

/**
 * component designed to be rendered in PatchNotesModal
 */

const PatchNotesTitle = ({ mainTitle, mainSummary, docTranslationKey }) => {
  //#region redux store
  const translations = useSelector((state) => state.translationSlice.translations);
  const language = useSelector((state) => state.translationSlice.language);
  //#endregion

  //#region state
  const [filename, setFilename] = useState(null);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const dispatch = useDispatch();
  //#endregion

  //#region functions
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  useEffect(() => {
    const checkFileExist = async () => {
      if (await isPdfFileAvailable(DOCUMENTATION.UPDATE_NOTE(docTranslationKey), language)) {
        setFilename(getTranslations(docTranslationKey, translations));
      }
    };

    checkFileExist();
  }, [language, translations, docTranslationKey]);
  //#endregion

  return (
    <div className="patch-notes-title">
      <h1>{mainTitle}</h1>
      <span>{mainSummary}</span>
      {filename && (
        <div className="patch-notes-title-file">
          <div
            className="patch-notes-title-link"
            onClick={() => downloadPdfFile(
              DOCUMENTATION.UPDATE_NOTE(docTranslationKey),
              language,
              filename,
              getTranslations(TRANSLATIONS.NOTIFICATION_NOTICE_DOWNLOADED, translations),
              dispatch
            )}
          >
            <i className={ICON_PDF} />
            <span>{filename}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default PatchNotesTitle;
