//#region react import
//#endregion

//#region functions import
//#endregion

//#region components import
import PatchNotesContent from "../PatchNotesContent/PatchNotesContent.component";
import PatchNotesTitle from "../PatchNotesTitle/PatchNotesTitle.component";
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
import { PATCH_NOTES_PROPERTY } from "./PatchNotes.constants";
//#endregion

//#region style import
import "./PatchNotes.style.scss";
//#endregion

/**
 * description
 */

const PatchNotes = ({ patchNotesContent }) => {
  //#region functions
  const renderContent = () => {
    return Object.keys(patchNotesContent).map((property) => {
      if (property === PATCH_NOTES_PROPERTY.title) {
        return (
          <PatchNotesTitle
            key={patchNotesContent?.title?.mainTitle}
            mainTitle={`${patchNotesContent?.title?.mainTitle} - ${patchNotesContent?.publicationDate}`}
            mainSummary={patchNotesContent?.title?.mainSummary}
            docTranslationKey={patchNotesContent?.title?.docTranslationKey}
          />
        );
      } else if (property === PATCH_NOTES_PROPERTY.date) {
        return <></>;
      } else {
        return (
          <PatchNotesContent
            key={property.toString()}
            contentType={property.toString()}
            listElements={patchNotesContent[property]}
          />
        );
      }
    });
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return <section className="patch-notes">{renderContent()}</section>;
};

export default PatchNotes;
