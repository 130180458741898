//#region react import
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTrainingLoop } from "../../../Constants/AddTrainingModalFunctions";
import { EnableOverflow } from "../../../Helper/Overflow";
import { getTranslations } from "../../../Helper/TranslationController";
//#endregion

//#region components import
import GstaModal from "../../../Components/GstaModal/GstaModal.component";
import GstaTable from "../../../Components/GstaTable/GstaTable.component";
import AddTrainingModalFooterOverride from "../AddTrainingModalFooterOverride/AddTrainingModalFooterOverride.component";
import TableTrainingActionSubscribe from "../TableTrainingActionSubscribe/TableTrainingActionSubscribe.component";
//#endregion

//#region service import
import { getArchivedTrainingsWithActiveSub } from "../../../Services/TrainingService";
//#endregion

//#region store import
import { setErrorNotification } from "../../../ReduxStore/notificationSlice";
import { setTrainings } from "../../../ReduxStore/trainingSlice";
//#endregion

//#region constants import
import { GET_TRAINING } from "../../../Constants/ApiConstants";
import { getTrainingHeaderDefinition } from "../../../Constants/TrainingModalHeaderDefinition";
import { copyObject } from "../../../Helper/CopyObject";
import { TRANSLATIONS } from "../../../Constants/Translations";
//#endregion

//#region styles import
import "./AddTrainingModal.style.scss";
//#endregion

/**
 * Modal created to subscribe student to training
 */

const AddTrainingModal = ({
  modalOpen,
  setModalOpen,
  validateAction,
  setValues,
  cancelAction,
  selectedTrainingToSubscribe,
  setSelectedTrainingToSubscribe,
  selectedTrainingToUnsubscribe,
  setSelectedTrainingToUnsubscribe,
  selectedTrainingToReSubscribe,
  setSelectedTrainingToReSubscribe,
  type,
  subscriptions,
  students,
  title,
}) => {
  //#region constants
  //#endregion
  //#region others use...
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const totalElementsCount = useSelector((state) => state.trainingSlice.totalElement);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  //#endregion

  //#region state
  const [trainingsState, setTrainingState] = useState(trainings);
  //#endregion

  //#region functions
  const addTrainingToArray = (trainingsArray, trainingIdToAdd, setTrainings) => {
    if (trainingsArray.includes(trainingIdToAdd)) {
      return;
    }
    const newArray = [...trainingsArray];
    newArray.push(trainingIdToAdd);
    setTrainings(newArray);
  };

  const removeTraining = (trainingsArray, trainingIdToRemove, setTrainings) => {
    const newArray = [...trainingsArray];
    const index = newArray.indexOf(trainingIdToRemove);
    if (index >= 0) {
      newArray.splice(index, 1);
      setTrainings(newArray);
    }
  };

  const handleAddTrainingToSubscribe = (trainingId) => {
    addTrainingToArray(selectedTrainingToSubscribe, trainingId, setSelectedTrainingToSubscribe);
  };

  const handleRemoveTrainingToSubscribe = (trainingId) => {
    removeTraining(selectedTrainingToSubscribe, trainingId, setSelectedTrainingToSubscribe);
  };

  const handleAddTrainingToUnsubscribe = (trainingId) => {
    addTrainingToArray(selectedTrainingToUnsubscribe, trainingId, setSelectedTrainingToUnsubscribe);
  };

  const handleRemoveTrainingToUnsubscribe = (trainingId) => {
    removeTraining(selectedTrainingToUnsubscribe, trainingId, setSelectedTrainingToUnsubscribe);
  };

  const handleAddTrainingToResubscribe = (trainingId) => {
    addTrainingToArray(selectedTrainingToReSubscribe, trainingId, setSelectedTrainingToReSubscribe);
  };

  const handleRemoveTrainingToResubscribe = (trainingId) => {
    removeTraining(selectedTrainingToReSubscribe, trainingId, setSelectedTrainingToReSubscribe);
  };

  const isInscribeSelected = (trainingId) => {
    return selectedTrainingToSubscribe.includes(trainingId);
  };

  const isResubscribeSelected = (trainingId) => {
    return selectedTrainingToReSubscribe.includes(trainingId);
  };

  const isUnSubscribeSelected = (trainingId) => {
    return selectedTrainingToUnsubscribe.includes(trainingId);
  };

  const handleCloseModal = () => {
    if (cancelAction) {
      cancelAction();
    }
    setModalOpen(false);
    EnableOverflow();
  };

  const manageMachineToAdd = (array, student) => {
    let copyMachineToAdd = student ? [...student.machineSubscribes] : [];
    array.forEach((item) => {
      const training = trainings.find((trainingFromDb) => trainingFromDb.id === item);
      const machineExist = copyMachineToAdd.find((machine) => machine.id === training.machineId);
      if (!machineExist) {
        copyMachineToAdd.push({
          id: training.machineId,
          code: training.machineCode,
          languageKey: training.machineName,
        });
      }
    });
    return copyMachineToAdd;
  };

  const manageMachineToRemove = (array) => {
    const subscriptionsIdGroupByMachineId = [];
    const machineToDelete = [];

    // get all machine id from the subscriptions to unsubscribe
    array.forEach((subscriptionIdToRemove) => {
      const subscription = subscriptions.find(
        (currentSubscription) => currentSubscription.subscriptionId === subscriptionIdToRemove
      );
      const traingIdByMachine = subscriptionsIdGroupByMachineId.find(
        (item) => item.machineId === subscription.subscriptedMachine.id
      );
      if (traingIdByMachine) {
        traingIdByMachine.subscriptionIds.push(subscriptionIdToRemove);
      } else {
        subscriptionsIdGroupByMachineId.push({
          machineId: subscription.subscriptedMachine.id,
          subscriptionIds: [subscriptionIdToRemove],
        });
      }
    });
    // if all training linked to the machine is in trainingsIdGroupByMachineId then add the machine to the machine to delete
    let trainingIdByMachine;
    subscriptionsIdGroupByMachineId.forEach((machine) => {
      trainingIdByMachine = subscriptions
        .filter((subscription) => subscription.subscriptedMachine.id === machine.machineId && subscription.isActive)
        .map((subscription) => subscription.subscriptionId);
      if (trainingIdByMachine.toString() === machine.subscriptionIds.toString()) {
        machineToDelete.push(machine.machineId);
      }
    });
    return machineToDelete;
  };

  const handleValidate = () => {
    if (students) {
      setValues(
        students.map((student) => {
          let machinesToAdd,
            machinesToDelete = [];
          machinesToAdd = manageMachineToAdd(selectedTrainingToSubscribe, student);
          if (selectedTrainingToUnsubscribe && selectedTrainingToUnsubscribe.length > 0) {
            machinesToDelete = manageMachineToRemove(selectedTrainingToUnsubscribe);
          }
          machinesToDelete.forEach((machineToDelete) => {
            const index = machinesToAdd.findIndex((machineToAdd) => machineToAdd.id === machineToDelete);
            if (index >= 0) {
              machinesToAdd.splice(index, 1);
            }
          });
          const studentModified = { ...student };
          studentModified.machineSubscribes = machinesToAdd;
          return studentModified;
        })
      );
    }
    if (validateAction) {
      validateAction();
    }
    setModalOpen(false);
  };

  const addArchivedTrainingWithActiveSubsToState = (archivedTrainings) => {
    let trainingStateCopied = copyObject(trainingsState);
    archivedTrainings.forEach((archivedTraining) => {
      if (!trainingStateCopied.some((training) => training.id === archivedTraining.id))
        trainingStateCopied.push(archivedTraining);
    });
    setTrainingState(trainingStateCopied);
  };
  //#endregion

  //#region useQuery
  useQuery({
    queryKey: ["getTrainingLoop"],
    queryFn: async () => {
      try {
        const allTrainings = await getTrainingLoop(centerId, GET_TRAINING.NOT_ARCHIVED, dispatch, setTrainingState);
        dispatch(setTrainings(allTrainings));
        return allTrainings;
      } catch (e) {
        dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.GET_TRAINING_ERROR, translations) }));
        throw e;
      }
    },
    enabled: trainings.length === 0,
  });

  useQuery({
    queryKey: ["archivedTrainingWithActiveSubs"],
    queryFn: async () => {
      try {
        const archivedTrainings = await getArchivedTrainingsWithActiveSub(centerId, students[0].id);
        addArchivedTrainingWithActiveSubsToState(archivedTrainings);
        return archivedTrainings;
      } catch (e) {
        dispatch(
          setErrorNotification({
            message: getTranslations(TRANSLATIONS.GET_ARCHIVED_TRAINING_WITH_ACTIVE_SUBS_ERROR, translations),
          })
        );
        throw e;
      }
    },
    enabled: students?.length === 1,
  });
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <GstaModal
      cancelText={getTranslations(TRANSLATIONS.COMMON_CANCEL, translations)}
      validText={getTranslations(TRANSLATIONS.COMMON_VALIDATE, translations)}
      handleCloseModal={handleCloseModal}
      handleValidate={handleValidate}
      modalOpen={modalOpen}
      title={title}
      hasTableInside
      FooterAddedComponent={AddTrainingModalFooterOverride}
      FooterAddedComponentProps={{
        ReSubscribeCount: selectedTrainingToReSubscribe?.length,
        UnsubscriptionCount: selectedTrainingToUnsubscribe?.length,
        SubscriptionCount: selectedTrainingToSubscribe?.length,
        Type: type,
      }}
      validateDisabled={
        selectedTrainingToSubscribe?.length === 0 &&
        selectedTrainingToUnsubscribe?.length === 0 &&
        selectedTrainingToReSubscribe?.length === 0
      }
      className={"training-modal"}
    >
      <GstaTable
        totalElementsCount={totalElementsCount}
        SearchInputPlaceHolder={getTranslations(TRANSLATIONS.ADD_TRAINING_MODAL_SEARCH_TRAINING_NAME, translations)}
        headerDefinitions={getTrainingHeaderDefinition(translations)}
        values={trainingsState}
        Actions={TableTrainingActionSubscribe}
        ActionsProps={{
          handleRemoveTrainingToResubscribe: handleRemoveTrainingToResubscribe,
          handleAddTrainingToResubscribe: handleAddTrainingToResubscribe,
          handleRemoveTrainingToUnsubscribe: handleRemoveTrainingToUnsubscribe,
          handleAddTrainingToUnsubscribe: handleAddTrainingToUnsubscribe,
          handleRemoveTrainingToSubscribe: handleRemoveTrainingToSubscribe,
          handleAddTrainingToSubscribe: handleAddTrainingToSubscribe,
          isInscribeSelected: isInscribeSelected,
          isUnSubscribeSelected: isUnSubscribeSelected,
          isResubscribeSelected: isResubscribeSelected,
          subscriptions: subscriptions,
          type: type,
        }}
        noResultPlaceHolder={getTranslations(TRANSLATIONS.COMMON_NO_TRAINING_FOUND, translations)}
        numberPerPageText={getTranslations(TRANSLATIONS.NUMBER_TRAINING_PER_PAGE, translations)}
        pagination
        globalSearch
      />
    </GstaModal>
  );
};
export default AddTrainingModal;
