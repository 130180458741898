//#region react import
import { Document, PDFViewer, Page, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import TrainingStepDetails from "../TrainingStepDetail";
//#endregion

//#region constants import
import { pdfMockTrainingStepDetails } from "../Mock/CustomPdfTrainingStepDetails";
//#endregion

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    fontFamily: "Roboto",
    paddingTop: 90,
    paddingBottom: 80,
    fontSize: 12,
  },
  content: {
    flexDirection: "column",
    gap: "30px",
    paddingHorizontal: 35,
  },
});

const CustomPdfTrainingStepResultViewer = () => {
  const translations = useSelector((state) => state.translationSlice.translations);
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <PDFViewer
        height={window.innerHeight}
        width={"100%"}
      >
        <Document>
          <Page
            size="A4"
            style={styles.page}
          >
            <TrainingStepDetails
              resultCriterionInfo={pdfMockTrainingStepDetails}
              translations={translations}
            />
          </Page>
        </Document>
      </PDFViewer>
    </QueryClientProvider>
  );
};
export default CustomPdfTrainingStepResultViewer;
