//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region style import
import "./PatchNotesContent.style.scss";
//#endregion

//#region constants import
import { contentTypes } from "./PatchNotesContent.constants";
import { ICON_CHECK, ICON_CHECK_CIRCLE, ICON_D1B_PICTO, ICON_NEW, ICON_UPGRADE } from "../../Constants/IconConstants";
//#endregion

/**
 * Component created to be the content of the PatchNotesModal
 */

const PatchNotesContent = ({ contentType, listElements }) => {
  //#region state
  //#endregion

  //#region constants
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region others use...
  //#endregion

  //#region functions
  const checkListIcon = () => {
    switch (contentType) {
      case contentTypes.NEW_FEATURE:
        return <span className="new-feature">New</span>;
      case contentTypes.CORRECTION:
      case contentTypes.COMPATIBILITY:
        return <i className={ICON_CHECK} />;
      default:
        return "default";
    }
  };

  const checkListStyle = () => {
    if (
      contentType === contentTypes.NEW_FEATURE ||
      contentType === contentTypes.CORRECTION ||
      contentType === contentTypes.COMPATIBILITY
    )
      return "none";
    else return "default";
  };

  const checkTitleIcon = () => {
    switch (contentType) {
      case contentTypes.COMPATIBILITY:
        return ICON_D1B_PICTO;
      case contentTypes.NEW_FEATURE:
        return ICON_NEW;
      case contentTypes.AMELIORATION:
        return ICON_UPGRADE;
      case contentTypes.CORRECTION:
        return ICON_CHECK_CIRCLE;
      default:
        return "";
    }
  };

  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <article className="patch-notes-content">
      <div>
        <i className={checkTitleIcon()} />
        <h2>{getTranslations(contentType, translations)}</h2>
      </div>
      <ul style={{ listStyle: checkListStyle() }}>
        {Array.isArray(listElements) &&
          listElements?.map((listElement, index) => {
            return (
              <div key={listElement.summary + index} className="list-item-container">
                <li style={{ display: "flex" }}>
                  <div className="list-icon-container">
                    {checkListIcon()}
                  </div>
                  <div className="content-container">
                    <span>{listElement.label}</span>
                    {listElement.summary && <span className="summary">{listElement.summary}</span>}
                  </div>
                </li>
              </div>
            );
          })}
      </ul>
    </article>
  );
};

export default PatchNotesContent;
