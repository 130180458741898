//#region react import
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import AcreosDatepickerInput from "../AcreosDatePickerInput/AcreosDatePickerInput.component";
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region functions import
import useClickOutside from "../../Helper/CustomHook/useClickOutside";
import { DateTimeToIsoString } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { INPUT_TEXT, INPUT_TYPE } from "../GstaInput/GstaInput.constants";
import { GstaInputWithValidationDefaultClass } from "./GstaInputWithValidation.constants";
//#endregion

//#region styles import
import "../GstaInput/GstaInput.style.scss";
//#endregion

const GstaInputWithValidation = ({
  label,
  value,
  setValue,
  placeholder,
  overrideClass,
  toUpper,
  mandatory,
  type,
  options,
  inputStyle,
}) => {
  //#region state
  const [valueModified, setValueModified] = useState(value);
  const [editMode, setEditMode] = useState(false);
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const ref = useRef(null);
  const refValidate = useRef(null);
  //#endregion

  //#region functions
  const handleChangeEditMode = () => {
    setValueModified(value);
    setEditMode(true);
  };

  const handleExitEditMode = () => {
    setValueModified(value);
    setEditMode(false);
  };

  const quitEditMode = () => {
    if (document.getElementById(label)) document.getElementById(label).blur();
    setEditMode(false);
  };

  const handleChangeValue = (newValueFromInput) => {
    const newValue = toUpper ? newValueFromInput.toUpperCase() : newValueFromInput;
    setValueModified(newValue);
  };

  const handleValidateClick = async () => {
    await setValue(valueModified);
    handleExitEditMode();
  };

  const renderInput = () => {
    switch (type) {
      case INPUT_TYPE.TEXT:
        return (
          <GstaInput
            id={label}
            value={valueModified}
            addClassCustom={mandatory && valueModified.length === 0 ? "gsta-input-error" : inputStyle}
            setValue={handleChangeValue}
            placeholder={placeholder}
            type={INPUT_TEXT}
            isValid
            autoFocus
          />
        );
      case INPUT_TYPE.SELECT:
        const valueModifiedLength = valueModified?.value ? valueModified?.value.length : valueModified?.length;
        return (
          <AcreosSelect
            isValid={!mandatory || valueModifiedLength > 0}
            options={options}
            clearable={!mandatory}
            value={valueModified?.value ?? valueModified}
            setNewValue={setValueModified}
            addClassCustom={inputStyle}
          />
        );
      case INPUT_TYPE.DATEPICKER:
        return (
          <AcreosDatepickerInput
            selectedDateTime={valueModified}
            setSelectedDateTime={setValueModified}
            isValid={!mandatory || valueModified?.toString().length > 0}
            addClassCustom={inputStyle}
          />
        );
      default:
        break;
    }
  };

  useClickOutside([ref, refValidate], editMode, quitEditMode);
  //#endregion

  return (
    <div>
      <div className={"gsta-input " + overrideClass}>
        <label htmlFor={label}>
          <b>{label}</b>
        </label>
        {editMode ? (
          <div ref={ref}>{renderInput()}</div>
        ) : (
          <div onClick={handleChangeEditMode}>
            <input
              type="input"
              readOnly
              id={label}
              value={type === INPUT_TYPE.DATEPICKER ? DateTimeToIsoString(value) : value?.label ?? value}
              className={inputStyle}
            />
            <i className="icon-pencil" />
          </div>
        )}
      </div>
      <div className={(editMode ? "" : "hidden ") + "gsta-input-with-validation-button-container"}>
        {editMode && (
          <>
            <button
              className={"gsta-button-outlined"}
              onClick={handleExitEditMode}
            >
              <span>{getTranslations("common_cancel", translations)}</span>
              <i className="icon-close" />
            </button>
            <button
              className={GstaInputWithValidationDefaultClass.GstaButtonFillMarginZero}
              onClick={handleValidateClick}
              disabled={mandatory && (valueModified?.length === 0 || !valueModified)}
              ref={refValidate}
            >
              <span>{getTranslations("common_validate", translations)}</span>
              <i className="icon-check" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default GstaInputWithValidation;
