import axios from "axios";
import FileSaver from "file-saver";
import { getApiServerProPath } from "../Helper/ReadWriteApiPath";
import { URI } from "../Constants/ApiConstants";
import { versionMock } from "./MockService/VersionServiceMock"
import { FILE_DOES_NOT_EXIST } from "../Constants/ErrorMessage";
import { FRANCAIS, ENGLISH } from "../Constants/LanguageConstants";
import { setSuccessNotification } from "../ReduxStore/notificationSlice";

export const getUpdatedVersions = async (centerId) => {
  if (process.env.NODE_ENV === "test") {
    return versionMock;
  }
  const { data } = await axios.get(`${getApiServerProPath()}/${URI.Entity.versions}/${URI.Entity.gsta}/${centerId}`);
  return data;
};

export const getJsonContent = async(filepath, language) => {
  let result = null;
  
  if(!language){
    if (await isFileExist(filepath, "json")) {
      result = await axios.get(`${filepath}.json`);
    }
    else {
      throw new Error(FILE_DOES_NOT_EXIST + `: ${filepath}.json`);
    }
  }
  else {
    let file = await chooseFile(filepath, language, "json");
    if (!file) {
      throw new Error(FILE_DOES_NOT_EXIST + `: ${filepath}_${language}.json`);
    }
    result = await axios.get(file);
  }

  return result.data;
}



/**
 * Check if the file exist
 * @param {string} filepath - The file path withouth extension
 * @param {string} extension - The file extension
 * @returns {boolean} - True if the file exist, false otherwise
 */
export const isFileExist = async (filepath, extension) => { 
  let isExist = false;
  try {
      const response = await fetch(`${filepath}.${extension}`, { method: 'HEAD' });

      if (response.ok) 
      {
        const contentType = response.headers.get("Content-Type");
        isExist = !!contentType?.includes(`application/${extension}`);
      }

  } catch (error) {
      console.error(error);
  }
  return isExist;
};

/**
 * Choose the file to download
 * @param {string} filepath - The file path without language and pdf extension
 * @returns 
 */
export const chooseFile =  async(filepath, language, extension) => {
  let filechoosen = null;

  const customFile = `${filepath}_${language}`;
  const englishFile = `${filepath}_${ENGLISH}`;
  const frenchFile = `${filepath}_${FRANCAIS}`;

  if (await isFileExist(customFile, extension)) {
    filechoosen = `${customFile}.${extension}`;
  }
  else if (await isFileExist(englishFile, extension)) {
    filechoosen = `${englishFile}.${extension}`;
  }
      
  else if (await isFileExist(frenchFile, extension)) {
    filechoosen = `${frenchFile}.${extension}`;
  }
  
  return filechoosen;
};

/**
 * Check if a PDF file is available for download
 * @param {string} filepath - The file path without language and pdf extension
 * @param {string} language - The language of the file
 * @returns {boolean} - True if a file is available, false otherwise
 */
export const isPdfFileAvailable = async (filepath, language) => {
  return await chooseFile(filepath, language, "pdf");
};

/**
 * Save the pdf file with a check if the file exist
 * @param {string} filepath - The file path withouth language and extension
 * @param {string} language - The language of the file
 * @param {string} saveAsFilename - The name of the file to save (not mandatory)
 * @returns {Promise<void>}
 * @throws {Error} - If the file does not exist
 */
export const savePdfFile = async(filepath, language, saveAsFilename) => {
  const fileToDownload = await chooseFile(filepath, language, "pdf");
  if (!fileToDownload) {
    throw new Error(FILE_DOES_NOT_EXIST + `: ${filepath}_${language}.pdf`);
  }
  if(!saveAsFilename) {
    saveAsFilename = fileToDownload.split("/")?.pop()?.split(".")[0];
  }
  
  fetch(fileToDownload).then((response) => {
    if (response.ok) {
      FileSaver.saveAs(fileToDownload, saveAsFilename + ".pdf");
    } else {
      throw new Error(FILE_DOES_NOT_EXIST);
    }
  });
};

/**
 * Save the pdf file with user feedback
 * @param {string} filepath - The file path withouth language and extension
 * @param {string} language - The language of the file
 * @param {string} saveAsFilename - The name of the file to save (not mandatory)
 * @param {string} feedbackMessage - The message to display to the user
 * @param {function} dispatch - The dispatch function to send a notification
 * @returns {Promise<void>}
 */
export const downloadPdfFile = async (filepath, language, saveAsFileName, feedbackMessage, dispatch) => {
  try {
    await savePdfFile(filepath, language, saveAsFileName);
    dispatch(setSuccessNotification(feedbackMessage));
  } catch (e) {
    console.error(e);
  }
}