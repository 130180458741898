export const groupBy = (array, key) => {
  return array?.reduce((groupByDictionary, value) => {
    (groupByDictionary[value[key]] = groupByDictionary[value[key]] || []).push(value);
    return groupByDictionary;
  }, {});
};

export const swipeTwooElement = (array, indexStart, indexEnd) => {
  [array[indexStart], array[indexEnd]] = [array[indexEnd], array[indexStart]];
  return array;
};

export const moveArrayElement = (array, indexToMove, indexToPlace) => {
  const newArray = [...array];
  const element = newArray.splice(indexToMove, 1)[0];
  newArray.splice(indexToPlace, 0, element);
  return newArray;
};