const DOCUMENTATION_PATH = `${process.env.PUBLIC_URL}/Documentation`;

export const DOCUMENTATION_JSON_FILE = `${process.env.PUBLIC_URL}/documentation-tree`;

export const DOCUMENTATION = {
  NOTICE_GSTA_DELTAOS: (codeNotice) => `${DOCUMENTATION_PATH}/generalDocumentation/notices/notice_${codeNotice}`,

  PATCH_NOTE_GSTA_DELTAOS_JSON: (version) => `${DOCUMENTATION_PATH}/PatchNotes/patchNote${version.replace(/\./g, "_")}`,

  UPDATE_NOTE: (updateNoteName) => `${DOCUMENTATION_PATH}/UpdateNotes/${updateNoteName}`,

  FIRST_STEP_GUIDE: () => `${DOCUMENTATION_PATH}/firstStepGuide`,

  GOOD_PRACTICE_RULES: () => `${DOCUMENTATION_PATH}/goodPracticeRules`,

  SIMULATOR_PRESENTATION: () => `${DOCUMENTATION_PATH}/presentation_simulator`,

  SIMULATOR_DESCRIPTION: (simuTypeCode) => `${DOCUMENTATION_PATH}/description_${simuTypeCode}`,

  SIMULATION_CONTENT: (softwareCode, machineCode) => `${DOCUMENTATION_PATH}/simulationDocumentation/${softwareCode}/${machineCode}/simulationContent_${machineCode}`,

  TRAINING_CATALOG: (softwareCode, machineCode) => `${DOCUMENTATION_PATH}/simulationDocumentation/${softwareCode}/${machineCode}/trainingCatalog_${machineCode}`,

  PATCH_NOTE_SIMULATION_JSON: (softwareCode, version) => `${DOCUMENTATION_PATH}/PatchNotes/patchNote_${softwareCode}_${version}`,

};
