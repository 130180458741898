//#region react import
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./ReduxStore/store";
import reportWebVitals from "./reportWebVitals";
//#endregion

//#region component import
import App from "./App";
//#endregion

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));

root.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <QueryClientProvider client={queryClient}>
      {/* <React.StrictMode> */}
        <App />
      {/* </React.StrictMode> */}
    </QueryClientProvider>
    </PersistGate>
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();