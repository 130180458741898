//#region react import
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
//#endregion

//#region component import
import PdfVisualizer from "../PdfVisualizer/PdfVisualizer.component";
import EditPdfFooter from "../EditPdfFooter/EditPdfFooter.component";
import ImageSelector from "../ImageSelector/ImageSelector.component";
//#endregion

//#region services import
import { GetPdfInfos, setCustomLogo, setCustomPdfFooter } from "../../Services/SettingsService";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { copyObject } from "../../Helper/CopyObject";
//#endregion

//#region constants import
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./PdfSettingSection.style.scss";
//#endregion

/**
 * The component that charge and allow to edit the pdf footer and the pdf logo
 */
const PdfSettingSection = () => {
  //#region useState
  const [pdfInfos, setPdfInfos] = useState();
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenterId = useSelector((state) => state.connexionSlice.trainer?.activeCenter.id);

  const dispatch = useDispatch();
  //#endregion
  //#region functions
  const handleChangePdfFooter = async (newPdfFooterValue) => {
    try {
      const newValue = (await setCustomPdfFooter(activeCenterId, newPdfFooterValue)).dataModified;
      const newPdfInfos = copyObject(pdfInfos);
      newPdfInfos.footer = newValue;
      setPdfInfos(newPdfInfos);
      dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_PARAMETER_UPDATED, translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const handleChangeImage = async (newImage) => {
    try {
      let splitImage,
        imageExtension,
        image = "";
      if (newImage) {
        splitImage = newImage.split(",");
        imageExtension = splitImage[0].replace(/data:/, "").replace(/;base64/, "");
        image = splitImage[1];
      }
      await setCustomLogo(activeCenterId, { imageExtension: imageExtension, image: image });
      const newPdfInfos = copyObject(pdfInfos);
      newPdfInfos.pdfCustomLogo.image = image;
      newPdfInfos.pdfCustomLogo.imageExtension = imageExtension;
      setPdfInfos(newPdfInfos);
      dispatch(setSuccessNotification(getTranslations(TRANSLATIONS.NOTIFICATION_PARAMETER_UPDATED, translations)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  //#endregion
  //#region useQuery
  const { isSuccess: isSuccessPdfInfos, data: pdfInfosData, isError: isErrorPdfInfos} = useQuery({
    queryKey: ["pdfInfos"],
    queryFn: () =>  GetPdfInfos(activeCenterId),
    refetchOnWindowFocus: false,
    enabled: !pdfInfos,
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    if(isSuccessPdfInfos && pdfInfosData){
      setPdfInfos(pdfInfosData);
    } else if (isErrorPdfInfos) {
      dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.ERROR_GET_PDF_TEMPLATE, translations) }));
    }
  }, [isSuccessPdfInfos, pdfInfosData, isErrorPdfInfos, dispatch, translations]);
  //#endregion
  return (
    <div className="center-parameters-content">
      <div>
        <h1>{getTranslations(TRANSLATIONS.EXPORT_PDF_PARAMETERS, translations)}</h1>
        <PdfVisualizer
          pdfFooter={pdfInfos?.footer}
          headerLogo={`data:${pdfInfos?.pdfCustomLogo.imageExtension};base64,${pdfInfos?.pdfCustomLogo.image}`}
        />
      </div>
      <hr className="hr-without-margin-top" />
      <div className="pdf-setting-container">
        <ImageSelector
          image={
            pdfInfos?.pdfCustomLogo.image
              ? `data:${pdfInfos?.pdfCustomLogo.imageExtension};base64,${pdfInfos?.pdfCustomLogo.image}`
              : null
          }
          setImage={handleChangeImage}
        />
        <EditPdfFooter
          pdfFooter={pdfInfos?.footer}
          setPdfFooter={handleChangePdfFooter}
        />
      </div>
    </div>
  );
};

export default PdfSettingSection;
