//#region react
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
//#endregion
//#region constants
import { TYPE_NOTATION_CODE } from "../../Constants/SettingsConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion
//#region services import
import { getCenterSettings } from "../../Services/SettingsService";
//#endregion
//#region store 
import { setNotationType } from "../../ReduxStore/settingSlice";
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
//#endregion
//#region hooks
import { useSelector } from "react-redux";
//#endregion
//#region functions
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

const useNotationType = (dispatch, centerId, notationType) => {

  const translations = useSelector((state) => state.translationSlice.translations);

  const { isSuccess, data: notationTypeData, isError, error } = useQuery({
    queryFn: () => getCenterSettings(centerId, TYPE_NOTATION_CODE),
    enabled: notationType === undefined || notationType === null,
  });

  useEffect(() => {
    if(isSuccess && notationTypeData && (notationType === undefined || notationType === null)){
      dispatch(setNotationType(notationTypeData.value));
    }
    else if(isError && error){
      dispatch(setErrorNotification({ message: getTranslations(TRANSLATIONS.ERROR_GET_NOTATION_TYPE, translations) }));
    }
  }, [isSuccess, notationTypeData, isError, error, dispatch, notationType, translations]);
};

export default useNotationType;
