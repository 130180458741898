//#region react import
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePapaParse } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import { customSleep } from "../../Helper/Sleep";
import { DateTimeToIsoString, customTryParseDate } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region store import
import { setErrorNotification, setSuccessNotification } from "../../ReduxStore/notificationSlice";
import { setImportStudentParsedCsv, setStudentsGettedDate } from "../../ReduxStore/studentsSlice";
//#endregion

//#region components
import ImportStudentsCell from "../ImportStudentsCell/ImportStudentsCell.component";
import ImportStudentsError from "../ImportStudentsError/ImportStudentsError.component";
import ImportStudentsModalFooterOverride from "../ImportStudentsModalFooterOverride/ImportStudentsModalFooterOverride.component";
import ImportStudentModalStepOne from "../ImportStudentsModalStepOne/ImportStudentsModalStepOne.component";
import ImportStudentModalStep3 from "../ImportStudentsModalStepThree/ImportStudentsModalStepThree.component";
import ImportStudentsModalStepTwo from "../ImportStudentsModalStepTwo/ImportStudentsModalStepTwo.component";
//#endregion

//#region services import
import { getStudentsWithDate, importStudents } from "../../Services/StudentService";
//#endregion

//#region constants import
import { ELEMENT_PER_PAGE } from "../../Constants/ApiConstants";
import { CUSTOM_FIELD_DATETIME, CUSTOM_FIELD_LIST, CUSTOM_FIELD_STRING } from "../../Constants/CustomFieldType";
import { FORMAT_DAY_HOURS } from "../../Constants/DateConstants";
import { API_ERROR } from "../../Constants/ErrorMessage";
import { FILTERED, ORDERED } from "../../Constants/HeaderType";
import { REGEX_PIN } from "../../Constants/Regex";
import { MANAGE_STUDENTS_ACCESS_WITH_DATES } from "../../Constants/SettingsConstants";
import {
  CODE_PIN_ID,
  DEFAULT_CODE_PIN,
  END_SESSION_ID,
  FIELDS_TO_IGNORE,
  FIELDS_TO_IGNORE_SESSION,
  FIRST_NAME_ID,
  NAME_ID,
  START_SESSION_ID,
  csvTypes,
} from "./ImportStudentsModal.constants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

/**
 * Custom modal to import students
 */

const ImportStudentsModal = ({ modalOpen, setModalOpen, addStudents }) => {
  //#region router
  //#endregion

  //#region constants
  const { readString } = usePapaParse();
  //#endregion

  //#region others use...
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translationSlice.translations);
  const customFieldsFromSlice = useSelector((state) => state.studentSlice.customFields);
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter.id);
  const sessionDates = useSelector((state) => state.settingSlice.settingDictionary[MANAGE_STUDENTS_ACCESS_WITH_DATES]);
  const parsedCsvReseted = useSelector((state) => state.studentSlice.importStudentParsedCsv);
  const input = useRef();
  const fileValue = useRef();
  const error = useRef();
  const [disableNext, setDisableNext] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [inputMessage, setInputMessage] = useState(() =>
    getTranslations(TRANSLATIONS.EXCEL_IMPORT_POPUP_NO_FILE_SELECTED, translations)
  );
  const [loading, setLoading] = useState(false);
  const [parsedCsv, setParsedCsv] = useState();
  const [parsedCsvLengthError, setParsedCsvLengthError] = useState(false);
  const [acreosSelectedValues, setAcreosSelectedValues] = useState({});
  const [filteredTable, setFilteredTable] = useState([]);
  const [headerDefinition, setHeaderDefinition] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [dataError, setDataError] = useState([]);
  const [isRowEdit, setIsRowEdit] = useState(false);

  /** Add mandatory custom field (name, first name and pin code) to customFields list and set customFields state with */
  const handleCustomFields = useCallback(() => {
    let customFieldsFromSliceCopied = [...(customFieldsFromSlice ?? [])];
    const customFieldsToBeAddedWithSession = [
      {
        id: END_SESSION_ID,
        name: getTranslations(TRANSLATIONS.END_SESSION_DATE, translations),
        isMandatory: false,
        listValues: [],
        fieldType: 2,
      },
      {
        id: START_SESSION_ID,
        name: getTranslations(TRANSLATIONS.START_SESSION_DATE, translations),
        isMandatory: false,
        listValues: [],
        fieldType: 2,
      },
      {
        id: CODE_PIN_ID,
        name: getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_PIN, translations),
        isMandatory: false,
        listValues: [],
        fieldType: 1,
      },
      {
        id: FIRST_NAME_ID,
        name: getTranslations(TRANSLATIONS.COMMON_FIRST_NAME, translations),
        isMandatory: true,
        listValues: [],
        fieldType: 1,
      },
      {
        id: NAME_ID,
        name: getTranslations(TRANSLATIONS.COMMON_NAME, translations),
        isMandatory: true,
        listValues: [],
        fieldType: 1,
      },
    ];

    const customFieldsToBeAdded = [
      {
        id: CODE_PIN_ID,
        name: getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_PIN, translations),
        isMandatory: false,
        listValues: [],
        fieldType: 1,
      },
      {
        id: FIRST_NAME_ID,
        name: getTranslations(TRANSLATIONS.COMMON_FIRST_NAME, translations),
        isMandatory: true,
        listValues: [],
        fieldType: 1,
      },
      {
        id: NAME_ID,
        name: getTranslations(TRANSLATIONS.COMMON_NAME, translations),
        isMandatory: true,
        listValues: [],
        fieldType: 1,
      },
    ];

    sessionDates === "true"
      ? customFieldsToBeAddedWithSession.forEach((customField) => customFieldsFromSliceCopied.unshift(customField))
      : customFieldsToBeAdded.forEach((customField) => customFieldsFromSliceCopied.unshift(customField));

    return customFieldsFromSliceCopied;
  }, [customFieldsFromSlice, sessionDates, translations]);

  const [customFields, setCustomFields] = useState(() => handleCustomFields());
  const getCustomFieldMandatoryNumber = () => {
    let customFieldsMandatoryNumber = 0;
    customFields?.forEach((customField) => customField.isMandatory && customFieldsMandatoryNumber++);
    return customFieldsMandatoryNumber;
  };

  const [customFieldMandatoryNumber, setCustomFieldMandatoryNumber] = useState(() => getCustomFieldMandatoryNumber());
  //#endregion
  //#region functions
  const handleCloseModal = () => {
    setParsedCsv();
    setCurrentStep(1);
    setErrorMessage();
    setInputMessage(getTranslations(TRANSLATIONS.EXCEL_IMPORT_POPUP_NO_FILE_SELECTED, translations));
    setModalOpen(false);
  };

  const handleValidateFirstStep = () => {
    setParsedCsvLengthError(false);
    setDisableNext(true);

    if (input.current.files.length > 0) {
      setLoading(true);
      try {
        readString(input.current.files[0], {
          worker: true,
          complete: async (results) => {
            const cleanedData = results.data.filter(row => 
              row.some(cell => cell && cell.trim() !== '')
            );
            if (cleanedData.length < 2) {
              setLoading(false);
              dispatch(setErrorNotification({
                message: getTranslations(TRANSLATIONS.EMPTY_CSV_ERROR, translations),
              }));
              return;
            }
            setParsedCsv(cleanedData);
            await customSleep(2000);
            setLoading(false);
            setCurrentStep(2);
          },
        });
      } catch (error) {
        setLoading(false);
        dispatch(setErrorNotification({
          message: getTranslations(TRANSLATIONS.CSV_IMPORT_ERROR, translations),
        }));
      }
    }
  };
  const setImportStudentValue = (value, indexValue, newValue, fieldType) => {
    value.values[indexValue - 1] =
      fieldType && fieldType === CUSTOM_FIELD_DATETIME.value ? DateTimeToIsoString(newValue) : newValue;
    const newTable = filteredTable.map((row) => {
      return row.id === value.id ? value : { ...row };
    });
    setFilteredTable(newTable);
  };

  const getHeaderDefinitionValue = (customFieldObject, value, index) => {
    if (customFieldObject.fieldType === CUSTOM_FIELD_DATETIME.value) {
      return customTryParseDate(value.values[index]?.toString() ?? "");
    }
    return customFieldObject.id === NAME_ID ? value.values[index].toUpperCase() : value.values[index];
  };

  const handleValidateStep2 = () => {
    let customFieldsSelected = [];
    const date = new Date();
    let parsedCsvToStore = parsedCsv.map((item) => [...item]);
    let parsedCsvFiltered = parsedCsv.map((item) => [...item]);
    let acreosSelectedValuesCopied = {};
    Object.values(acreosSelectedValues).forEach((value, index) => (acreosSelectedValuesCopied[index] = value));
    dispatch(setImportStudentParsedCsv(parsedCsvToStore));
    /** look for all the custom fields selected and push it into a new array */
    Object.values(acreosSelectedValues).forEach((value) =>
      customFields.find((customField) => customField.id === value && customFieldsSelected.push(customField.name))
    );

    if (
      Object.keys(acreosSelectedValues).length < parsedCsv[0].length ||
      Object.values(acreosSelectedValues).includes(null)
    ) {
      /** remove column data based on the custom field selected column */
      let keys = Object.keys(acreosSelectedValues).map((key) => key - 1);
      parsedCsvFiltered = parsedCsvFiltered.map((row) => row.filter((data, index) => keys.includes(index)));
    }

    // add an unique id to each row
    let newFilteredTable = parsedCsvFiltered.map((filteredValue, index) => ({ id: index, values: filteredValue }));

    // build header definitions
    let newHeaderDefinition = [];

    newHeaderDefinition.push({
      columnName: "",
      width: "50px",
      overrideColumn: ImportStudentsError,
      overrideProps: {
        data: [],
        dataError: dataError,
        setDataError: setDataError,
        setDisableNext: setDisableNext,
      },
    });

    /**Check if headerDefinition contains start AND end session Date */
    const indexOfStartSessionDate = customFieldsSelected.findIndex(
      (item) => item === getTranslations(TRANSLATIONS.START_SESSION_DATE, translations)
    );
    const indexOfEndSessionDate = customFieldsSelected.findIndex(
      (item) => item === getTranslations(TRANSLATIONS.END_SESSION_DATE, translations)
    );

    /** If no start session date, add it */
    if (indexOfStartSessionDate < 0 && indexOfEndSessionDate >= 0) {
      customFieldsSelected = [
        ...customFieldsSelected.slice(0, indexOfEndSessionDate),
        getTranslations(TRANSLATIONS.START_SESSION_DATE, translations),
        ...customFieldsSelected.slice(indexOfEndSessionDate, customFieldsSelected.length),
      ];
      newFilteredTable = newFilteredTable.map((item) => ({
        id: item.id,
        values: [
          ...item.values.slice(0, indexOfEndSessionDate),
          DateTimeToIsoString(date),
          ...item.values.slice(indexOfEndSessionDate, item.values.length),
        ],
      }));
      acreosSelectedValuesCopied[customFieldsSelected.indexOf(getTranslations(TRANSLATIONS.START_SESSION_DATE, translations))] =
        START_SESSION_ID;
      acreosSelectedValuesCopied[customFieldsSelected.indexOf(getTranslations(TRANSLATIONS.END_SESSION_DATE, translations))] =
        END_SESSION_ID;
    }

    /** If no end session date, add it */
    if (indexOfStartSessionDate >= 0 && indexOfEndSessionDate < 0) {
      customFieldsSelected = [
        ...customFieldsSelected.slice(0, indexOfStartSessionDate + 1),
        getTranslations(TRANSLATIONS.END_SESSION_DATE, translations),
        ...customFieldsSelected.slice(indexOfStartSessionDate + 1, customFieldsSelected.length),
      ];
      newFilteredTable = newFilteredTable.map((item) => ({
        id: item.id,
        values: [
          ...item.values.slice(0, indexOfStartSessionDate + 1),
          DateTimeToIsoString(date),
          ...item.values.slice(indexOfStartSessionDate + 1, item.values.length),
        ],
      }));
      acreosSelectedValuesCopied[customFieldsSelected.indexOf(getTranslations(TRANSLATIONS.END_SESSION_DATE, translations))] =
        END_SESSION_ID;
    }
    customFieldsSelected.forEach((customFieldSelected, index) => {
      const customFieldObject = customFields.find((customFields) => customFields.name === customFieldSelected);
      newHeaderDefinition[0]?.overrideProps.data.push({
        name: customFieldSelected,
        fieldType: customFieldObject?.fieldType,
        isMandatory: customFieldObject?.isMandatory,
        listValues: customFieldObject?.listValues,
      });
      newHeaderDefinition.push({
        columnName: customFieldObject.isMandatory ? `${customFieldSelected} *` : customFieldSelected,
        type: customFieldObject?.fieldType === CUSTOM_FIELD_LIST.value ? FILTERED : ORDERED,
        searchColumn: true,
        getValue:
          customFieldObject.id === NAME_ID
            ? (value) => value.values[index].toUpperCase()
            : (value) => value.values[index],
        overrideColumn: ImportStudentsCell,
        overrideProps: {
          setValue: (value, newValue) => setImportStudentValue(value, index, newValue),
          getValue: (value) => getHeaderDefinitionValue(customFieldObject, value, index),
          overrideColumn: ImportStudentsCell,
          type: customFieldObject?.fieldType,
          options: customFieldObject?.listValues.map((option) => ({ value: option.value, label: option.value })),
        },
      });
    });

    //Check for optionsValues in header : if those values are not equal to the value of a row, replace the value of the row by empty string
    newHeaderDefinition.forEach((columnHeader, index) => {
      if (columnHeader.type === FILTERED) {
        newFilteredTable.forEach((row) => {
          if (!columnHeader.overrideProps.options.find((value) => value.value === row.values[index - 1])) {
            row.values[index - 1] = "";
          }
        });
      }
    });

    // Check if the headerDefinition contains "code pin" column
    const headerDefinitionContainsPin = newHeaderDefinition.map((item) =>
      item.columnName.includes(getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_PIN, translations))
    );

    // If not, add column code pin
    if (!headerDefinitionContainsPin.includes(true)) {
      newFilteredTable.forEach((item) => item.values.push(DEFAULT_CODE_PIN));

      newHeaderDefinition[0].overrideProps.data.push({
        fieldType: 1,
        isMandatory: false,
        listValues: [],
      });
      newHeaderDefinition.push({
        columnName: getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_PIN, translations),
        getValue: (value) => value.values[value.values.length - 1],
        overrideColumn: ImportStudentsCell,
        searchColumn: true,
        overrideProps: {
          setValue: (value, newValue) => setImportStudentValue(value, value.values[value.values.length - 1], newValue),
          getValue: (value) => value.values[value.values.length - 1],
          type: CUSTOM_FIELD_STRING.value,
        },
      });
      acreosSelectedValuesCopied[customFieldsSelected.length] = CODE_PIN_ID;

      // else check the content of each code pin row
    } else {
      // if row value of code pin column does not match the regex, replace it
      const codePinIndex = headerDefinitionContainsPin.indexOf(true);
      newFilteredTable.forEach((row) => {
        if (!REGEX_PIN.test(row.values[codePinIndex - 1])) {
          row.values[codePinIndex - 1] = DEFAULT_CODE_PIN;
        }
      });
    }

    setFilteredTable(newFilteredTable);
    setCurrentStep(3);
    setAcreosSelectedValues(acreosSelectedValuesCopied);
    setHeaderDefinition(newHeaderDefinition);
  };

  const getStudentSinceDate = async (dispatch, date) => {
    try {
      const firstPaginationResponse = await getStudentsWithDate(
        0,
        ELEMENT_PER_PAGE,
        centerId,
        moment(date).format(FORMAT_DAY_HOURS).toString()
      );
      addStudents(firstPaginationResponse.datas);
      for (
        let index = 1;
        index < Math.ceil(firstPaginationResponse.totalNumberOfElements / ELEMENT_PER_PAGE);
        index++
      ) {
        addStudents(await getStudentsWithDate(index, ELEMENT_PER_PAGE, centerId, DateTimeToIsoString(date)).datas);
      }
      dispatch(setStudentsGettedDate(DateTimeToIsoString(date)));
    } catch (e) {
      dispatch(setErrorNotification(e));
    }
  };

  const checkDuplicateStudents = () => {
    const uniqueStudents = new Set(filteredTable?.map(item => item.values.join(";")));
    const duplicates = filteredTable.length - uniqueStudents.size;
    return duplicates > 0;
  };

  const handleValidateStep3 = async () => {
    // Filter on customFieldSelected
    let customFieldFiltered = customFields.filter((customField) =>
      Object.values(acreosSelectedValues).includes(customField.id)
    );
    const acreosSelectedValuesArray = Object.values(acreosSelectedValues);
    let customFieldFilteredOrdered = [];

    // Sort customFieldFiltered by acreosSelectedValue id order
    acreosSelectedValuesArray.forEach((selectedValue) => {
      customFieldFiltered.forEach((customField) => {
        if (customField.id === selectedValue) {
          customFieldFilteredOrdered.push(customField);
        }
        return false;
      });
    });

    // Check if customFieldFilteredOrdered contains code pin column, if not, add it
    if (!customFieldFilteredOrdered.some((item) => item.id === CODE_PIN_ID)) {
      customFieldFilteredOrdered.push({
        id: CODE_PIN_ID,
        name: "Code PIN",
        isMandatory: false,
        listValues: [],
        fieldType: 1,
      });
    }

    // Get customField array excluding name, first name and code pin
    let specificCustomFields = sessionDates
      ? customFieldFilteredOrdered.filter((field) => !FIELDS_TO_IGNORE_SESSION.includes(field.id))
      : customFieldFilteredOrdered.filter((field) => !FIELDS_TO_IGNORE.includes(field.id));
    let studentsToCreate = [];

    let sessionDateError = false;
    let filteredTableUpdated = [];
    filteredTable.forEach((row) => {
      let specificCustomFieldsValues = [];

      // Iterating over specific custom field array to create array of customValues for import route
      specificCustomFields.forEach((specificField) => {
        let specificCustomFieldValue = {
          id: specificField.id,
          name: specificField.name,
          value:
            specificField.fieldType === CUSTOM_FIELD_DATETIME.value
              ? DateTimeToIsoString(
                  customTryParseDate(
                    row.values[
                      customFieldFilteredOrdered.map((customField) => customField.id).indexOf(specificField.id)
                    ]
                  )
                )
              : row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(specificField.id)],
          fieldType: specificField.fieldType,
        };
        specificCustomFieldsValues.push(specificCustomFieldValue);
      });
      //check if customfields contain start and sessions dates
      const customFieldsContainsSessionDates = customFieldFilteredOrdered.some(
        (customField) => customField.id === START_SESSION_ID || customField.id === END_SESSION_ID
      );

      // check if start sessionDate is higher than end sessionDate
      if (
        sessionDates &&
        customFieldsContainsSessionDates &&
        customTryParseDate(
          row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(START_SESSION_ID)]
        ) >
          customTryParseDate(
            row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(END_SESSION_ID)]
          )
      ) {
        row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(START_SESSION_ID)] = "";
        row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(END_SESSION_ID)] = "";
        dispatch(
          setErrorNotification({
            message: getTranslations(TRANSLATIONS.SESSION_DATES_ERROR, translations),
          })
        );
        sessionDateError = true;
      }

      filteredTableUpdated.push(row);
      // creating array of student to create
      let studentToCreate = {
        firstName: row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(FIRST_NAME_ID)],
        lastName: row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(NAME_ID)],
        password: row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(CODE_PIN_ID)],

        trainingsToSubscribe: [0],
        customValues: specificCustomFieldsValues,
      };

      if (sessionDates && customFieldsContainsSessionDates) {
        studentToCreate.accessStart = DateTimeToIsoString(
          customTryParseDate(
            row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(START_SESSION_ID)]
          )
        );
        studentToCreate.accessEnd = DateTimeToIsoString(
          customTryParseDate(
            row.values[customFieldFilteredOrdered.map((customField) => customField.id).indexOf(END_SESSION_ID)]
          )
        );
      }
      studentsToCreate.push(studentToCreate);
    });

    if (sessionDateError) {
      setFilteredTable(filteredTableUpdated);
      return;
    }

    try {
      const date = new Date();
      if (checkDuplicateStudents()) { 
        dispatch(setErrorNotification({ 
          message: getTranslations(TRANSLATIONS.EXCEL_IMPORT_ERROR_DUPLICATE_ENTRY, translations) 
        })); 
        return; 
      }
      await importStudents(centerId, studentsToCreate);
      await getStudentSinceDate(dispatch, date);
      dispatch(
        setSuccessNotification(
          `${studentsToCreate.length} ${getTranslations(TRANSLATIONS.EXCEL_IMPORT_SUCCESS_MULTIPLE, translations)}`
        )
      );
      setModalOpen(false);
    } catch (e) {
      if (!e.response.data.translationKey) {
        dispatch(
          setErrorNotification({
            message: `${getTranslations(API_ERROR.ERROR_UNKNOWN, translations)}`,
          })
        );
      } else if (e.response.data.translationKey === API_ERROR.ERROR_API_USER_ALREADY_EXIST) {
        const [studentFirstName, studentLastName] = e.response.data.errorMessage.trim().split(/\s+/);
        dispatch(
          setErrorNotification({
            message: `${studentFirstName} ${studentLastName} ${getTranslations(
              API_ERROR.ERROR_API_USER_ALREADY_EXIST,
              translations
            )}`,
          })
        );
      } else if (e.response.data.translationKey === API_ERROR[e.response.data.translationKey]) {
        dispatch(
          setErrorNotification({
            message: `${getTranslations(API_ERROR[e.response.data.translationKey], translations)}`,
          })
        );
      }
    }
  };

  const handleChange = (e) => {
    setErrorMessage();
    // si une error ou aucun fichier affiche un message d'erreur et disable le bouton suivant
    if (!input.current || input.current.files.length < 1 || !csvTypes.includes(input.current.files[0].type)) {
      setErrorMessage(getTranslations(TRANSLATIONS.EXCEL_IMPORT_ERROR_FILE, translations));
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    // au changement prend le nom du fichier
    if (e.target.files.length > 0) {
      setInputMessage(e.target.files[0].name);
    } else {
      setInputMessage(getTranslations(TRANSLATIONS.EXCEL_IMPORT_POPUP_NO_FILE_SELECTED, translations));
    }
  };
  const handleClick = () => {
    input.current && input.current.click();
  };

  const goToPreviousStep = () => {
    if (currentStep === 3) {
      setCustomFields(() => handleCustomFields());
      setFilteredTable([]);
      setParsedCsv(parsedCsvReseted);
      setParsedCsvLengthError(false);
      setHeaderDefinition([]);
      setAcreosSelectedValues({});
      setCustomFieldMandatoryNumber(() => getCustomFieldMandatoryNumber());
      setIsRowEdit(false);
      setDisableNext(true);
      setCurrentStep(2);
    } else {
      setHeaderDefinition([]);
      setAcreosSelectedValues({});
      setParsedCsv();
      setInputMessage(() => getTranslations(TRANSLATIONS.EXCEL_IMPORT_POPUP_NO_FILE_SELECTED, translations));
      setDisableNext(true);
      setCurrentStep(1);
    }
  };
  const renderComponent = () => {
    switch (currentStep) {
      default:
      case 1:
        return (
          <ImportStudentModalStepOne
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleCloseModal={handleCloseModal}
            handleValidate={handleValidateFirstStep}
            disableNext={disableNext}
            loading={loading}
            loadingText={getTranslations(TRANSLATIONS.EXCEL_IMPORT_IN_PROGRESS, translations)}
            errorMessage={errorMessage}
            refError={error}
            refInput={input}
            inputMessage={inputMessage}
            handleChange={handleChange}
            handleClick={handleClick}
            fileValue={fileValue}
          />
        );
      case 2:
        return (
          <ImportStudentsModalStepTwo
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleCloseModal={handleCloseModal}
            handleValidate={handleValidateStep2}
            disableNext={disableNext}
            loading={loading}
            parsedCsvLengthError={parsedCsvLengthError}
            setCustomFieldMandatoryNumber={setCustomFieldMandatoryNumber}
            getCustomFieldMandatoryNumber={getCustomFieldMandatoryNumber}
            parsedCsv={parsedCsv}
            acreosSelectedValues={acreosSelectedValues}
            setAcreosSelectedValues={setAcreosSelectedValues}
            setDisableNext={setDisableNext}
            setParsedCsvLengthError={setParsedCsvLengthError}
            customFields={customFields}
            FooterAddedComponent={ImportStudentsModalFooterOverride}
            FooterAddedComponentProps={{
              contentToRender: currentStep,
              customFieldMandatoryNumber: customFieldMandatoryNumber,
              columnNumber: parsedCsv[0].length,
              acreosSelectedValues: acreosSelectedValues,
            }}
            goToPreviousStep={goToPreviousStep}
          />
        );
      case 3:
        return (
          <ImportStudentModalStep3
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleCloseModal={handleCloseModal}
            handleValidate={handleValidateStep3}
            disableNext={disableNext}
            setDisableNext={setDisableNext}
            loading={loading}
            filteredTable={filteredTable}
            headerDefinition={headerDefinition}
            setHeaderDefinition={setHeaderDefinition}
            setImportStudentValue={setImportStudentValue}
            setFilteredTable={setFilteredTable}
            FooterAddedComponent={ImportStudentsModalFooterOverride}
            FooterAddedComponentProps={{
              contentToRender: currentStep,
              dataError: dataError,
            }}
            goToPreviousStep={goToPreviousStep}
            isRowEdit={isRowEdit}
            setIsRowEdit={setIsRowEdit}
          />
        );
    }
  };

  //#endregion

  //#region useQuery
  //#endregion
  //#region useEffect
  useEffect(() => {
    handleCustomFields();
  }, [handleCustomFields]);
  //#endregion

  return renderComponent();
};
ImportStudentsModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
export default ImportStudentsModal;
