export const subscriptionsMock = {
  data: [
    {
      averagePercent: null,
      currentStepOrder: 0,
      customDescription: "débuter ",
      customName: "Parcours pour les nuls",
      languageKey: "jrn_007_journey_name",
      stepAverages: [
        {
          averagePercent: null,
          customDescription: null,
          customName: "Conduite en vue extérieure",
          languageKey: "stp_0071_step_name",
          stepState: 0,
          totalSecondElapsed: null,
          trainingStepId: 7255,
          trainingStepOrder: 1,
          trainingStepType: "OBLIGATOIRE",
        },
        {
          averagePercent: null,
          customDescription: null,
          customName: "Manoeuvres et stationnements",
          languageKey: "stp_0072_step_name",
          stepState: 0,
          totalSecondElapsed: null,
          trainingStepId: 7256,
          trainingStepOrder: 2,
          trainingStepType: "OBLIGATOIRE",
        },
      ],
      stepCount: 2,
      studentId: 718,
      subscriptedMachine: {
        id: 154,
        code: "CH2",
        version: "1.4.5",
      },
      subscriptionId: 5,
      totalSecondElapsed: null,
      trainingId: 671,
    },
  ],
};
