//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region component import
import PdfTemplateSummary from "../PdfTemplateSummary/PdfTemplateSummary.component";
//#endregion

//#region service import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region style import
import "./CreateCustomPdfStep4.style.scss";
//#endregion

//#region constants import
import { ICON_PERSON, ICON_TROPHY } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { SELECTED_PERIOD } from "../ExportPdfModal/ExportPdfModal.constants";

//#endregion

/**
 * A summary of all setting checked for the current pdf template
 */
const CreateCustomPdfStep4 = ({ pdfTemplate, setCurrentStep }) => {
  //#region useState
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const getSettings = () => {
    const dateSetting = { name: getTranslations(TRANSLATIONS.DATE_FOR_RESULTS, translations) };
    switch (pdfTemplate.showResultDates) {
      case SELECTED_PERIOD.PERSONALIZED_DATES:
        dateSetting.value = getTranslations(TRANSLATIONS.CUSTOM_DATES, translations);
        break;
      case SELECTED_PERIOD.SINCE_STUDENT_CREATION:
        dateSetting.value = getTranslations(TRANSLATIONS.SINCE_STUDENT_CREATION, translations);
        break;
      case SELECTED_PERIOD.SESSION_DATES:
        dateSetting.value = getTranslations(TRANSLATIONS.SESSION_DATES, translations);
        break;
      default:
        break;
    }
    return [
      dateSetting,
      {
        name: getTranslations(TRANSLATIONS.SHOW_CONNEXION_CALENDAR, translations),
        isActived: pdfTemplate.showCalendar,
      },
      {
        name: getTranslations(TRANSLATIONS.SHOW_GENERIC_SKILLS, translations),
        isActived: pdfTemplate.showGenericSkills,
      },
    ];
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  //#endregion
  return (
    <section className="create-custom-pdf-step4">
      <header>
        <h1>{`${getTranslations(TRANSLATIONS.STEP_PROGRESS_STEP, translations)} 4 - ${getTranslations(
          TRANSLATIONS.SUMMARY,
          translations
        )}`}</h1>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </header>
      <PdfTemplateSummary
        icon={ICON_PERSON}
        title={getTranslations(TRANSLATIONS.TRAINING_LAYOUT_DETAIL_GENERAL_INFORMATIONS, translations)}
        settings={[
          { name: getTranslations(TRANSLATIONS.MODEL_NAME, translations), value: pdfTemplate.name },
          {
            name: getTranslations(TRANSLATIONS.EXPORT_USED_BY_DEFAULT, translations),
            isActived: pdfTemplate.isDefault,
          },
        ]}
        action={() => setCurrentStep(1)}
      />
      <PdfTemplateSummary
        icon={ICON_PERSON}
        title={getTranslations(TRANSLATIONS.PDF_DRIVER_PROFILE_NO_TITLE, translations)}
        settings={getSettings()}
        action={() => setCurrentStep(2)}
      />
      <PdfTemplateSummary
        icon={ICON_TROPHY}
        title={getTranslations(TRANSLATIONS.COMMON_RESULTS, translations)}
        settings={[
          {
            name: getTranslations(TRANSLATIONS.SHOW_WORK_SKILLS, translations),
            isActived: pdfTemplate.showSkills,
          },
          {
            name: getTranslations(TRANSLATIONS.SHOW_STEP_DETAIL, translations),
            isActived: pdfTemplate.showTrainingSteps,
          },
          {
            name: getTranslations(TRANSLATIONS.SHOW_TIME, translations),
            isActived: pdfTemplate.showTime,
          },
          {
            name: getTranslations(TRANSLATIONS.SHOW_TRAINING_STEP_DETAIL, translations),
            isActived: pdfTemplate.showTrainingStepsDetail,
          },
        ]}
        action={() => setCurrentStep(3)}
      />
    </section>
  );
};
export default CreateCustomPdfStep4;
