import { SELECTED_PERIOD } from "../ExportPdfModal/ExportPdfModal.constants";

export const CREATE_TEMPLATE_STEP_COUNT = 4;
export const CUSTOM_PDF_TEMPLATE = {
  name: "",
  isDefault: false,
  showResultDates: SELECTED_PERIOD.SINCE_STUDENT_CREATION,
  showCalendar: false,
  showGenericSkills: false,
  showTrainingSteps: false,
  showSkills: false,
  showTrainingStepsDetail: false,
  showTime: true,
};

export const CUSTOM_PDF_TEMPLATE_TO_MODIFY = {
  name: "template to modify name",
  isDefault: true,
  showResultDates: SELECTED_PERIOD.SESSION_DATES,
  showCalendar: true,
  showGenericSkills: true,
  showTrainingSteps: true,
  showSkills: true,
  showTrainingStepsDetail: true,
  showTime: true,
};
