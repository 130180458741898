//#region react import
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region component import
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
//#endregion

//#region function import
import { getTranslations, reloadEnginesTranslations, reloadGSTATranslations } from "../../Helper/TranslationController";
import { createDocumentationObject } from "../../Helper/HandleDocumentation";
//#endregion

//#region store import
import { setMachines } from "../../ReduxStore/machineSlice";
import { setSetting } from "../../ReduxStore/settingSlice";
import { reset, setLanguage } from "../../ReduxStore/translationSlice";
//#endregion

//#region service import
import { getMachines } from "../../Services/MachineService";
import { getJsonContent, getUpdatedVersions } from "../../Services/PatchNotesService";
import { getCenterSettings, getUserSettings } from "../../Services/SettingsService";
//#endregion

//#region constants import
import { DOCUMENTATION, DOCUMENTATION_JSON_FILE } from "../../Constants/Documentation";
import {
  EXAMINE_MODULES_EXTENDED,
  LANGUAGE,
  MANAGE_STUDENTS_ACCESS_WITH_DATES,
  SHOW_STUDENTS_LAST_ACTIVITY,
  SHOW_STUDENTS_MACHINES,
} from "../../Constants/SettingsConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { CURRENT_ACTIVITY, LOGIN, SELECT_ACTIVE_CENTER } from "../../Routing/PageNames";
import { sortObjectsByVersion, sortVersions } from "./LoadingPage.constants";
//#endregion

const LoadingPage = () => {
  //#region hook
  const navigate = useNavigate();
  const connexionSlice = useSelector((state) => state.connexionSlice);
  const translations = useSelector((state) => state.translationSlice.translations);
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region useState
  const [dictionnariesLoaded, setDictionnariesLoaded] = useState(false);
  //#endregion

  //#region constants
  const centerId = connexionSlice.trainer.activeCenter?.id;
  const userId = connexionSlice.trainer?.id;
  //#endregion

  //#region useQuery
  const { data: languageData, isSuccess: isGetUserSettingsSuccessfull } = useQuery({
    queryKey: ["userSettings"],
    queryFn: () => getUserSettings(centerId, userId, LANGUAGE),
    enabled: !!centerId,
    meta: {
      onSuccess: async (data) => await onGetUserSettingsSuccess(data.value),
    },
  });

  const { data: updatedVersionsData, isSuccess: isGetUpdatedVersionsSuccessfull } = useQuery({
    queryKey: ["updatedVersions"],
    enabled: isGetUserSettingsSuccessfull,
    queryFn: () => getUpdatedVersions(centerId),
    meta: {
      errorMessage: TRANSLATIONS.ERROR_GET_UPDATED_VERSIONS,
    },
  });

  const { isSuccess: isShowStudentMachinesSuccessfull } = useQuery({
    queryKey: ["showStudentMachines"],
    queryFn: () => getCenterSettings(centerId, SHOW_STUDENTS_MACHINES),
    meta: {
      onSuccess: (data) => dispatch(setSetting({ settingName: SHOW_STUDENTS_MACHINES, settingValue: data.value })),
      errorMessage: `${getTranslations(TRANSLATIONS.ERROR_GET_CENTER_SETTING)} : ${SHOW_STUDENTS_MACHINES}`,
    },
  });

  const { isSuccess: isManageStudentsWithAccessDateSucessfull } = useQuery({
    queryKey: ["manageStudentsWithAccessDate"],
    queryFn: () => getCenterSettings(centerId, MANAGE_STUDENTS_ACCESS_WITH_DATES),
    meta: {
      onSuccess: (data) =>
        dispatch(
          setSetting({
            settingName: MANAGE_STUDENTS_ACCESS_WITH_DATES,
            settingValue: data.value,
          })
        ),
      errorMessage: `${getTranslations(TRANSLATIONS.ERROR_GET_CENTER_SETTING)} : ${MANAGE_STUDENTS_ACCESS_WITH_DATES}`,
    },
  });
  const { isSuccess: isShowStudentLastActivitySuccessfull } = useQuery({
    queryKey: ["showStudentLastActivity"],
    queryFn: () => getCenterSettings(centerId, SHOW_STUDENTS_LAST_ACTIVITY),
    meta: {
      onSuccess: (data) => dispatch(setSetting({ settingName: SHOW_STUDENTS_LAST_ACTIVITY, settingValue: data.value })),
      errorMessage: `${getTranslations(TRANSLATIONS.ERROR_GET_CENTER_SETTING)} : ${SHOW_STUDENTS_LAST_ACTIVITY}`,
    },
  });

  const { isSuccess: isSettingExaminesModulesSuccessfull } = useQuery({
    queryKey: ["settingExaminesModules"],
    queryFn: () => getCenterSettings(centerId, EXAMINE_MODULES_EXTENDED),
    meta: {
      onSuccess: (data) => dispatch(setSetting({ settingName: EXAMINE_MODULES_EXTENDED, settingValue: data.value })),
      errorMessage: `${getTranslations(
        TRANSLATIONS.ERROR_GET_CENTER_SETTING,
        translations
      )} : ${EXAMINE_MODULES_EXTENDED}`,
    },
  });

  const { data: machineData, isSuccess: isGetMachinesSuccessfull } = useQuery({
    queryKey: ["machines"],
    queryFn: () => getMachines(centerId),
    meta: {
      onSuccess: (data) => dispatch(setMachines(data)),
      errorMessage: getTranslations(TRANSLATIONS.GET_MACHINES_ERROR, translations),
    },
  });

  const { data: updateGstaVersionData, isSuccess: isFetchGstaPatchNotesSuccessfull } = useQuery({
    queryKey: ["updateGstaVersion"],
    queryFn: () => fetchGstaPatchNotes(sortVersions(updatedVersionsData?.updatedGstaVersions || []), languageData.value),
    enabled: isGetUpdatedVersionsSuccessfull && !!updatedVersionsData?.updatedGstaVersions,
  });

  const { data: updatedSoftwareData, isSuccess: isFetchSoftwarePatchNotesSuccessfull } = useQuery({
    queryKey: ["updatedSoftware"],
    queryFn: () => fetchSoftwarePatchNotes(sortObjectsByVersion(updatedVersionsData?.updatedSoftwares || []), languageData.value),
    enabled: isGetUpdatedVersionsSuccessfull && !!updatedVersionsData?.updatedSoftwares,
  });

  const { isSuccess: isFetchDocumentationTreeSuccessfull } = useQuery({
    queryKey: ["documentationTree"],
    queryFn: () => getJsonContent(DOCUMENTATION_JSON_FILE),
    meta: {
      onSuccess: async (data) => { createDocumentationObject(data, languageData.value, machineData, dispatch); },
    },
    enabled: isGetMachinesSuccessfull && isGetUserSettingsSuccessfull,
  });
  //#endregion

  //#region functions
  const onGetUserSettingsSuccess = async (language) => {
    try {
      dispatch(setLanguage(language));
      await reloadEnginesTranslations(dispatch, centerId, language);
      await reloadGSTATranslations(dispatch, language);
      setDictionnariesLoaded(true);
    } catch (e) {
      console.error(e);
    }
  };

  const buildUpdatedVersions = useCallback(() => {
    if (isFetchGstaPatchNotesSuccessfull && isFetchSoftwarePatchNotesSuccessfull) {
      return {
        updatedGstaVersions: updateGstaVersionData,
        updatedSoftwares: updatedSoftwareData,
        addedMachines: sortObjectsByVersion(updatedVersionsData?.addedMachines || []),
      };
    }
    return null;
  }, [
    updateGstaVersionData,
    isFetchSoftwarePatchNotesSuccessfull,
    updatedSoftwareData,
    updatedVersionsData,
    isFetchGstaPatchNotesSuccessfull,
  ]);

  const fetchGstaPatchNotes = async (updatedGstaVersions, language) => {
    const gstaVersionPatchNotes = [];
    for (const version of updatedGstaVersions) {
      try {
        const jsonPath = DOCUMENTATION.PATCH_NOTE_GSTA_DELTAOS_JSON(version);
        const json = await getJsonContent(jsonPath, language);
        gstaVersionPatchNotes.push({ version, json });
      } catch (e) {
        console.error(`Error fetching Gsta patch notes for version ${version}:`, e);
      }
    }
    return gstaVersionPatchNotes;
  };

  const fetchSoftwarePatchNotes = async (updatedSoftwares, language) => {
    const softwarePatchNotes = [];
    for (const software of updatedSoftwares) {
      try {
        const jsonPath = DOCUMENTATION.PATCH_NOTE_SIMULATION_JSON(software.softwareCode, software.version);
        const json = await getJsonContent(jsonPath, language);
        softwarePatchNotes.push({
          softwareCode: software.softwareCode,
          version: software.version,
          json,
        });
      } catch (e) {
        console.error(`Error fetching software patch notes for ${software.softwareCode}:`, e);
      }
    }
    return softwarePatchNotes;
  };
  //#endregion
  useEffect(() => {
    if (
      isGetUserSettingsSuccessfull &&
      isShowStudentMachinesSuccessfull &&
      isShowStudentLastActivitySuccessfull &&
      isManageStudentsWithAccessDateSucessfull &&
      isSettingExaminesModulesSuccessfull &&
      isGetUpdatedVersionsSuccessfull &&
      isGetMachinesSuccessfull &&
      dictionnariesLoaded &&
      buildUpdatedVersions &&
      isFetchDocumentationTreeSuccessfull &&
      getTranslations("120_m", translations) !== "120_m"
    ) {
      if (location.state === LOGIN.url) {
        navigate(CURRENT_ACTIVITY.url, { state: { updatedVersions: buildUpdatedVersions() } });
      } else {
        navigate(SELECT_ACTIVE_CENTER.url);
      }
    }
  }, [
    navigate,
    isGetUserSettingsSuccessfull,
    isGetUpdatedVersionsSuccessfull,
    isShowStudentMachinesSuccessfull,
    isManageStudentsWithAccessDateSucessfull,
    isShowStudentLastActivitySuccessfull,
    isSettingExaminesModulesSuccessfull,
    isGetMachinesSuccessfull,
    updatedVersionsData,
    translations,
    location.state,
    isFetchDocumentationTreeSuccessfull,
    dictionnariesLoaded,
    buildUpdatedVersions,
  ]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  //#endregion

  return (
    <div>
      <GstaLoaderPage />
    </div>
  );
};

export default LoadingPage;
