//#region react import
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region functions import
import { SetDataTestId } from "../../Helper/DataTestId";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import AcreosSelect from "../AcreosSelect/AcreosSelect.component";
import CreateValueFooter from "../CreateValueFooter/CreateValueFooter.component";
import GstaInput from "../GstaInput/GstaInput.component";
//#endregion

//#region services import
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import { getTrainingToDuplicate } from "../../Services/TrainingService";
//#endregion

//#region constants import
import { showErrorNotification } from "../../Constants/ErrorMessage";
import { CREATE_CUSTOM_FIELD_NAME_MAX_LENGTH } from "../../Constants/StringConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { TRAININGS, TRAININGS_CHOOSE_MODULE } from "../../Routing/PageNames";
import { INPUT_TEXT, INPUT_TEXT_AREA } from "../GstaInput/GstaInput.constants";
//#endregion

//#region style import
import "./CreateCustomtrainingMainInformation.scss";
//#endregion

/**
 * component to create custom training
 */

const CreateCustomTrainingMainInformation = () => {
  //#region state
  const [createTrainingFormData, setCreateTrainingFormData] = useState({
    machineId: null,
    name: "",
    description: "",
  });
  //#endregion

  //#region functions
  const onCancelButtonClick = () => {
    navigate(TRAININGS.url);
  };
  const onNextButtonClick = () => {
    const trainingNameAlreadyExist = trainings.filter(
      (training) =>
        training.trainingName === createTrainingFormData.name && training.machineId === createTrainingFormData.machineId
    );
    if (trainingNameAlreadyExist.length > 0) {
      dispatch(
        setErrorNotification({
          message: getTranslations("personnalized_training_error_name", translations),
        })
      );
    } else if (createTrainingFormData.name.length > CREATE_CUSTOM_FIELD_NAME_MAX_LENGTH) {
      dispatch(
        setErrorNotification({
          message: getTranslations("personnalized_training_error_caracters", translations),
        })
      );
    } else {
      navigate(TRAININGS_CHOOSE_MODULE.url, {
        state: {
          training: { ...createTrainingFormData },
          machine: machines.find((machine) => machine.id === createTrainingFormData.machineId),
        },
      });
    }
  };

  const disableNextButton = () => {
    return (
      (createTrainingFormData.machineId === null && createTrainingFormData.name === "") ||
      createTrainingFormData.name === "" ||
      createTrainingFormData.machineId === null
    );
  };

  const buildMachineOptions = () => {
    return machines?.map((machine) => ({
      value: machine.id,
      label: getTranslations(machine.languageKey, translations),
      icon: `icon-${machine.code}`,
    }));
  };

  const handleCreateTrainingFormData = (propertyToModify, value) => {
    let createTrainingFormDataCopied = { ...createTrainingFormData };
    createTrainingFormDataCopied[propertyToModify] = value;
    setCreateTrainingFormData(createTrainingFormDataCopied);
  };
  //#endregion

  //#region hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const centerId = useSelector((state) => state.connexionSlice.trainer?.activeCenter?.id);
  const translations = useSelector((state) => state.translationSlice.translations);
  const trainings = useSelector((state) => state.trainingSlice.trainings);
  const machines = useSelector((state) => state.machineSlice.machines);
  const location = useLocation();
  const trainingToDuplicateId = location.state?.trainingId;
  const [machineOptions] = useState(machines && buildMachineOptions());
  //#endregion

  //#region useQuery
  const {
    isSuccess: isSuccessTrainingToDuplicate,
    data: trainingToDuplicateData,
    isError: isErrorTrainingToDuplicate,
  } = useQuery({
    queryKey: ["trainingToDuplicate"],
    queryFn: () => getTrainingToDuplicate(centerId, trainingToDuplicateId),
    refetchOnWindowFocus: false,
    enabled: trainingToDuplicateId !== undefined,
  });
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (isSuccessTrainingToDuplicate && trainingToDuplicateData && trainingToDuplicateId !== undefined) {
      setCreateTrainingFormData({ ...trainingToDuplicateData, trainingName: trainingToDuplicateData.name });
    } else if (isErrorTrainingToDuplicate) {
      showErrorNotification(
        dispatch,
        translations,
        getTranslations(TRANSLATIONS.GET_TRAINING_TO_DUPLICATE_ERROR, translations)
      );
    }
  }, [
    isSuccessTrainingToDuplicate,
    trainingToDuplicateData,
    isErrorTrainingToDuplicate,
    dispatch,
    translations,
    trainingToDuplicateId,
  ]);
  //#endregion

  return (
    <section className="create-custom-training-main-information">
      <h2>{getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_GENERAL_INFORMATIONS, translations)}</h2>
      <div>
        <span>
          <b>{getTranslations(TRANSLATIONS.COMMON_TRAINING_NAME, translations)} *</b>
        </span>
        <span>{getTranslations(TRANSLATIONS.REQUIRED_FIELD, translations)}</span>
      </div>
      <GstaInput
        dataTestId={SetDataTestId("input-training-name")}
        type={INPUT_TEXT}
        isValid
        placeholder={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_NAME_EXAMPLE, translations)}
        appearanceClass={"training-name"}
        value={getTranslations(createTrainingFormData.name, translations)}
        setValue={(value) => handleCreateTrainingFormData("name", value)}
      />
      <GstaInput
        dataTestId={SetDataTestId("input-training-description")}
        type={INPUT_TEXT_AREA}
        isValid
        label={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DESCRIPTION, translations)}
        placeholder={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_DESCRIPTION_EXAMPLE, translations)}
        appearanceClass={"training-description"}
        value={getTranslations(createTrainingFormData.description, translations)}
        setValue={(value) => handleCreateTrainingFormData("description", value)}
      />
      <AcreosSelect
        dataTestId={SetDataTestId("select-machine")}
        title={`${getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_MACHINE, translations)} *`}
        options={machineOptions}
        addClassCustom={"machine"}
        isValid
        defaultText={getTranslations(TRANSLATIONS.PERSONNALIZED_TRAINING_CHOOSE_MACHINE, translations)}
        value={createTrainingFormData.machineId}
        setNewValue={(value) => handleCreateTrainingFormData("machineId", value)}
      />
      <CreateValueFooter
        onCancelButtonClick={onCancelButtonClick}
        onNextButtonClick={onNextButtonClick}
        validateRender={false}
        disabled={disableNextButton()}
      />
    </section>
  );
};
export default CreateCustomTrainingMainInformation;
