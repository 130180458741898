//#region store import
import { setDocumentation } from "../ReduxStore/translationSlice";
//#endregion

//#region functions import
import { copyObject } from "../Helper/CopyObject";
//#endregion

/**
 * Remove the language and file extension from the translation key.
 * @param {Object} item - The item containing the translation key.
 * @returns {Object} - The item with the modified translation key.
 */
const removeLanguageAndExtension = (item) => {
    item.translationKey = item.translationKey.replace(/_[a-z]{2}(-[a-z]{2})?\.[^.]+$/, '');
    return copyObject(item);
};


/**
 * Filter the items based on the provided language.
 * @param {Array} items - The array of items to filter.
 * @param {string} lang - The language code to filter by (e.g., 'fr', 'en').
 */
export const filterByLanguage = (items, lang) => {
    const selectedByKey = {};
    const langSuffix = `_${lang}.`;
    const enSuffix = '_en.';
    const frSuffix = '_fr.';

    items.forEach(item => {
        const baseKey = item.translationKey.replace(/_[a-z]{2}(-[a-z]{2})?\.[^.]+$/, '');

        const isLangMatch = item.translationKey.includes(langSuffix);
        const isEnMatch = item.translationKey.includes(enSuffix);
        const isFrMatch = item.translationKey.includes(frSuffix);

        if (isLangMatch || isEnMatch || isFrMatch) {
            const modifiedItem = removeLanguageAndExtension(item);

            if(isLangMatch || (isEnMatch && !selectedByKey[baseKey]) || (isFrMatch && !selectedByKey[baseKey])) {
                selectedByKey[baseKey] = modifiedItem;
            }
        }
    });

    return Object.values(selectedByKey);
};


/**
 * Recursively process the documentation tree to filter by language.
 * @param {Object} node - The current node in the documentation tree.
 * @param {string} language - The language code to filter by (e.g., 'fr', 'en').
 * @returns {Object} - The processed node with filtered translations.
 */
export const processFilterByLanguage = (node, language) => {
    if (Array.isArray(node)) {
        return filterByLanguage(node, language);
    }

    const result = {};
    for (const [key, value] of Object.entries(node)) {
        if (typeof value === 'object') {
            const filtered = processFilterByLanguage(value, language);

            if (Array.isArray(filtered) && filtered.length > 0) {
                result[key] = filtered;
            } else if (!Array.isArray(filtered) && Object.keys(filtered).length > 0) {
                result[key] = filtered;
            }
        }
    }

    return result;
};

/**
 * Create the documentation object based on the provided tree and language.
 * It filters the documentation tree to include only the relevant machines and translations.
 * @param {Object} documentationTree - The original documentation tree.
 * @param {string} language - The language code to filter translations (e.g., 'fr', 'en').
 * @param {Object} machinesInCenter - Object containing the machines present in the center.
 * @param {Function} dispatch - Redux dispatch function to update the documentation in the store.
 */
export const createDocumentationObject = async (documentationTree, language, machinesInCenter, dispatch) => {
    const machineCodes = machinesInCenter.map(machine => machine.code);

    const filteredTree = copyObject(documentationTree);

    // Filtrage spécifique à SimulationDocumentation (exclure machines non présentes)
    if (filteredTree.Documentation?.SimulationDocumentation) {
        Object.keys(filteredTree.Documentation.SimulationDocumentation).forEach(machineCode => {
            if (!machineCodes.includes(machineCode)) {
                delete filteredTree.Documentation.SimulationDocumentation[machineCode];
            }
        });
    }

    const finalDocumentationTree = processFilterByLanguage(filteredTree, language);
    dispatch(setDocumentation(finalDocumentationTree));
};
