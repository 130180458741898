//#region react import
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#region component import
import TrainingItem from "../../Pages/Training/TrainingItem/TrainingItem.component";
import AddTrainingStepModal from "../AddTrainingStepModal/AddTrainingStepModal.component";
import CustomTrainingRoadMapAction from "../CustomTrainingRoadMapAction/CustomTrainingRoadMapAction.component";
import GstaSimpleTable from "../GstaSimpleTable/GstaSimpleTable.component";
import ModuleNameItem from "../ModuleNameItem/ModuleNameItem.component";
import ReorderCell from "../ReorderCell/ReorderCell.component";
import SelectSerieExercise from "../SelectSerieExercise/SelectSerieExercise.component";
import SkillsCell from "../SkillsCell/SkillsCell.component";
import SwitchWithText from "../SwitchWithText/SwitchWithText.component";
//#endregion

//#region constants import
import { ICON_FLAG } from "../../Constants/IconConstants";
import { FORMAT_TIMESTRING, formatSecondToStringTime } from "../../Helper/TimeConverter";
//#endregion

//#region functions import
import { moveArrayElement } from "../../Helper/ArrayHelper";
import { copyObject } from "../../Helper/CopyObject";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region styles import
import "./CustomTrainingRoadMap.style.scss";
//#endregion

/**
 * Table to define the step of the choosen duplicated modules
 */

const CustomTrainingRoadMap = ({
  customTraining,
  setCustomTraining,
  notSelectedExercises,
  setNotSelected,
  machineModules,
  setMachinesModules,
}) => {
  //#region useState
  const [addStepToTrainingModalOpen, setAddStepToTrainingModalOpen] = useState(false);
  const [scrollbarPosition, setScrollbarPosition] = useState(0);
  //#endregion
  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion
  //#region functions
  const getTrainingDuration = () => {
    let duration = 0;
    customTraining?.steps?.forEach((step) => {
      step?.exercises?.forEach((exercise) => {
        duration += exercise.duration ?? 0;
      });
    });
    return duration;
  };

  const addStepToCustomTraining = (stepToAdd) => {
    const startIndex = customTraining.steps?.length ? customTraining.steps?.length : 0;
    const newCustomTrainingSteps =
      stepToAdd?.map((step, index) => {
        notSelectedExercises[startIndex + index + 1] = step.series.map((serie) =>
          serie.exercises.map((exercise) => ({
            serieOrder: serie.serieOrder,
            exerciseOrder: exercise.exerciseOrder,
            duration: exercise.duration,
          }))
        );
        notSelectedExercises[startIndex + index + 1] =
          notSelectedExercises[startIndex + index + 1].length > 0
            ? notSelectedExercises[startIndex + index + 1].reduce((pre, cur) => pre.concat(cur))
            : [];
        return {
          name: getTranslations(step.moduleNameKey, translations),
          description: getTranslations(step.moduleDescriptionKey, translations),
          moduleId: step.moduleId,
          order: startIndex + index + 1,
          picturePath: step.picturePath,
          skills: step.skills,
          mandatory: true,
          exercises: [],
        };
      }) ?? [];
    const newCustomTraining = { ...customTraining };

    newCustomTraining.steps = (newCustomTraining.steps ?? []).concat(newCustomTrainingSteps);
    setCustomTraining(newCustomTraining);
  };

  const getStepName = (value) => {
    return getTranslations(value.name, translations);
  };

  const getStepDuration = (value) => {
    let duration = 0;
    value.exercises?.forEach((exercise) => {
      duration += exercise.duration;
    });
    return duration;
  };

  const getMandatory = (value) => {
    return value?.mandatory;
  };

  const setMandatory = (value) => {
    let newCustomTraining = copyObject(customTraining);
    let newValue = copyObject(value);
    newValue.mandatory = !value.mandatory;
    newCustomTraining.steps[value.order - 1] = newValue;
    setCustomTraining(newCustomTraining);
  };

  const unselectedExercises = (stepOrder, serieOrder, exerciseOrder, duration, orderInStep) => {
    const newNotSelectedExercice = { ...notSelectedExercises };
    const newNotSelectedExerciceForCurrentStep = [...(notSelectedExercises[stepOrder] ?? [])];
    newNotSelectedExerciceForCurrentStep.push({
      orderInStep: orderInStep,
      serieOrder: serieOrder,
      exerciseOrder: exerciseOrder,
      duration: duration,
    });
    newNotSelectedExercice[stepOrder] = newNotSelectedExerciceForCurrentStep;
    setNotSelected(newNotSelectedExercice);
    const indexStep = customTraining.steps.findIndex((step) => step.order === stepOrder);
    const indexExerciceToRemove = customTraining.steps[indexStep].exercises.findIndex(
      (exercise) => exercise.serieOrder === serieOrder && exercise.exerciseOrder === exerciseOrder
    );
    customTraining.steps[indexStep].exercises.splice(indexExerciceToRemove, 1);
    setCustomTraining({ ...customTraining });
  };

  const selectExercise = (stepOrder, exercisesToAdd) => {
    const newNotSelectedExercice = { ...notSelectedExercises };
    const newNotSelectedExerciceForCurrentStep = [...(notSelectedExercises[stepOrder] ?? [])];
    exercisesToAdd.forEach((exerciseToAdd) => {
      const indexToRemove = newNotSelectedExerciceForCurrentStep.findIndex(
        (exercise) =>
          exercise.serieOrder === exerciseToAdd.serieOrder && exercise.exerciseOrder === exerciseToAdd.exerciseOrder
      );
      newNotSelectedExerciceForCurrentStep.splice(indexToRemove, 1);
      newNotSelectedExercice[stepOrder] = newNotSelectedExerciceForCurrentStep;
    });
    setNotSelected(newNotSelectedExercice);

    exercisesToAdd.forEach((exerciseToAdd) => {
      const indexStep = customTraining.steps.findIndex((step) => step.order === stepOrder);
      customTraining.steps[indexStep].exercises.push({
        orderInStep: exerciseToAdd.orderInStep,
        serieOrder: exerciseToAdd.serieOrder,
        exerciseOrder: exerciseToAdd.exerciseOrder,
        duration: exerciseToAdd.duration,
      });
    });
    setCustomTraining({ ...customTraining });
  };

  const setStep = (newStep) => {
    const newCustomTraining = { ...customTraining };
    newCustomTraining.steps[newStep.order - 1] = newStep;
    setCustomTraining(newCustomTraining);
  };

  const deleteStep = (stepToFind) => {
    const newCustomTraining = { ...customTraining };
    const indexToRemove = newCustomTraining.steps.findIndex((step) => step.order === stepToFind.order);
    newCustomTraining.steps.splice(indexToRemove, 1);
    newCustomTraining.steps.forEach((step, index) => {
      if (index >= indexToRemove) {
        step.order = step.order - 1;
      }
    });
    setCustomTraining(newCustomTraining);

    // delete also the not selected exercises linked to the step to delete
    const newNotSelectedExercises = copyObject(notSelectedExercises);
    delete newNotSelectedExercises[stepToFind.order];
    const test = {};
    Object.values(newNotSelectedExercises).forEach((value, index) => {
      test[index + 1] = value;
    });

    setNotSelected(test);
  };

  const changeStepOrder = async (indexStart, indexEnd) => {
    await setTimeout(() => {
      setCustomTraining({});
      const newCustomTraining = { ...customTraining };
      newCustomTraining.steps = moveArrayElement(customTraining?.steps, indexStart, indexEnd);
      newCustomTraining.steps.forEach((step, index) => {
        step.order = index + 1;
      });
      setCustomTraining(newCustomTraining);
      const newNotSelectedExercice = { ...notSelectedExercises };
      newNotSelectedExercice[indexStart + 1] = [...notSelectedExercises[indexEnd + 1]];
      newNotSelectedExercice[indexEnd + 1] = [...notSelectedExercises[indexStart + 1]];
      setNotSelected(newNotSelectedExercice);
      setScrollbarPosition(window.scrollY);
    }, 5);
  };
  //#endregion
  //#region useQuery
  //#endregion
  //#region useEffect
  useEffect(() => {
    window.scrollTo(0, scrollbarPosition);
  }, [scrollbarPosition]);
  //#endregion
  const headerDefinition = [
    {
      columnName: getTranslations("personnalized_training_table_order", translations),
      overrideColumn: ReorderCell,
      getValue: (value) => value.order,
      overrideProps: {
        getIndex: (value) => customTraining.steps.findIndex((step) => step.order === value.order),
        changeOrder: changeStepOrder,
        valuesLength: customTraining.steps?.length ?? 0,
      },
    },
    {
      columnName: getTranslations("personnalized_training_table_name_step", translations),
      getValue: getStepName,
      overrideColumn: ModuleNameItem,
      overrideProps: {
        getValue: getStepName,
        getImage: (value) => process.env.PUBLIC_URL + value.picturePath,
      },
    },
    {
      columnName: getTranslations("personnalized_training_table_mandatory", translations),
      overrideColumn: SwitchWithText,
      getValue: (value) => value.mandatory,
      overrideProps: {
        onText: getTranslations("common_yes", translations),
        offText: getTranslations("common_no", translations),
        getValue: (value) => getMandatory(value),
        handleChange: (value) => setMandatory(value),
      },
    },
    {
      columnName: getTranslations("personnalized_training_table_duration", translations),
      getValue: getStepDuration,
      overrideColumn: TrainingItem,
      overrideProps: {
        image: "timer",
        getValue: (value) => formatSecondToStringTime(getStepDuration(value), translations, FORMAT_TIMESTRING.HHMM),
      },
    },
    {
      columnName: getTranslations("personnalized_training_table_exercices_number", translations),
      getValue: (value) => value.exercises.length,
      overrideColumn: TrainingItem,
      expandedColumn: true,
      overrideProps: {
        image: "traficlight",
        getValue: (value) => (
          <span className={value.exercises?.length === 0 ? "color-invalid" : ""}>
            {`${value.exercises?.length} ${getTranslations("personnalized_training_exercices_selected", translations)}`}
          </span>
        ),
        isStyleOverridable: true,
      },
    },
    {
      columnName: getTranslations("personnalized_training_table_skills_worked", translations),
      getValue: (value) => <SkillsCell value={value} />,
      searchColumn: false,
    },
  ];

  return (
    <main className="custom-training-roadmap">
      <AddTrainingStepModal
        machineModules={machineModules}
        setMachinesModules={setMachinesModules}
        customTraining={customTraining}
        modalOpen={addStepToTrainingModalOpen}
        setModalOpen={setAddStepToTrainingModalOpen}
        addStepToCustomTraining={addStepToCustomTraining}
      />
      <section>
        <div>
          <h1>{getTranslations("personnalized_training_steps", translations)}</h1>
          <div>
            <span>
              <i className={ICON_FLAG} />
              {`${customTraining?.steps?.length ?? 0} ${getTranslations("training_layout_detail_step", translations)}`}
            </span>
            <span>
              <i className="icon-timer" />
              {`${formatSecondToStringTime(getTrainingDuration(), translations, FORMAT_TIMESTRING.HHMM)}`}
            </span>
          </div>
        </div>
        <button
          className="gsta-button-outlined"
          onClick={() => setAddStepToTrainingModalOpen(true)}
        >
          <i className="icon-add_circle" />
          {getTranslations("personnalized_training_button_add_steps", translations)}
        </button>
      </section>
      <GstaSimpleTable
        values={customTraining.steps ?? []}
        headerDefinitions={headerDefinition}
        noResultPlaceHolder={getTranslations("custom_training_no_step", translations)}
        ExpandedComponent={SelectSerieExercise}
        ExpandedComponentProps={{
          notSelectedExercises: notSelectedExercises,
          unselectedExercises: unselectedExercises,
          setStep: setStep,
          selectExercise: selectExercise,
        }}
        Actions={CustomTrainingRoadMapAction}
        ActionsProps={{ deleteStep: deleteStep }}
        onDragRelease={async (value, newIndex) => {
          const index = customTraining.steps.findIndex((step) => step.order === value.order);
          await changeStepOrder(index, newIndex);
        }}
        draggable
      />
    </main>
  );
};
CustomTrainingRoadMap.propTypes = {
  customTraining: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string,
    isAcreos: PropTypes.bool,
    machineId: PropTypes.number,
    name: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        exercises: PropTypes.arrayOf(
          PropTypes.shape({
            duration: PropTypes.number,
            exerciseOrder: PropTypes.number,
            orderInStep: PropTypes.number,
            serieOrder: PropTypes.number,
          })
        ),
        mandatory: PropTypes.bool,
        moduleId: PropTypes.number,
        name: PropTypes.string,
        order: PropTypes.number,
        picturePath: PropTypes.string,
        skills: PropTypes.array,
      })
    ),
  }).isRequired,
  setCustomTraining: PropTypes.func.isRequired,
};
export default CustomTrainingRoadMap;
