//#region react import
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//#endregion

//#region component import
import GstaLoaderPage from "../../Components/GstaLoaderPage/GstaLoaderPage.component";
import GstaTable from "../../Components/GstaTable/GstaTable.component";
import GlobalLayout from "../../Layout/GlobalLayout/GlobalLayout.component";
import TrainingStepItem from "../../Pages/Training/TrainingItem/TrainingItem.component";
import TrainingAction from "../Training/TrainingAction/TrainingAction.component";
import TrainingMachineItem from "../Training/TrainingMachineItem/TrainingMachineItem.component";
import TrainingNameItem from "../Training/TrainingNameItem/TrainingNameItem.component";
//#endregion

//#region service import
//#endregion

//#region store import
import { setSelectedTrainingId } from "../../ReduxStore/trainingSlice";
//#endregion

//#region functions import
import { getTrainingLoop } from "../../Constants/AddTrainingModalFunctions";
import { formatSecondToStringTime } from "../../Helper/TimeConverter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { GSTA_TABLE_IDS } from "../../Components/GstaTable/GstaTable.constants";
import { GET_TRAINING } from "../../Constants/ApiConstants";
import { FILTERED, ORDERED } from "../../Constants/HeaderType";
import KeyTrad_NumberPerPage from "../../Constants/PaginationConstants";
import { FORMAT_TIMESTRING } from "../../Helper/TimeConverter";
import { TRAININGS_ARCHIVED, TRAININGS_DETAILS } from "../../Routing/PageNames";
//#endregion

//#region style import
import { setErrorNotification } from "../../ReduxStore/notificationSlice";
import "./ArchivedTrainingPage.style.scss";
//#endregion

/**
 * Page rendering all the archived training
 */

const ArchivedTrainingPage = () => {
  //#region State
  const [archivedTraining, setArchivedTraining] = useState();
  const [loading, setLoading] = useState(false);
  //#endregion

  //#region useSelector
  const translations = useSelector((state) => state.translationSlice.translations);
  const activeCenter = useSelector((state) => state.connexionSlice.trainer?.activeCenter);
  //#endregion

  //#region other use
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#rendregion

  //#region functions
  const getTrainingName = (training) => {
    return getTranslations(training.trainingName, translations);
  };

  const getMachineName = (training) => {
    return getTranslations(training.machineName, translations);
  };

  const getTrainingDuration = (training) => {
    return training.duration;
  };

  const getTrainingStep = (training) => {
    return training.stepCount;
  };

  const redirectToDetail = (value) => {
    dispatch(setSelectedTrainingId(value.id));
    navigate(TRAININGS_DETAILS.url, { state: { training: value } });
  };

  //#endregion

  //#region constants
  const trainingStepHeaderDefinition = [
    {
      columnName: getTranslations("common_training_name", translations),
      type: ORDERED,
      getValue: getTrainingName,
      expandedColumn: false,
      searchColumn: true,
      overrideColumn: TrainingNameItem,
      overrideProps: {
        getValue: getTrainingName,
        showImg: false,
      },
    },
    {
      columnName: getTranslations("common_machine", translations),
      type: FILTERED,
      getValue: getMachineName,
      expandedColumn: false,
      searchColumn: true,
      overrideColumn: TrainingMachineItem,
    },
    {
      columnName: getTranslations("common_duration", translations),
      type: ORDERED,
      getValue: getTrainingDuration,
      expandedColumn: false,
      overrideColumn: TrainingStepItem,
      overrideProps: {
        image: "timer",
        getValue: (value) => formatSecondToStringTime(getTrainingDuration(value), translations, FORMAT_TIMESTRING.HHMM),
      },
    },
    {
      columnName: getTranslations("common_steps", translations),
      type: ORDERED,
      getValue: getTrainingStep,
      expandedColumn: false,
      overrideColumn: TrainingStepItem,
      overrideProps: {
        image: "training",
        getValue: getTrainingStep,
      },
    },
  ];
  //#endregion

  // #region useQuery
  const { refetch: refetchArchivedTrainings } = useQuery({
    queryKey: ["ArchivedTraining"],
    queryFn: async () => {
      try {
        setLoading(true);
        const tranings = await getTrainingLoop(activeCenter.id, GET_TRAINING.ARCHIVED, dispatch, setArchivedTraining);
        setLoading(false);
        return tranings;
      } catch (e) {
        dispatch(
          setErrorNotification({ message: getTranslations("cannot_get_archived_training_error", translations) })
        );
        throw e; 
      }
    },
    refetchOnWindowFocus: false,
  });
  //#endregion

  //#region useEffect

  //#endregion

  return (
    <GlobalLayout
      icon="list"
      title={TRAININGS_ARCHIVED.pageTraductionKey}
    >
      {loading && <GstaLoaderPage />}
      <main className="archived-training-page">
        <h3 className="back-button">
          <i
            onClick={() => navigate(-1)}
            className="icon-arrow_circle_left"
          />
          <span onClick={() => navigate(-1)}>
            {getTranslations("training_layout_detail_back_to_catalog", translations)}
          </span>
        </h3>
        <GstaTable
          values={archivedTraining}
          headerDefinitions={trainingStepHeaderDefinition}
          totalElementsCount={archivedTraining?.length}
          Actions={TrainingAction}
          ActionsProps={{
            isArchivedTrainingPage: true,
            refetchArchivedTrainings: refetchArchivedTrainings,
            setLoading: setLoading,
          }}
          SearchInputPlaceHolder={getTranslations("student_find_student_placeholder", translations)}
          onRowClick={redirectToDetail}
          noResultPlaceHolder={getTranslations("common_no_training_found", translations)}
          numberPerPageText={KeyTrad_NumberPerPage.Training}
          pagination
          globalSearch
          tableId={GSTA_TABLE_IDS.ARCHVED_TRAINING_TABLE}
        />
      </main>
    </GlobalLayout>
  );
};

export default ArchivedTrainingPage;
