export const sortVersions = (versions) => {
  const compareSemanticVersions = (versionA, versionB) => {
    const versionAParts = versionA.split('.').map(Number);
    const versionBParts = versionB.split('.').map(Number);

    for (let i = 0; i < Math.max(versionAParts.length, versionBParts.length); i++) {
      const partA = versionAParts[i] || 0;
      const partB = versionBParts[i] || 0;

      if (partA > partB) return 1;
      if (partA < partB) return -1;
    }

    return 0;
  };

  return [...versions].sort(compareSemanticVersions);
};


export const sortObjectsByVersion = (objects) => {
  let sortedObjects = [];
  if (objects && objects.length !== 0){
    const versions = objects.map(obj => obj["version"]);
    const sortedVersions = sortVersions(versions);
  
    sortedObjects = sortedVersions.map(version =>
      objects.find(obj => obj["version"] === version)
    );
  }
  
  return sortedObjects;
};
