//#region react import
import React from "react";
import { useDispatch, useSelector } from "react-redux";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region services import
import { downloadPdfFile } from "../../Services/PatchNotesService";
//#endregion

//#region components import
import PatchNotesTitle from "../PatchNotesTitle/PatchNotesTitle.component";
//#endregion

//#region constants import
import { ICON_NEW, ICON_PDF, ICON_CHECK } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
import { DOCUMENTATION } from "../../Constants/Documentation";
//#endregion

//#region style import
import "./MachineAddedPatchNotes.style.scss";
//#endregion

/**
 * component designed to be rendered in PatchNotesModal
 */

const MachineAddedPatchNotes = ({ machineInfo }) => {
  //#region hook
  const translations = useSelector((state) => state.translationSlice.translations);
  const language = useSelector((state) => state.translationSlice.language);
  const dispatch = useDispatch();
  //#endregion

  //#region constants
  const imgSrc = `/img/${machineInfo?.softwareCode}/${machineInfo?.version}/${machineInfo?.machineCode}/${machineInfo?.machineCode}_gstapro_image.jpg`;

  const simulationContentFilename = `${getTranslations(
    TRANSLATIONS.SIMULATION_CONTENT,
    translations
  )} ${getTranslations(machineInfo?.machineCode + "_name", translations)} ${machineInfo?.version}`;
  const trainingCatalogFilename = `${getTranslations(
    TRANSLATIONS.TRAINING_LAYOUT_TRAINING_CATALOG,
    translations
  )} ${getTranslations(machineInfo?.machineCode + "_name", translations)} ${machineInfo?.version}`;
  //#endregion

  return (
    <section className="patch-notes">
      <PatchNotesTitle
        mainTitle={`${getTranslations(TRANSLATIONS.NEW_SIMULATION_ADDED, translations)} - ${getTranslations(
          machineInfo?.machineCode + "_name",
          translations
        )}`}
        mainSummary={getTranslations(TRANSLATIONS.CONGRATULATIONS_NEW_SIMULATION_ADDED, translations)}
      />

      <div className="patch-notes-image">
        <img
          src={imgSrc}
          alt="Machine"
        />
      </div>

      <div className="patch-notes-info">
        <div className="patch-notes-info-title">
          <i className={ICON_NEW} />
          <h3>{getTranslations(TRANSLATIONS.SIMULATION_CONTENT, translations)}</h3>
        </div>

        <div className="patch-notes-info-content">
          <div className="patch-notes-info-content-list">
            <div className="patch-notes-info-content-list-item">
              <i className={ICON_CHECK} />
              <span>
                {machineInfo?.modulesCount} {getTranslations(TRANSLATIONS.UNITS, translations)}
              </span>
            </div>
            <div className="patch-notes-info-content-list-item">
              <i className={ICON_CHECK} />
              <span>
                {machineInfo?.exercisesCount} {getTranslations(TRANSLATIONS.EXERCISES, translations)}
              </span>
            </div>
            <div className="patch-notes-info-content-list-item">
              <i className={ICON_CHECK} />
              <span>
                {machineInfo?.trainingsCount} {getTranslations(TRANSLATIONS.TRAINING_CURRICULA, translations)}
              </span>
            </div>
          </div>
          <div className="patch-notes-info-content-pdf">
            <div
              className="patch-notes-info-content-pdf-item"
              onClick={() =>
                downloadPdfFile(
                  DOCUMENTATION.SIMULATION_CONTENT(machineInfo?.softwareCode, machineInfo?.machineCode),
                  language,
                  simulationContentFilename,
                  getTranslations(TRANSLATIONS.NOTIFICATION_NOTICE_DOWNLOADED, translations),
                  dispatch
                )
              }
            >
              <i className={ICON_PDF} />
              <span>
                {getTranslations(TRANSLATIONS.SIMULATION_CONTENT, translations)}{" "}
                {getTranslations(machineInfo?.machineCode + "_name", translations)}
              </span>
            </div>
            <div
              className="patch-notes-info-content-pdf-item"
              onClick={() =>
                downloadPdfFile(
                  DOCUMENTATION.TRAINING_CATALOG(machineInfo?.softwareCode, machineInfo?.machineCode),
                  language,
                  trainingCatalogFilename,
                  getTranslations(TRANSLATIONS.NOTIFICATION_NOTICE_DOWNLOADED, translations),
                  dispatch
                )
              }
            >
              <i className={ICON_PDF} />
              <span>
                {getTranslations(TRANSLATIONS.TRAINING_LAYOUT_TRAINING_CATALOG, translations)}{" "}
                {getTranslations(machineInfo?.machineCode + "_name", translations)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MachineAddedPatchNotes;
